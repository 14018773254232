import React, { Component } from "react";
import {
  Select,
  FormControl,
  MenuItem,
  Button,
  Checkbox,
  Typography,
  InputLabel,
  TextField,
  FormHelperText,
  Backdrop,
  CircularProgress,
  Tooltip,
  FormGroup,
  FormControlLabel,
  Stack,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Grid,
  DialogContentText,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import {
  getDropdownOptions,
  getIdentifiersDynamicValues,
  getFileSpecifications,
  getTermsAndConditions,
  updateFileSpecifications,
  updateFileSpecificationswithmultiplefiles,
  quotationSubmit,
  industry_type,
  q_plus_screen,
  postFileSpecifications,
  HOSTMAP,
  downloadmaterialDescription,
  templateURi,
  devTemplateURi,
  sendMailDataWriter,
} from "../../../config/apiUrl";
import axios from "axios";
import { styles } from "./style.js";
import UploadIcon from "@mui/icons-material/CloudUploadRounded";
import { OpenInNew } from "@mui/icons-material";
import { openSnackbar } from "../../../redux/actions/snackbarAction";
import _, { isEmpty } from "lodash";
import UploadFileDialog from "../../Dialogs/Upload/Upload";
import {
  setReminderSettings,
  resetReminderSettings,
  setRFQSettings,
} from "../../../redux/actions/drawerActions";
import { setPrRfqs } from "../../../redux/actions/prrfqactions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { resetCAT } from "../../../redux/resetApp";
import ConfirmAlert from "../../Dialogs/ConfirmAlert.js";
import TermsAndConditions from "../../Dialogs/Upload/TermsAndConditions";
import { setFileSpecification } from "../../../redux/actions/categoryAction";
import { setItemSpecifications } from "../../../redux/actions/itemActions";
import {
  setPrlinktoitemsforspecification,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
} from "../../../redux/actions/prrfqactions";
import { ImportQuotation } from "../../../redux/actions/ImportQuotation";
import axiosInstance from "../../../interceptor/axiosInstance";
import QuotationFormSectionTable from "../QuotationTemplateSectionTable/QuotationTemplateSectionTable.js";
import QuotationCardTemplate from "../../Card/QuotationCardTemplate.js";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import QuotationDetailsSection from "../QuotationDetailsSection/QuotationDetailsSection";
import dayjs from "dayjs";
import successimage from "../../../assets/vendxAdminPanelAsstes/assetsnew/rfq_submit.svg";
import UploadDynamicQuotation from "../../Dialogs/Upload/uploadDynamicQuotation";
import UploadDynamicView from "../../Dialogs/Upload/UploadDynamicView";
import DestinationDetailsDialog from "../DestinationDetailsDialog/DestinationDialog";
import { getSymbol } from "../../AccountTemplate/CurrencySymbol";

let Date_Time_Not_Null = function (value1) {
  //return value1 && value1.isValid() ? true : false;
  //let date1 = new Date(value1);
  return typeof value1 == "object"
    ? moment(value1).isValid()
    : !isNaN(new Date(value1));
};

let Greater_Than_Date_Time = function (value1, value2) {
  let date1 = new Date(value1);
  let date2 = new Date(value2);

  return date1 > date2;
};

let Greater_Than_Current_Date_Time = function (value1) {
  let date1 = new Date(value1);
  let Today = new Date();

  return date1 > Today;
};
let prconfig = {};

let slab = {
  //has to be dynamic
  58: {
    isOpen: false,
  },
};

class QuotationTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
      template: "",
      loading: false,
      quotationInitializeFlag: true,
      mainTemplateQuotation: {},
      slabMainTemplateQuotation: [],
      msmainTemplateQuotation: {},
      notFilteredMainTemplateQuotation: {},
      multiSolmainTemplateQuotation: [],
      errorArray: [],
      errorArrayForMS: [],
      errorArrayForSlab: [],
      solutionNames: [],
      solutionNameErrors: {},
      errorArrayForMSParentName: {},
      additionalInfoTemplate: {},
      IntlTemplate: {},
      disabledRows: {},
      shipment_mark: "",
      MaterialDescription: false,

      RFQStatic: {
        lock: false,
        star: false,
        MaterialDescription: false,
        RFQValidity: {
          HTMLAttributeName: "RFQValidity",
          FieldLabel: "RFQValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Date",
          value: "",
          maxDays: 0,
        },
        RFQValidityTime: {
          HTMLAttributeName: "RFQValidityTime",
          FieldLabel: "RFQValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid Time",
          value: "23:30",
        },
        TBValidity: {
          HTMLAttributeName: "TBValidity",
          FieldLabel: "TBValidity",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Date",
          value: "",
        },
        TBValidityTime: {
          HTMLAttributeName: "TBValidityTime",
          FieldLabel: "TBValidityTime",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid TB Time",
          value: "23:30",
        },
        RFQspecifications: {
          HTMLAttributeName: "RFQspecifications",
          FieldLabel: "RFQspecifications",
          Validation: "",
          error: false,
          ErrorMessage: "RFQspecifications is required",
          value: "",
        },
        RFQCCUsersmails: {
          HTMLAttributeName: "RFQCCUsersmails",
          FieldLabel: "RFQCCUsersmails",
          Validation: "",
          error: false,
          ErrorMessage: "Invalid!",
          value: "",
        },
        RFQContactCheck: {
          HTMLAttributeName: "RFQContactCheck",
          FieldLabel: "RFQContactCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
        RFQUpdateCCUsersCheck: {
          HTMLAttributeName: "RFQUpdateCCUsersCheck",
          FieldLabel: "RFQUpdateCCUsersCheck",
          Validation: "",
          error: false,
          ErrorMessage: "",
          value: false,
        },
      },
      RFQDynamic: {},
      vendorRecipintOpen: false,
      vendorRecipintType: "",
      reminderDrawerOpen: false,
      specificationsOpen: false,
      RFQSubmitDialogOpen: false,
      rfqSubmitMsg: "",
      startDate: "",
      startDateTB: true,
      qty: "",
      rate: "",
      unit: "",
      rfqSubmitLoading: false,
      loaderArray: [],
      vendorType: "",
      vendorTerms: "",
      vendorTermsOpen: false,
      currentFieldID: "",
      incoTermError: false,
      paymentTermError: false,
      dynamicFileUploadOpen: false,
      dynamicFileUploadItemsOpen: false,
      currentFieldItemId: "",
      currentItemId: "",
      currentItemIndex: "",
      currentTemplateItem: null,
      currentFieldId: null,
      currentTemplate: null,
      categorySpecificationFiles: [],
      jsonTemplateloading: false,
      prevRFQLoading: false,
      prevQuotationFormLoading: false,
      prevRFQ: {},
      randomId: null,
      usedAttributesId: [],
      confirmDialogOpen: false,
      confirmAlertMsg: "",
      confirmAlertTitle: "",
      tandcDialogOpen: false,
      termsAndConditionsData: {},
      applyToAll: {},
      multiSolApplyToAll: {},
      slabApplyToAll: {},
      slabParentApplyToAll: {},
      commonOptions: {},
      localPaymentFields: [],
      localIncoFields: [],
      intlPaymentFields: [],
      intlIncoFields: [],
      submitDisable: true,
      templateValue: {},
      slab: {},
      slabKey: 1,
      applyToAllSlab: false,
      quotationTemplateJson: [],
      openConfirmBox: false,
      selectedCurrency: "",
      mainSelectedInitiallyN: false,
      termsAndConditionsModalOpen: false,
      dataFromQuotationDetails: {},
      offlineDateError: "",
      offlineAttachment: "",
      offlineFieldError: "",
      industryTypeError: "",
      offlineReferenceError: "",
      industryTypeErrorMessage: "",
      remainTemplateQuotation: {},
      quotationSubmitSuccess: false,
      quotationSubmitSuccessMessage: "",
      quotationChecked: false,
      quotationSubmitLoading: false,
      industryTypeValidator: false,
      msmeValidator: false,
      industryType: this.props?.industryDetails?.type,
      msmeNumber: this.props?.industryDetails?.Number,
      switchState: false,
      switchStateToggleCheck: false,
      slabChildStateToggleCheck: false,
      fileSpecificationsOpen: false,
      specification: [],
      fileSpecLoading: true,
      fileAttachmentName: "",
      attachmentLoading: false,
      attachments: {},
      solutionNameMainTemplate: {},
      quotationImported: false,
      isDestinationDetailsDialogOpen: false,
      selectedCurrencyValidator: false,
      selectedCurrencyErrorMessage: "",
      rearrangedArray: [],
      allItemsTemplate: [],
      EvalResults: {},
      EvalResultsSlab: {},
      intialMaintemplateQuotation: {},
      disabledProps: {},
      excelSourceName: "",
      filteredData: {},
      notFilteredSlabMainTemplateQuotation: {},
      fieldIdentifierMapping: {},
      selectedMasterCurrency: {},
    };
    this.setIsDestinationDetailsDialogOpen =
      this.setIsDestinationDetailsDialogOpen.bind(this);
    this.setRearrangedTemplate = this.setRearrangedTemplate.bind(this);
    this.setItemsTemplate = this.setItemsTemplate.bind(this);
    this.setServiceSourceName = this.setServiceSourceName.bind(this);
    this.handleSolNameChangeMainTemplate =
      this.handleSolNameChangeMainTemplate.bind(this);
    this.handleSolutionNameChange = this.handleSolutionNameChange.bind(this);
    this.disabledProps = this.disabledProps.bind(this);
    this._isMounted = false;
  }

  componentDidMount() {
    if (!this.props.quotationFormFlag) {
      this.props.quotationJsonTemplate.ITEMIZE[0]?.map((cell, index) => {
        if (cell.VisibilityFlag === "Y") {
          if (cell.Identifier === "matspecghclfile") {
            let RFQStaticUpdate = this.state.RFQStatic;
            RFQStaticUpdate.MaterialDescription = true;
          }
        }
      });
      this._isMounted = true;
      this.initializeState();
      this.initializeSolutionNames(); //set ms flag condition if face any issue
      this.initializeRFQDynamicState();
      this.fetchTermsAndCondtions();

      if (this.props?.cc_emails) {
        let RFQStaticUpdate = { ...this.state.RFQStatic };
        RFQStaticUpdate.RFQCCUsersmails.value = this.props?.cc_emails;
        this.setState(
          {
            RFQStatic: RFQStaticUpdate,
          },
          () => {
            if (document.getElementsByName("RFQCCUsersmails")[0]) {
              document.getElementsByName("RFQCCUsersmails")[0].value =
                this.props?.cc_emails;
            }
          }
        );
      }

      if (this.props.featurePermission?.sealed_rfq?.feature_value === "Y") {
        let RFQStaticUpdate = this.state.RFQStatic;
        RFQStaticUpdate.lock = true;
        this.setState({
          RFQStatic: RFQStaticUpdate,
        });
      }

      if (
        this.props.featurePermission?.max_rfq_validity_days?.feature_value ===
        "Y"
      ) {
        let days =
          this.props.featurePermission?.max_rfq_validity_days
            ?.feature_permission_value;
        let RFQStaticUpdate = { ...this.state.RFQStatic };
        RFQStaticUpdate.RFQValidity.maxDays = days;
        this.setState({
          RFQStatic: RFQStaticUpdate,
        });
      }
      //  let fileSpecificationFromApi = this.props.fileSpecificationFromApi;
      let categoryId = this.props.selectedCategory?.id;
      this.setState({ randomId: this.props?.randomId }, () => {
        if (Object.keys(this.props?.prlineitems).length !== 0) {
          this.fetchprlineitemsfiles(); // fetching category specs here to show count
        } else {
          this.fetchCategorySpecs((files) => {
            let selected_category_idres = this.props.selectedCategory?.id;
            this.setState({
              categorySpecificationFiles:
                files?.[selected_category_idres] || [],
            });
            this.props.setFileSpecification(
              files?.[selected_category_idres] || []
            );
          });
        }
      });

      if (this.props.rfqSettingValues.priorityRfq === true) {
        this.setState({
          RFQStatic: {
            ...this.state.RFQStatic,
            star: this.props.rfqSettingValues.priorityRfq,
          },
        });
      }
    } else {
      this.props.quotationJsonTemplate.ITEMIZE[0]?.map((cell, index) => {
        if (cell.VisibilityFlag === "Y") {
          if (cell.Identifier === "matspecghclfile") {
            this.setState({
              MaterialDescription: true,
            });
          }
        }
      });
      this.props.setClick(this.fetchPrevious);
      //For main template quotation
      if (this.props.multipleSolutionFlag && !this.props.slabFlag) {
        const { items } = this.props;
        if (items) {
          const solutionNameMainTemplate = {};
          // Initialize solutionNameMainTemplate for all keys in items
          Object.entries(items).forEach(([key, value]) => {
            solutionNameMainTemplate[key] = value.name || "";
          });
          this.setState({ solutionNameMainTemplate });
        } else {
          console.log("items is not available at componentDidMount");
        }
      }

      this.initializeState();
      this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        this.initializeSolutionNames();
      this.initializeRFQDynamicState();
      if (
        this.props.TxnType == "Requote" &&
        this.props.multipleSolutionFlag &&
        this.props.slabFlag
      ) {
        this.fetchPrevTemplate();
      }
    }
  }

  setSlabMainTemplateQuotation = (updatedSlabMainTemplateQuotation) => {
    this.setState({
      slabMainTemplateQuotation: updatedSlabMainTemplateQuotation,
    });
  };

  setDisabledRows = (updatedDisabledRows) => {
    this.setState({ disabledRows: updatedDisabledRows });
  };

  setDataFromQuotationChild = (data) => {
    this.setState({ dataFromQuotationDetails: data });
  };

  handleCurrencyChange = (event) => {
    this.setState({
      selectedCurrency: event.target.value,
      selectedCurrencyValidator: !event.target.value, // Update validator state based on selection
    });
  };

  getFieldMasterCurrency = (cell) => {
    const additionalInfo = this.state.additionalInfoTemplate[cell?.FieldId] || {};
    const { error, ErrorMessage, HTMLAttributeName: name, value } = additionalInfo;

    return (
      <FormControl fullWidth variant="outlined" error={error}>
        <InputLabel>{cell.FieldLabel}</InputLabel>
        <Tooltip title={value || ""} disableInteractive placement="top-start">
          <Select
            className="template_dropdown"
            label={`${cell.FieldLabel}${cell.InstructionText ? ` (${cell.InstructionText})` : ""}`}
            name={name}
            attr-id={cell.FieldId}
            id="additionalSelect"
            value={value || this.getMasterCurrencyByFieldId(cell.FieldId)}
            onChange={(e) => {
              this.handleMasterCurrencyChange(e.target.value, cell);
              this.handleLocalChange(e, cell.FieldId);
            }}
            onBlur={() => this.validateInputAdditionalInfo(cell.FieldId)}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 250,
                  width: 250,
                },
              },
            }}
          >
            <MenuItem value="">Select</MenuItem>
            {this.props.currencyOptions.map((option) => (
              <MenuItem key={option?.CurrencyID} value={option?.Currency}>
                <Typography style={{ whiteSpace: "pre-line" }}>{option?.Currency}</Typography>
              </MenuItem>
            ))}
          </Select>
        </Tooltip>
        {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
      </FormControl>
    );
  };

  handleMasterCurrencyChange = (value, cell) => {
    let field_id = cell.FieldId || cell.HTMLAttributeName;
    if (!cell || !field_id) {
      return;
    }
    field_id = field_id.match(/\d+/)?.[0];
    this.setState((prevState) => ({
      selectedMasterCurrency: {
        ...prevState.selectedMasterCurrency,
        [field_id]: value || "",
      },
    }));
  }

  getMasterCurrencyByFieldId = (fieldId) => {
    if(!fieldId){
      return "";
    }
    const id = fieldId.match(/\d+/)?.[0] || "";
    const { selectedMasterCurrency } = this.state;
    const currency_name = selectedMasterCurrency[id] || "";
    return currency_name;
  }

  evalMasterExchangeRate = (formula) => {
    const regex = /ExchangeRate_"([^"]+)"/g;
    formula = formula.replace(regex, (match, currency) => {
      const exchangeRate = this.props.currencyOptions.find(
        (currencyOption) => currencyOption.Currency === currency
      );
      return exchangeRate ? exchangeRate.ExchangeRate : 0;
    });
    if (formula.includes('ExchangeRate_0')) {
      formula = formula.replace(
        /ExchangeRate_0/g,
        1
      );
    }
    return formula;
  }

  handleIndustryTypeChange = (value) => {
    this.setState({ industryType: value });
  };

  handleMsmeNumberChange = (value) => {
    this.setState({ msmeNumber: value });
  };

  handleConfirmBoxOpen = () => {
    this.setState({
      openConfirmBox: true,
    });
  };

  toggleSwitch = (key) => {
    this.setState((prevState) => ({
      switchState: {
        ...prevState.switchState,
        [key]: !prevState.switchState[key],
      },
    }));
  };

  initializeSolutionNames() {
    const { lastTransactionInThread } = this.props;
    const solutionNames = {};

    Object.entries(lastTransactionInThread).forEach(([key, item]) => {
      item.solution.forEach((solution, index) => {
        // added by sonali to add default item code :: START
        if (index == 0) {
          const { items } = this.props;
          let solNames = {};
          if (items) {
            // Initialize solNames for all keys in items
            Object.entries(items).forEach(([key, value]) => {
              solNames[key] = value.name || "";
            });
            this.setState({ solNames });
          }

          const solutionKey = `${key}-${index}`;
          if (this.props.lastTransactionTypeInThread === "Inquiry") {
            solutionNames[solutionKey] = solNames[key];
          } else {
            solutionNames[solutionKey] = solution.name || solNames[key];
          }
        } // added by sonali to add default item code :: END
        else {
          const solutionKey = `${key}-${index}`;
          solutionNames[solutionKey] = solution.name || "";
        }
      });
    });

    this.setState({ solutionNames });
  }

  handleSolNameChangeMainTemplate(key, newSolName) {
    this.setState((prevState) => {
      const {
        solutionNames,
        solutionNameMainTemplate,
        errorArrayForMSParentName,
      } = prevState;

      // Update solutionNames[`${key}-${0}`] to the new value
      const updatedSolutionNames = {
        ...solutionNames,
        [`${key}-${0}`]: newSolName, // Overwrite solutionNames[`${key}-${0}`] with the new value
      };

      // Also update solutionNameMainTemplate for the specific key
      const updatedSolutionNameMainTemplate = {
        ...solutionNameMainTemplate,
        [key]: newSolName,
      };

      // Update errorArrayForMSParentName based on validation
      const updatedErrorArrayForMSParentName = {
        ...errorArrayForMSParentName,
      };

      if (newSolName.trim() === "") {
        updatedErrorArrayForMSParentName[key] = `${
          this.props.AlternativeNames?.alternate_options || "Solution"
        } name cannot be empty`;
      } else {
        delete updatedErrorArrayForMSParentName[key];
      }

      return {
        solutionNames: updatedSolutionNames, // Save the updated solutionNames
        solutionNameMainTemplate: updatedSolutionNameMainTemplate, // Save the updated solutionNameMainTemplate
        errorArrayForMSParentName: updatedErrorArrayForMSParentName,
      };
    });
  }

  handleSolutionNameChange(itemId, rowIndex, event) {
    const { value } = event.target;
    const key = `${itemId}-${rowIndex}`;

    this.setState((prevState) => {
      const { solutionNames, solutionNameErrors } = prevState;

      // Update solutionNames for the specific key
      const updatedSolutionNames = {
        ...solutionNames,
        [key]: value,
      };

      // Group solution names by their itemId
      const groupedSolutionNames = {};
      Object.keys(updatedSolutionNames).forEach((key) => {
        const [itemId] = key.split("-"); // Extract itemId or key prefix
        if (!groupedSolutionNames[itemId]) {
          groupedSolutionNames[itemId] = [];
        }
        groupedSolutionNames[itemId].push({
          key,
          name: updatedSolutionNames[key],
        });
      });

      // Check for uniqueness and update errors
      const updatedSolutionNameErrors = {};
      Object.keys(groupedSolutionNames).forEach((itemId) => {
        const names = groupedSolutionNames[itemId];
        const uniqueNamesSet = new Set();
        names.forEach(({ key, name }) => {
          if (name.trim() === "") {
            // Solution name is empty

            delete updatedSolutionNameErrors[key];
            if (
              this.state.mainTemplateQuotation[key.split("-")[0]]?.selected ===
              "Y"
            ) {
              // nothing to  do
              updatedSolutionNameErrors[key] = `${
                this.props.AlternativeNames?.alternate_options || "Solution"
              } name cannot be empty`;
            }
          } else if (name !== "" && uniqueNamesSet.has(name)) {
            // Solution name is duplicated
            delete updatedSolutionNameErrors[key];
            if (
              this.state.mainTemplateQuotation[key.split("-")[0]]?.selected ===
              "Y"
            ) {
              updatedSolutionNameErrors[key] = "Solution name must be unique";
            }
          } else {
            // Add the name to the set for uniqueness checking
            uniqueNamesSet.add(name);
          }
        });
      });

      return {
        solutionNames: updatedSolutionNames,
        solutionNameErrors: updatedSolutionNameErrors,
      };
    });
  }

  handleConfirmBoxClose = () => {
    this.setState({
      openConfirmBox: false,
    });
  };

  downloadTransactionExcel = (format) => {
    // Implement your downloadTransactionExcel function logic here
  };

  validateFileExistance = () => {
    // Implement your validateFileExistance function logic here
  };

  handleImport = () => {
    // Implement your import logic here
    // ...
    this.handleConfirmBoxOpen();
  };

  //slab functions start
  handleSlab = (evt, item) => {
    this.setState({
      slab: { ...this.state.slab, [item.id]: { isOpen: evt.target.checked } }, //set is open for item
    });
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    //remove value if slab is closed
    if (!evt.target.checked) {
      this.setState((prevState) => ({
        ...prevState,
        mainTemplate1: {
          ...prevState.mainTemplateQuotation,
          [item.id]: {
            ...prevState.mainTemplateQuotation[item.id],
            slab: [
              { id: 1, start: "1", end: "", error: false },
              { id: 2, start: "More Than", end: "", error: false },
            ],
          },
        },
      }));
    }
  };
  //add row in slab
  slabAddRow = (idx, index) => {
    const re = /((\b|\+|-)(0|([1-9][0-9]*))(\.[0-9]+)?)\b/;
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    if (!re.test(allSlab[allSlab.length - 1].end)) {
      this.props.openSnackbar(
        "error",
        ` Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (
      parseFloat(allSlab[allSlab.length - 2].start) >=
      parseFloat(allSlab[allSlab.length - 2].end)
    ) {
      this.props.openSnackbar(
        "error",
        `  Please provide a valid ${this.props?.displayOptions["label"]} for ${this.props.selectedItems[index].code}`
      );
      return;
    } else if (allSlab.some((obj) => obj.end == "" || obj.error == true)) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for${this.props.selectedItems[index].code}.`
      );
      return;
    } else if (
      allSlab.length ==
      parseInt(this.props.rfqSettingValues?.allowed_slab_limit)
    ) {
      this.props.openSnackbar(
        "error",
        `Maximum ${this.props.rfqSettingValues.allowed_slab_limit} ${this.props?.displayOptions["label"]} allowed per item`
      );
      return;
    }
    let value = allSlab[idx].end;
    let val = allSlab[idx].end.toString().split(".")[1];
    let isAllZero = val && val.split("").every((char) => char == "0");
    if (val && val.length && !isAllZero) {
      if (val.length == 1) {
        value = parseFloat(value) + 0.1;
        value = value.toFixed(1);
      } else if (val.length == 2) {
        value = parseFloat(value) + 0.01;
        value = value.toFixed(2);
      } else if (val.length == 3) {
        value = parseFloat(value) + 0.001;
        value = value.toFixed(3);
      }
    } else {
      value = parseInt(value) + 1;
    }
    let newSlab = {
      id: Math.random().toString(36).slice(2),
      start: `${parseFloat(value)}`,
      end: "",
      error: false,
    };
    let popped = allSlab.pop(); //remove last element from array
    popped = { ...popped, end: "" };
    let updateSlab = [...allSlab, newSlab];
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //remove row from slab
  slabRemoveRow = (idx, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    let updateSlab = allSlab.filter((row, i) => i != idx);
    let popped = updateSlab.pop();
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    if (updateSlab[idx]) {
      updateSlab[idx] = { ...updateSlab[idx], start: allSlab[idx].start };
    }
    updateSlab.push(popped);
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [this.props.selectedItems[index].id]: {
            ...prevState.mainTemplateQuotation[
              this.props.selectedItems[index].id
            ],
            slab: updateSlab,
          },
        },
      }),
      () => {
        this.handleKey(index);
      }
    );
  };
  //handle input in row
  handleRowInput = (evt, row, id, index) => {
    if (this.state.applyToAllSlab == true) {
      this.setState({
        applyToAllSlab: false,
      });
    }
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    let popped = allSlab.pop();
    let updateSlab = allSlab.map((obj, i) => {
      let newObj = { ...obj };
      const re = /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/;
      if (i == id) {
        if (
          parseFloat(evt.target.value) <= parseFloat(newObj.start) ||
          !re.test(evt.target.value)
        ) {
          newObj.error = true;
          newObj.end = "";
        } else {
          newObj.end = parseFloat(evt.target.value);
          newObj.error = false;
        }
      } else if (i == id + 1) {
        if (evt.target.value != "" && re.test(evt.target.value)) {
          let value = evt.target.value;
          let val = evt.target.value.toString().split(".")[1];
          let isAllZero = val && val.split("").every((char) => char == "0");
          if (val && val.length && !isAllZero) {
            if (val.length == 1) {
              value = parseFloat(value) + 0.1;
              value = value.toFixed(1);
            } else if (val.length == 2) {
              value = parseFloat(value) + 0.01;
              value = value.toFixed(2);
            } else if (val.length == 3) {
              value = parseFloat(value) + 0.001;
              value = value.toFixed(3);
            }
          } else {
            value = parseInt(value) + 1;
          }
          newObj.start = `${parseFloat(value)}`;
          if (parseFloat(newObj.end) <= parseFloat(newObj.start)) {
            newObj.error = true;
          } else {
            newObj.error = false;
          }
        } else if (evt.target.value == "") {
          newObj.start = "";
        }
      }
      return newObj;
    });
    popped = { ...popped, end: updateSlab[updateSlab.length - 1].end };
    updateSlab.push(popped);
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [this.props.selectedQuotedItems[index].id]: {
          ...prevState.mainTemplateQuotation[
            this.props.selectedQuotedItems[index].id
          ],
          slab: updateSlab,
        },
      },
    }));
  };
  //for apply to all in slab
  handleApplyToAllSlab = (evt, index, itemId) => {
    var found = this.props.selectedItems.find((element) => {
      if (this.state.slab[element.id].isOpen) {
        return element;
      }
    });
    let allSlab = this.state.mainTemplateQuotation[found.id]["slab"];
    if (allSlab.some((obj) => obj.error == true || obj.end == "")) {
      this.props.openSnackbar(
        "error",
        `Please provide a valid ${this.props?.displayOptions["label"]} for ${found.code}.`
      );
      return;
    }
    this.setState({
      applyToAllSlab: evt.target.checked,
    });
    this.props.selectedItems.forEach((item) => {
      if (this.state.slab[item.id].isOpen) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [item.id]: {
              ...prevState.mainTemplateQuotation[item.id],
              slab: allSlab.map((obj, i) => {
                return { ...obj, id: Math.random().toString(36).slice(2) };
              }),
            },
          },
        }));
      }
    });
  };
  //change key for render
  handleKey = (index) => {
    let allSlab =
      this.state.mainTemplateQuotation[this.props.selectedItems[index].id][
        "slab"
      ];
    let updateSlab = allSlab.map((obj, i) => {
      return { ...obj, id: Math.random().toString(36).slice(2) };
    });
    this.setState((prevState) => ({
      ...prevState,
      mainTemplateQuotation: {
        ...prevState.mainTemplateQuotation,
        [this.props.selectedItems[index].id]: {
          ...prevState.mainTemplateQuotation[
            this.props.selectedItems[index].id
          ],
          slab: updateSlab,
        },
      },
    }));
  };

  handleItemizeInputChange = (
    evt,
    index,
    FieldId,
    Identifier,
    ElementType,
    key
  ) => {
    const { value, type, checked } = evt.target;
    const { mainTemplateQuotation } = this.state;

    if (mainTemplateQuotation[key][FieldId]["value"] !== value) {
      this.setState({
        applyToAll: {
          ...this.state.applyToAll,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
          [`${FieldId}_applyToAll`]: false,
        },
      });
    }
    this.setState(
      (prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [key]: {
            ...prevState.mainTemplateQuotation[key],
            [FieldId]: {
              ...prevState.mainTemplateQuotation[key][FieldId],
              value:
                Identifier == "GSTValue"
                  ? value
                  : type !== "checkbox"
                  ? //Number.isInteger(parseFloat(evt.target.value))
                    !isNaN(evt.target.value) && evt.target.value != ""
                    ? parseFloat(evt.target.value, 10)
                    : evt.target.value
                  : checked,
              error:
                type !== "checkbox"
                  ? false
                  : prevState.mainTemplateQuotation[key][FieldId].error,
            },
          },
        },
      }),
      () => {
        this.validateInput(FieldId, index);
        if (
          Identifier === "Budgetedrate" ||
          Identifier === "rate" ||
          Identifier === "CIFRate" ||
          Identifier === "Rate"
        ) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [key]: {
                ...prevState.mainTemplateQuotation[key],
                rate: value,
              },
            },
          }));
        } else if (Identifier === "ProductQuantity") {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [key]: {
                ...prevState.mainTemplateQuotation[key],
                qty: value,
              },
            },
          }));
        }
      }
    );

    window.localStorage.setItem(
      "mainTemplateQuotation",
      JSON.stringify(this.state.mainTemplateQuotation)
    );
  };

  uploadItemFiles = (FieldId, Identifier, itemId, index, templateState) => {
    if (typeof index === "undefined") {
      const value =
        this.state.mainTemplateQuotation?.[itemId]?.[FieldId]?.["value"];

      this.setState({
        // dynamicFileUploadItemsOpen: true,
        currentFieldItemId: FieldId,
        currentTemplateItem: "mainTemplateQuotation",
        currentItemId: itemId,
        currentItemIndex: index,
      });
      if (
        value !== null &&
        value !== undefined &&
        value !== "" &&
        value.length > 0 &&
        value[0]?.id
      ) {
        this.getExistingFilesForAttachment(value, "itemize");
      } else {
        this.setState({
          dynamicFileUploadItemsOpen: true,
          // currentFieldItemId: FieldId,
          // currentTemplateItem: "mainTemplateQuotation",
          // currentItemId : itemId
        });
      }
    }
    /**
     * for slab item level attachment
     */
    // else {
    //   const value = this.state[templateState]?.[itemId]?.[index]?.[FieldId]?.['value']
    //   console.log("checkDTA", this.state[templateState]?.[itemId]?.[index]?.[FieldId]);
    //   this.setState({
    //     // dynamicFileUploadItemsOpen: true,
    //     currentFieldItemId: FieldId,
    //     currentTemplateItem: templateState,
    //     currentItemId : itemId,
    //     currentItemIndex: index
    //   })
    //   if (
    //     value !== null &&
    //     value !== undefined &&
    //     value !== "" &&
    //     value.length > 0 &&
    //     value[0]?.id
    //   ) {
    //     this.getExistingFilesForAttachment(value, "itemize");
    //   } else {

    //     this.setState({
    //       dynamicFileUploadItemsOpen: true,
    //     })
    //   }
    // }
  };

  handleMSItemizeInputChange = (
    evt,
    FieldId,
    Identifier,
    FieldLabel,
    key,
    index
  ) => {
    const { value, type, checked } = evt.target;
    const {
      multiSolmainTemplateQuotation,
      mainTemplateQuotation,
      multiSolApplyToAll,
    } = this.state;

    // Check if the field value is different from the current value
    if (multiSolmainTemplateQuotation[key][index][FieldId]?.value !== value) {
      this.setState({
        multiSolApplyToAll: {
          ...multiSolApplyToAll,
          [`${key}_${FieldId}_multiSolApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });
    }

    // Check if the key and index are valid
    if (
      multiSolmainTemplateQuotation[key] &&
      Array.isArray(multiSolmainTemplateQuotation[key]) &&
      multiSolmainTemplateQuotation[key][index]
    ) {
      let errpr = false;

      this.setState(
        (prevState) => ({
          ...prevState,
          multiSolmainTemplateQuotation: {
            ...prevState.multiSolmainTemplateQuotation,
            [key]: prevState.multiSolmainTemplateQuotation[key].map(
              (item, idx) => {
                if (idx === index) {
                  errpr = type !== "checkbox" ? false : item[FieldId].error;
                }
                return idx === index
                  ? {
                      ...item,
                      [FieldId]: {
                        ...item[FieldId],
                        value:
                          Identifier === "GSTValue"
                            ? value
                            : type !== "checkbox"
                            ? Number.isInteger(parseFloat(value))
                              ? parseInt(value, 10)
                              : value
                            : checked,
                        error:
                          type !== "checkbox" ? false : item[FieldId].error,
                      },
                      ...(Identifier === "Budgetedrate" ||
                      Identifier === "rate" ||
                      Identifier === "CIFRate" ||
                      Identifier === "Rate"
                        ? { rate: value }
                        : Identifier === "ProductQuantity"
                        ? { qty: value }
                        : {}),
                    }
                  : item;
              }
            ),
          },
          ...(index === 0 &&
            multiSolmainTemplateQuotation[key].length > 1 && {
              mainTemplateQuotation: {
                ...prevState.mainTemplateQuotation,
                [key]: {
                  ...prevState.mainTemplateQuotation[key],
                  [FieldId]: {
                    ...prevState.mainTemplateQuotation[key][FieldId],
                    value: value,
                  },
                  // Add this line to update rate in mainTemplateQuotation
                  ...(Identifier === "Budgetedrate" ||
                  Identifier === "rate" ||
                  Identifier === "CIFRate" ||
                  Identifier === "Rate"
                    ? { rate: value }
                    : {}),
                },
              },
            }),
        }),
        () => {
          this.validateInputForMultiSol(FieldId, key, index, true);
        }
      );

      window.localStorage.setItem(
        "multiSolmainTemplateQuotation",
        JSON.stringify(this.state.multiSolmainTemplateQuotation)
      );
    }
  };

  handleSlabItemizeInputChange = (
    evt,
    FieldId,
    Identifier,
    FieldLabel,
    key,
    index
  ) => {
    const { value, type, checked } = evt.target;
    const { slabMainTemplateQuotation, slabApplyToAll } = this.state;

    // Check if the field value is different from the current value
    if (slabMainTemplateQuotation[key][index][FieldId]?.value !== value) {
      this.setState({
        slabApplyToAll: {
          ...slabApplyToAll,
          [`${key}_${FieldId}_slabApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });
    }
    // Check if the key and index are valid
    if (
      slabMainTemplateQuotation[key] &&
      Array.isArray(slabMainTemplateQuotation[key]) &&
      slabMainTemplateQuotation[key][index]
    ) {
      this.setState(
        (prevState) => {
          const updatedQuotation = prevState.slabMainTemplateQuotation[key].map(
            (item, idx) => {
              if (idx === index) {
                return {
                  ...item,
                  [FieldId]: {
                    ...item[FieldId],
                    value:
                      Identifier === "GSTValue"
                        ? value
                        : type !== "checkbox"
                        ? Number.isInteger(parseFloat(value))
                          ? parseInt(value, 10)
                          : value
                        : checked,
                    error: type !== "checkbox" ? false : item[FieldId].error,
                  },
                  ...(Identifier === "Budgetedrate" ||
                  Identifier === "rate" ||
                  Identifier === "CIFRate" ||
                  Identifier === "Rate"
                    ? { rate: value }
                    : Identifier === "ProductQuantity"
                    ? { qty: value }
                    : {}),
                };
              }
              return item;
            }
          );

          // Preserve the selected key
          updatedQuotation.selected =
            prevState.slabMainTemplateQuotation[key].selected;

          return {
            ...prevState,
            slabMainTemplateQuotation: {
              ...prevState.slabMainTemplateQuotation,
              [key]: updatedQuotation,
            },
          };
        },
        () => {
          this.validateInputForSlab(FieldId, key, index, true);
          window.localStorage.setItem(
            "slabMainTemplateQuotation",
            JSON.stringify(this.state.slabMainTemplateQuotation)
          );
        }
      );
    }
  };

  handleWithOutSlabInputChange = (
    evt,
    FieldId,
    Identifier,
    FieldLabel,
    key,
    index
  ) => {
    const { value, type, checked } = evt.target;
    const { slabMainTemplateQuotation, slabParentApplyToAll } = this.state;
    // Check if the field value is different from the current value
    if (
      slabMainTemplateQuotation[key] &&
      slabMainTemplateQuotation[key][0]?._range === undefined &&
      slabMainTemplateQuotation[key][0][FieldId]?.value !== value
    ) {
      this.setState({
        slabParentApplyToAll: {
          ...slabParentApplyToAll,
          [`${FieldId}_slabParentApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });
    }

    this.setState(
      (prevState) => {
        const slabMainTemplateQuotation = {
          ...prevState.slabMainTemplateQuotation,
        };

        if (
          slabMainTemplateQuotation[key] &&
          slabMainTemplateQuotation[key][0]?._range === undefined
        ) {
          slabMainTemplateQuotation[key] = slabMainTemplateQuotation[key].map(
            (item, idx) => {
              if (idx === 0) {
                return {
                  ...item,
                  [FieldId]: {
                    ...item[FieldId],
                    value:
                      Identifier === "GSTValue"
                        ? value
                        : type !== "checkbox"
                        ? Number.isInteger(parseFloat(value))
                          ? parseInt(value, 10)
                          : value
                        : checked,
                    error: type !== "checkbox" ? false : item[FieldId].error,
                  },
                  ...(Identifier === "Budgetedrate" ||
                  Identifier === "rate" ||
                  Identifier === "CIFRate" ||
                  Identifier === "Rate"
                    ? { rate: value }
                    : Identifier === "ProductQuantity"
                    ? { qty: value }
                    : {}),
                };
              }
              return item;
            }
          );

          // Preserve the selected key
          slabMainTemplateQuotation[key].selected =
            prevState.slabMainTemplateQuotation[key].selected;

          window.localStorage.setItem(
            "slabMainTemplateQuotation",
            JSON.stringify(slabMainTemplateQuotation)
          );

          return { slabMainTemplateQuotation };
        }

        return null;
      },
      () => {
        // Validation after state update
        this.validateInputForSlab(FieldId, key, 0, true);
      }
    );
  };

  fetchprlineitemsfiles = () => {
    const { prlineitems, selectedItems } = this.props;
    let prno_items_mapping = {};
    let categoryarray = [];
    selectedItems.forEach((items, item_index) => {
      const itemid = items.id;
      categoryarray = this.Fetchprlineitemspecificationfiles(
        prlineitems[itemid],
        itemid,
        prno_items_mapping,
        categoryarray,
        item_index
      );
    });

    // if(categoryarray?.length > 0){
    this.getprlineitemspecificationfiles(categoryarray, "category");
    // }
    this.props.setPrlinktoitemsforspecification(prno_items_mapping);
  };
  Fetchprlineitemspecificationfiles = (
    pr_lineitemdata,
    itemid,
    prno_items_mapping,
    categoryarray,
    item_index
  ) => {
    let itemarray = [];
    pr_lineitemdata.forEach((object, i) => {
      const items_attechment = object.attachment || [];
      const category_attechment = object.pr?.attachment || [];
      if (category_attechment.length !== 0) {
        if (prno_items_mapping.hasOwnProperty(object.pr.number)) {
          if (!prno_items_mapping[object.pr.number].hasOwnProperty(itemid)) {
            prno_items_mapping[object.pr.number][itemid] = category_attechment;
          }
        } else {
          prno_items_mapping[object.pr.number] = {};
          prno_items_mapping[object.pr.number][itemid] = [];
          prno_items_mapping[object.pr.number][itemid] = category_attechment;
        }
      }
      if (items_attechment) itemarray = [...itemarray, ...items_attechment];
      if (category_attechment)
        categoryarray = [...categoryarray, ...category_attechment];
    });
    this.getprlineitemspecificationfiles(
      itemarray,
      "items",
      itemid,
      item_index
    );
    return categoryarray;
  };

  getprlineitemspecificationfiles = async (
    files_ids,
    type,
    itemid,
    item_index
  ) => {
    let response = {};
    if (files_ids.length > 0) {
      const url = getFileSpecifications(files_ids);
      response = await axiosInstance.get(url);
    }
    if (type === "category") {
      this.fetchCategorySpecs((files) => {
        let selected_category_idres = this.props.selectedCategory?.id;
        const categoryarray = response.data?.data || [];
        const old_files = files?.[selected_category_idres] || [];
        let all_files = [...categoryarray, ...old_files];
        this.props.setFileSpecification(all_files || []);
        //setting flile specification data into redux
        // this.props.setFileSpecificationFromApi({[selected_category_idres]:all_files || []})
      });
    } else {
      let itemmasterfiles = {};

      this.getitemspecificfiles(item_index, (files) => {
        itemmasterfiles = files;
        const itemarray = response.data?.data || [];
        const old_files = files?.[itemid] || [];
        let all_files = [...itemarray, ...old_files];
        this.props.setItemSpecifications({ [itemid]: all_files });
      });
    }
  };
  getitemspecificfiles = async (item_index, callback) => {
    const url = updateFileSpecificationswithmultiplefiles(
      [this.props.selectedItems[item_index]],
      "items"
    );
    let response = await axiosInstance.get(url);
    const nearresponse = response.data?.data;
    callback(nearresponse);
  };

  fetchCategorySpecs = async (callback) => {
    const url = updateFileSpecifications(
      this.props.selectedCategory?.id,
      "categories"
    );

    let response = await axios.get(url);
    const nearresponse = response.data?.data;
    callback(nearresponse);
  };

  generateRandom = (min, max) => {
    const timestring = new Date().getTime();
    const numstring = Math.floor(Math.random() * (max - min + 1)) + min;
    return timestring + "." + numstring;
  };

  extractAndFormat = (inputString) => {
    const pattern = /(\d+)\.\w\.\((f\d+)\)/g;
    const seen = new Set(); // Set to keep track of seen values
    let matches;
    const results = [];

    while ((matches = pattern.exec(inputString)) !== null) {
      const value = `${matches[1]}.${matches[2]}`;
      if (!seen.has(value)) {
        // Check if value is not already in the set
        results.push(value);
        seen.add(value); // Add value to the set
      }
    }

    return results;
  };
  handleFileSpecificationsOpen = (value, name) => {
    this.setState({ fileSpecificationsOpen: true });
    this.setState({ fileAttachmentName: name });
    this.getExistingFiles(value);
  };

  handleUploadClose = () => {
    this.setState({
      fileSpecificationsOpen: false,
      specification: [],
      fileSpecLoading: true,
    });
  };

  getExistingFiles(file_id) {
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;

    axios
      .get(url)
      .then((res) => {
        this.setState({ specification: res.data });
        this.setState({ fileSpecLoading: false });
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({ fileSpecLoading: false });
      });
  }

  getExistingFilesForAttachment = (file_id, mode = null) => {
    this.setState({ attachmentLoading: true });
    const queryString = file_id
      .map((file) => `file_ids[]=${file.id}`)
      .join("&");
    const url = `${postFileSpecifications}?${queryString}`;

    axios
      .get(url)
      .then((res) => {
        this.setState({ attachments: res.data.data });
        if (mode === "itemize") {
          this.setState({
            dynamicFileUploadItemsOpen: true,
            attachmentLoading: false,
          });
        } else {
          this.setState({
            dynamicFileUploadOpen: true,
            attachmentLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err, " downloadFile error");
        this.props.openSnackbar(
          "error",
          err.response.data?.message
            ? err.response.data?.message
            : "Something went wrong!"
        );
        this.setState({ attachmentLoading: false });
      });
  };
  isValidJsonString = (str) => {
    try {
      const parsed = JSON.parse(str);

      return typeof parsed === "object" && parsed !== null;
    } catch (e) {
      return false;
    }
  };
  finalFormulaFunction = (
    formula,
    placeholders,
    // basicPriceRate,
    fieldId,
    key,
    value,
    rowdata,
    fromRD = false
  ) => {
    let mainData = this.state.mainTemplateQuotation[key];
    placeholders.forEach((placeholder) => {
      const matchId = placeholder.slice(2, -1);
      let matchValue =
        (mainData && mainData[matchId] && mainData[matchId].value) || undefined;
      if (matchValue === undefined) {
        let fieldCell =
          // this.props.quotationJsonTemplate &&
          // this.props.quotationJsonTemplate.ITEMIZE &&
          // this.props.quotationJsonTemplate.ITEMIZE[0] && //Refactor
          // this.props.quotationJsonTemplate.ITEMIZE[0][fieldId] &&
          // this.props.quotationJsonTemplate.ITEMIZE[0][fieldId].find(
          //   (cell) => cell.FieldId === matchId
          // );
          // if (fieldCell) {
          //   matchValue = "(" + fieldCell.DefaultValue.Formula + ")";
          //   let TemperoraryPlaceholders = "";
          //   if (
          //     fieldCell.DefaultValue.Formula &&
          //     fieldCell.DefaultValue.Formula !== ""
          //   ) {
          //     TemperoraryPlaceholders =
          //       fieldCell.DefaultValue.Formula.match(/\(f\d+\)/g);
          //   } else if (
          //     fieldCell.DefaultFormula &&
          //     fieldCell.DefaultFormula !== ""
          //   ) {
          //     TemperoraryPlaceholders =
          //       fieldCell.DefaultFormula.match(/\(f\d+\)/g);
          //   }
          //   if (TemperoraryPlaceholders != "") {
          //     let data_hash = this.finalFormulaFunction(
          //       fieldCell.DefaultValue.Formula,
          //       TemperoraryPlaceholders,
          //       // basicPriceRate,
          //       matchId,
          //       key,
          //       0,
          //       rowdata,
          //       fromRD
          //     );
          //     if (data_hash.eval) {
          //       matchValue = eval(data_hash.formula);
          //     } else {
          //       matchValue = data_hash.value;
          //     }
          //   } else {
          //     matchValue = fieldCell.DefaultValue || 0;
          //   }
          // } else {
          (matchValue =
            (this.state.additionalInfoTemplate &&
              this.state.additionalInfoTemplate[matchId] &&
              this.state.additionalInfoTemplate[matchId].value) ||
            0);
        if (
          this.state.additionalInfoTemplate &&
          this.state.additionalInfoTemplate[matchId] &&
          this.state.additionalInfoTemplate[matchId].Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }

        if (
          this.state.additionalInfoTemplate &&
          this.state.additionalInfoTemplate[matchId] &&
          this.state.additionalInfoTemplate[matchId].Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        fieldCell = this.state.additionalInfoTemplate[0]?.find(
          (cell) => cell.FieldId === matchId
        );
        // }
      }
      // if (matchValue === "") {
      //   matchValue = '';
      // }
      if (matchValue !== undefined) {
        // If value is defined, update the result
        if (
          mainData &&
          mainData[matchId] &&
          mainData[matchId].Identifier === "GSTType"
        ) {
          matchValue = "'" + matchValue + "'";
        }
        if (
          mainData &&
          mainData[matchId] &&
          mainData[matchId].Identifier === "GSTValue"
        ) {
          matchValue = "'" + (matchValue - 0.0) + "'";
        }
        // "Identifier": "GSTValue",
        // if (basicPriceRate.FieldId === fieldId) {
        //   value = matchValue || 0;
        //   return { formula: formula, value: value, eval: 0 };
        // } else {
        formula = formula.replace(placeholder, matchValue);
        // }
      }
    });
    return { formula: formula, value: value, eval: 1 };
  };

  fetchPrevious = () => {
    const { quotationJsonTemplate, lastTransactionData } = this.props;
    if (quotationJsonTemplate?.ITEMIZE?.length && lastTransactionData) {
      this.setState({
        prevQuotationFormLoading: true,
      });
      let notFilteredMainTemplateQuotation = {};
      let notFilteredSlabMainTemplateQuotation = {};

      let switchStateManage = {};
      let switchStateToggleManage = {};
      let slabChildStateToggleManage = {};
      let mainTemplateQuotation = _.cloneDeep(this.state.mainTemplateQuotation);
      let multiSolmainTemplateQuotation = _.cloneDeep(
        this.state.mainTemplateQuotation
      );
      let slabMainTemplateQuotation = _.cloneDeep(
        this.state.mainTemplateQuotation
      );
      let remainTemplateQuotation = _.cloneDeep(
        this.state.remainTemplateQuotation
      );
      let additionalInfoTemplate;
      if (this.props.vendorType == "Local") {
        additionalInfoTemplate = this.initializeLocalRecipientsState();
      } else {
        additionalInfoTemplate = this.initializeIntlRecipientsState();
      }

      /**
       * old code in case issue is there open this and commit
       */

      /*For Main Template */
      for (const key in mainTemplateQuotation) {
        const currentItem = mainTemplateQuotation[key];

        for (const key2 in currentItem) {
          const currentField = currentItem[key2];
          if (
            currentField?.Identifier === "GSTType" &&
            currentField?.["DefaultFormula"] == "" &&
            this.props.selectedQuotedItems.vendors[0]?.gst_type
          ) {
            if (
              !this.props.selectedQuotedItems.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let substring =
                this.props.selectedQuotedItems.vendors[0]?.gst_type.split(
                  "_"
                )[0];

              const filteredData = currentField.options.find(
                (item) =>
                  item.code.includes(substring) && !item.code.includes("UT")
              );
              currentField.value = filteredData?.code;
            } else if (
              this.props.selectedQuotedItems.vendors[0]?.gst_type.includes(
                "###"
              )
            ) {
              let optionArray = [];
              let subArray =
                this.props.selectedQuotedItems.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
          // const defaultFormula = currentField?.["DefaultFormula"];
          if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
            const defaultFormula = currentField["DefaultFormula"];
            if (defaultFormula) {
              const formattedResult = this.extractAndFormat(defaultFormula);

              if (formattedResult && formattedResult.length >= 1) {
                const valuesFound = formattedResult
                  .map((identifier, index) => {
                    const attributeKey = identifier.split(".");
                    const subStringAttrKey = attributeKey[1].substring(1);
                    // Check if the solution array exists and has the identifier
                    const valuesInSolution = lastTransactionData.itemize[
                      key
                    ]?.solution
                      ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                      ?.filter((value) => value !== undefined);
                    // Fallback to checking in common if valuesInSolution is empty or undefined
                    const fallbackValue =
                      lastTransactionData.common?.[subStringAttrKey];
                    const fetchTxn = this.props.fetchedTxn?.[
                      attributeKey[0]
                    ]?.itemize[key]?.solution
                      ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                      ?.filter((value) => value !== undefined);
                    if (valuesInSolution && valuesInSolution.length > 0) {
                      return valuesInSolution;
                    } else if (
                      valuesInSolution &&
                      valuesInSolution.length == 0 &&
                      fetchTxn &&
                      fetchTxn.length > 0
                    ) {
                      return fetchTxn;
                    } else if (fallbackValue !== undefined) {
                      return [fallbackValue];
                    } else {
                      return [];
                    }
                  })
                  .flat();

                let finalValue = null;
                // Evaluate conditional statements in DefaultFormula
                for (let i = 0; i < valuesFound.length; i++) {
                  // if (valuesFound[i] !== null) {
                  finalValue = valuesFound[i];
                  break;
                  // }
                }
                if (finalValue !== null) {
                  const integerPattern = /^\d+$/;
                  currentItem[key2]["value"] =
                    integerPattern.test(finalValue) &&
                    currentItem[key2].Identifier != "GSTValue"
                      ? parseInt(finalValue, 10)
                      : finalValue;
                  // currentItem[key2]["value"] = finalValue;

                  currentItem[key2]["error"] = false;

                  if (currentItem[key2]["Identifier"] === "ProductQuantity") {
                    currentItem["qty"] = finalValue;
                  }

                  if (
                    currentItem[key2]["Identifier"] === "EstimatedCost" ||
                    currentItem[key2]["Identifier"] ===
                      "EstimatedCostForeign" ||
                    currentItem[key2]["Identifier"] === "Budgetedrate" ||
                    currentItem[key2]["Identifier"] === "Rate" ||
                    currentItem[key2]["Identifier"] === "CIFRate" ||
                    currentItem[key2]["Identifier"] === "rate"
                  ) {
                    currentItem["rate"] = finalValue;
                  }

                  const attributeName = currentItem[key2]["HTMLAttributeName"];
                  const index1 = this.state.errorArray.indexOf(attributeName);

                  if (index1 !== -1) {
                    this.setState((prevState) => ({
                      errorArray: prevState.errorArray.filter(
                        (item, index) => index !== index1
                      ),
                    }));
                  }
                }
              }
            }
          } else {
            // console
            //   .log
            //   // "Skipping currentField because DefaultFormula is undefined."
            //   ("currentFieldValue", currentField);
          }
        }
      }

      /*For Multi solution */
      for (const key in multiSolmainTemplateQuotation) {
        const currentItem = multiSolmainTemplateQuotation[key];

        // Get the solutions array from lastTransactionData
        const solutions = lastTransactionData.itemize[key]?.solution || [];
        let newItemArray = [];

        if (solutions.length > 1) {
          switchStateToggleManage[key] = true;
          switchStateManage[key] = true;
        } else {
          switchStateManage[key] = false;
        }

        solutions.forEach((solution, solutionIndex) => {
          let newItem = {};

          // Initialize newItem with all fields from currentItem
          for (const key2 in currentItem) {
            newItem[key2] = currentItem[key2];
          }

          for (const key2 in currentItem) {
            const currentField = currentItem[key2];

            // Check if the current field is GSTType with an empty DefaultFormula
            if (
              currentField?.Identifier === "GSTType" &&
              currentField?.["DefaultFormula"] === ""
            ) {
              if (this.props.selectedQuotedItems.vendors[0]?.gst_type) {
                let substring =
                  this.props.selectedQuotedItems.vendors[0]?.gst_type.split(
                    "_"
                  )[0];
                const filteredData = currentField.options.find(
                  (item) =>
                    item.code.includes(substring) && !item.code.includes("UT")
                );
                newItem[key2] = { ...currentField, value: filteredData?.code };
              }
            }
            const defaultFormula = currentField?.["DefaultFormula"];
            if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
              if (defaultFormula) {
                const formattedResult = this.extractAndFormat(defaultFormula);

                if (formattedResult && formattedResult.length >= 1) {
                  const valuesFound = formattedResult
                    .map((identifier) => {
                      const attributeKey = identifier.split(".");
                      const subStringAttrKey = attributeKey[1].substring(1);
                      const valuesInSolution =
                        solution.data[0]?.[subStringAttrKey];

                      const fallbackValue =
                        lastTransactionData.common?.[subStringAttrKey];
                      const fetchTxn = this.props.fetchedTxn?.[
                        attributeKey[0]
                      ]?.itemize[key]?.solution
                        ?.map((obj) => obj?.data[0]?.[subStringAttrKey])
                        ?.filter((value) => value !== undefined);

                      if (valuesInSolution !== undefined) {
                        return valuesInSolution;
                      } else if (fetchTxn && fetchTxn.length > 0) {
                        return fetchTxn[0];
                      } else if (fallbackValue !== undefined) {
                        return fallbackValue;
                      } else {
                        return null;
                      }
                    })
                    .filter((value) => value !== null);

                  let finalValue =
                    valuesFound.length > 0 ? valuesFound[0] : null;

                  if (finalValue !== null) {
                    const integerPattern = /^\d+$/;
                    newItem[key2] = {
                      ...currentField,
                      value:
                        integerPattern.test(finalValue) &&
                        currentField.Identifier !== "GSTValue"
                          ? parseInt(finalValue, 10)
                          : finalValue,
                      error: false,
                    };

                    if (currentField.Identifier === "ProductQuantity") {
                      newItem["qty"] = finalValue;
                    }

                    if (
                      currentField.Identifier === "EstimatedCost" ||
                      currentField.Identifier === "EstimatedCostForeign" ||
                      currentField.Identifier === "Budgetedrate" ||
                      currentField.Identifier === "Rate" ||
                      currentField.Identifier === "CIFRate" ||
                      currentField.Identifier === "rate"
                    ) {
                      newItem["rate"] = finalValue;
                    }

                    const attributeName = currentField["HTMLAttributeName"];
                  }
                }
              }
            }
          }

          newItemArray.push(newItem);
        });
        multiSolmainTemplateQuotation[key] = newItemArray;
      }

      for (const key in slabMainTemplateQuotation) {
        const currentItem = slabMainTemplateQuotation[key];
        const solutions =
          lastTransactionData.itemize[key]?.solution[0].data || [];
        let newItemArray = [];
        solutions.forEach((solution, solutionIndex) => {
          let newItem = {};

          // Initialize newItem with all fields from currentItem
          for (const key2 in currentItem) {
            const currentField = currentItem[key2];

            // Check if the field should be directly assigned or copied as an object
            if (
              key2 === "qty" ||
              key2 === "specification" ||
              key2 === "added_after_rfq_created" ||
              key2 === "is_deleted_from_master" ||
              key2 === "is_unlink_from_category" ||
              key2 === "selected" ||
              key2 === "formdetailRowId" ||
              key2 === "_range" ||
              key2 === "select_slab" ||
              key2 === "rate"
            ) {
              // Direct assignment without wrapping in an object
              newItem[key2] = currentField;
            } else {
              // Copy the object for other fields
              newItem[key2] = { ...currentField };
            }
          }

          // Ensure slabChildStateToggleManage[key] is initialized as an array
          if (!slabChildStateToggleManage[key]) {
            slabChildStateToggleManage[key] = []; // Initialize as an array
          }

          // Make sure the array has a slot for solutionIndex
          if (!slabChildStateToggleManage[key][solutionIndex]) {
            slabChildStateToggleManage[key][solutionIndex] = false; // Initialize the slot
          }

          // Ensure _range and select_slab are copied from the solution
          newItem["_range"] = solution["_range"];
          newItem["select_slab"] = solution["select_slab"];

          // Update the flag for this key and solutionIndex if "select_slab" is "Y"
          if (solution["select_slab"] === "Y") {
            slabChildStateToggleManage[key][solutionIndex] = true; // Mark this key's solution as having "select_slab: Y"
          } else {
            slabChildStateToggleManage[key][solutionIndex] = false; // Mark as false otherwise
          }

          for (const key2 in currentItem) {
            const currentField = currentItem[key2];

            if (currentField && currentField.hasOwnProperty("DefaultFormula")) {
              // Handle GSTType with an empty DefaultFormula
              if (
                currentField.Identifier === "GSTType" &&
                currentField["DefaultFormula"] === ""
              ) {
                const gstType =
                  this.props.selectedQuotedItems.vendors[0]?.gst_type;

                if (gstType) {
                  if (!gstType.includes("###")) {
                    // Case where GST type does not include "###"
                    let substring = gstType.split("_")[0];
                    const filteredData = currentField.options.find(
                      (item) =>
                        item.code.includes(substring) &&
                        !item.code.includes("UT")
                    );
                    newItem[key2] = {
                      ...currentField,
                      value: filteredData?.code,
                    };
                  } else {
                    // Case where GST type includes "###"
                    let optionArray = [];
                    let subArray = gstType.split("###");
                    subArray.forEach((element) => {
                      let substring = element.split("_")[0];
                      const filteredData = currentField.options.find(
                        (item) =>
                          item.code.includes(substring) &&
                          !item.code.includes("UT")
                      );
                      optionArray.push(filteredData?.code);
                    });
                    newItem[key2]["value"] = optionArray.join("###");
                  }
                }
              }

              const defaultFormula = currentField["DefaultFormula"];
              if (defaultFormula) {
                const formattedResult = this.extractAndFormat(defaultFormula);

                if (formattedResult && formattedResult.length >= 1) {
                  const valuesFound = formattedResult
                    .map((identifier) => {
                      const attributeKey = identifier.split(".");
                      const subStringAttrKey = attributeKey[1].substring(1);
                      const valuesInSolution = solution[subStringAttrKey];
                      const fallbackValue =
                        lastTransactionData.common?.[subStringAttrKey];
                      let fetchTxn;
                      if (newItem["select_slab"] === "N") {
                        fetchTxn = this.props.fetchedTxn?.[1]?.itemize[
                          key
                        ]?.solution[0].data
                          ?.map(
                            (obj) =>
                              obj[
                                this.state.fieldIdentifierMapping?.[
                                  currentField.Identifier
                                ]
                              ]
                          )
                          ?.filter((value) => value !== undefined);
                      } else {
                        fetchTxn = this.props.fetchedTxn?.[
                          attributeKey[0]
                        ]?.itemize[key]?.solution[0].data
                          ?.map((obj) => obj[subStringAttrKey])
                          ?.filter((value) => value !== undefined);
                      }
                      if (valuesInSolution !== undefined) {
                        return valuesInSolution;
                      } else if (fetchTxn && fetchTxn.length > 0) {
                        return fetchTxn[solutionIndex];
                      } else if (fallbackValue !== undefined) {
                        return fallbackValue;
                      } else {
                        return null;
                      }
                    })
                    .filter((value) => value !== null);

                  let finalValue =
                    valuesFound.length > 0 ? valuesFound[0] : null;

                  if (finalValue !== null) {
                    const integerPattern = /^\d+$/;
                    newItem[key2] = {
                      ...currentField,
                      value:
                        integerPattern.test(finalValue) &&
                        currentField.Identifier !== "GSTValue"
                          ? parseInt(finalValue, 10)
                          : finalValue,
                      error: false,
                    };

                    if (currentField.Identifier === "ProductQuantity") {
                      newItem["qty"] = finalValue;
                    }

                    if (
                      currentField.Identifier === "EstimatedCost" ||
                      currentField.Identifier === "EstimatedCostForeign" ||
                      currentField.Identifier === "Budgetedrate" ||
                      currentField.Identifier === "Rate" ||
                      currentField.Identifier === "CIFRate" ||
                      currentField.Identifier === "rate"
                    ) {
                      newItem["rate"] = finalValue;
                    }
                  }
                }
              }
            }
          }
          newItemArray.push(newItem);
        });
        slabMainTemplateQuotation[key] = newItemArray;
        slabMainTemplateQuotation[key].selected = currentItem?.selected;
      }

      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        if (
          currentField.Identifier === "GSTType" &&
          currentField?.["DefaultFormula"] == "" &&
          this.props.selectedQuotedItems.vendors[0]?.gst_type
        ) {
          if (
            !this.props.selectedQuotedItems.vendors[0]?.gst_type?.includes(
              "###"
            )
          ) {
            let substring =
              this.props.selectedQuotedItems.vendors[0]?.gst_type.split("_")[0];
            const filteredData = currentField.options.find(
              (item) =>
                item.code.includes(substring) && !item.code.includes("UT")
            );
            currentField.value = filteredData?.code;
          } else if (
            this.props.selectedQuotedItems.vendors[0]?.gst_type.includes("###")
          ) {
            {
              let optionArray = [];
              let subArray =
                this.props.selectedQuotedItems.vendors[0]?.gst_type.split(
                  "###"
                );
              subArray.map((element) => {
                const filteredData = currentField.options.find((item) => {
                  let substring = element.split("_")[0];
                  return (
                    item.code.includes(substring) && !item.code.includes("UT")
                  );
                });
                optionArray.push(filteredData?.code);
              });
              currentField.value = optionArray.join("###");
            }
          }
        }
        const attributeNameWithoutF = currentField.HTMLAttributeName
          ? currentField.HTMLAttributeName.replace("f", "")
          : null;
        if (
          attributeNameWithoutF &&
          lastTransactionData.common.hasOwnProperty(attributeNameWithoutF)
        ) {
          // Set the value to the currentField
          currentField.value =
            lastTransactionData.common[attributeNameWithoutF];
          currentField.error = false;

          // Remove field from errorArray if present
          const index1 = this.state.errorArray.indexOf(attributeNameWithoutF);
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }

          // Set value to corresponding HTML element if it exists
          const element = document.getElementsByName(attributeNameWithoutF)[0];
          if (element) {
            element.value = currentField.value;
          }
        } else if (
          currentField.DefaultFormula &&
          currentField.DefaultFormula.trim() != ""
        ) {
          // Extract attribute codes from DefaultFormula
          const attributeCodes =
            currentField.DefaultFormula.match(/f\d+/g) || [];
          const formattedResult = this.extractAndFormat(
            currentField.DefaultFormula
          );
          // Iterate over each attribute code
          formattedResult.forEach((attributeCode) => {
            // Extract attribute key from attributeCode
            const attributeKey = attributeCode.split(".");
            const subStringAttrKey = attributeKey[1].substring(1);
            // Check if the attribute key exists in lastTransactionData.common
            if (lastTransactionData.common.hasOwnProperty(subStringAttrKey)) {
              // Set the value to the currentField
              if (
                this.isValidJsonString(
                  lastTransactionData.common[subStringAttrKey]
                ) &&
                lastTransactionData.common[subStringAttrKey] &&
                currentField.ElementType !== "F"
              ) {
                let element = JSON.parse(
                  lastTransactionData.common[subStringAttrKey]
                )[this.props.selectedQuotedItems.vendors[0].id];

                currentField.value =
                  this.props.companyInfo?.default_dropdown_type == 10
                    ? `${element.element_name}-${element.element_code}`
                    : `${element.element_code}-${element.element_name}`;
              } else {
                currentField.value =
                  lastTransactionData.common[subStringAttrKey];
              }
              currentField.error = false;
              // Remove field from errorArray if present
              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              // Set value to corresponding HTML element if it exists
              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            } else if (
              this.props.fetchedTxn?.[attributeKey[0]]?.common.hasOwnProperty(
                subStringAttrKey
              )
            ) {
              currentField.value =
                this.props.fetchedTxn?.[attributeKey[0]]?.common[
                  subStringAttrKey
                ];
              currentField.error = false;

              const index2 = this.state.errorArray.indexOf(
                currentField.HTMLAttributeName
              );
              if (index2 !== -1) {
                this.state.errorArray.splice(index2, 1);
              }

              const element = document.getElementsByName(
                currentField.HTMLAttributeName
              )[0];
              if (element) {
                element.value = currentField.value;
              }
            }
          });
        } else if (
          currentField.DefaultFormula == "" &&
          currentField.DefaultValue.length > 0 &&
          currentField.ElementType == "T"
        ) {
          currentField.value = currentField.DefaultValue;
        }
        const identifier = currentField.Identifier;
        if (typeof identifier === "string" && identifier.includes("CurrencyMaster")) {
          this.handleMasterCurrencyChange(currentField.value, currentField)
        }
      });
      // Filter out items not present in inquiry_item_ids
      if (this.props.itemSpecific === true) {
        const filteredMainTemplateQuotation = Object.fromEntries(
          Object.entries(mainTemplateQuotation).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        const NotfilteredMainTemplateQuotation = Object.fromEntries(
          Object.entries(mainTemplateQuotation)
            .filter(
              ([key]) => !this.props.inquiryItemsId.includes(parseInt(key))
            )
            .map(([key, value]) => {
              return [key, { ...value, selected: "N" }];
            })
        );
        mainTemplateQuotation = filteredMainTemplateQuotation;
        notFilteredMainTemplateQuotation = NotfilteredMainTemplateQuotation;
      }

      if (
        this.props.itemSpecific === true &&
        this.props.multipleSolutionFlag === true &&
        !this.props.slabFlag
      ) {
        const filteredMSmainTemplateQuotation = Object.fromEntries(
          Object.entries(multiSolmainTemplateQuotation).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        multiSolmainTemplateQuotation = filteredMSmainTemplateQuotation;
      }

      if (
        this.props.itemSpecific === true &&
        this.props.multipleSolutionFlag === true &&
        this.props.slabFlag === true
      ) {
        const NotfilteredSlabMainTemplateQuotation = Object.fromEntries(
          Object.entries(slabMainTemplateQuotation)
            .filter(
              ([key]) => !this.props.inquiryItemsId.includes(parseInt(key))
            )
            .map(([key, value]) => {
              // Update 'selected' and 'select_slab' properties
              value.selected = "N";
              value.map((val) => {
                val.select_slab = "N";
                val.selected = "N";
                return val;
              });

              // Return the updated key-value pair
              return [key, value];
            })
        );
        notFilteredSlabMainTemplateQuotation =
          NotfilteredSlabMainTemplateQuotation;
        const filteredSlabmainTemplateQuotation = Object.fromEntries(
          Object.entries(slabMainTemplateQuotation).filter(([key]) =>
            this.props.inquiryItemsId.includes(parseInt(key))
          )
        );
        slabMainTemplateQuotation = filteredSlabmainTemplateQuotation;
      }

      // Replace mainTemplateQuotation with filteredMainTemplateQuotation
      this.setState({
        remainTemplateQuotation,
        switchState: switchStateManage,
        switchStateToggleCheck: switchStateToggleManage,
        slabChildStateToggleCheck: slabChildStateToggleManage,
        multiSolmainTemplateQuotation,
        slabMainTemplateQuotation,
        mainTemplateQuotation,
        intialMaintemplateQuotation: mainTemplateQuotation,
        additionalInfoTemplate,
        notFilteredMainTemplateQuotation,
        notFilteredSlabMainTemplateQuotation,
        prevQuotationFormLoading: false, // Assuming loading is finished after updating the state
      });
    }
  };

  handleSave = (savedValues) => {
    const mainTemplateQuotation = _.cloneDeep(this.state.mainTemplateQuotation);
    const multiSolmainTemplateQuotation = _.cloneDeep(
      this.state.multiSolmainTemplateQuotation
    );
    const slabMainTemplateQuotation = _.cloneDeep(
      this.state.slabMainTemplateQuotation
    );

    let mainFound = 0;
    let multiSolFound = 0;
    let slabFound = 0;

    // Iterate over mainTemplateQuotation
    for (const key in mainTemplateQuotation) {
      const currentItem = mainTemplateQuotation[key];

      for (const key2 in currentItem) {
        if (currentItem[key2].Identifier === "GSTType") {
          mainFound = 1;
          currentItem[key2].value = savedValues;
          currentItem[key2].error = false;
        }
      }
    }

    // Iterate over multiSolmainTemplateQuotation
    {
      this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(multiSolmainTemplateQuotation).forEach((mainIndex) => {
          multiSolmainTemplateQuotation[mainIndex].forEach(
            (subItem, subIndex) => {
              for (const key in subItem) {
                if (subItem[key].Identifier === "GSTType") {
                  multiSolFound = 1;
                  subItem[key].value = savedValues;
                }
              }
            }
          );
        });
    }

    // Update slabMainTemplateQuotation
    if (this.props.multipleSolutionFlag && this.props.slabFlag) {
      Object.keys(slabMainTemplateQuotation).forEach((mainIndex) => {
        slabMainTemplateQuotation[mainIndex].forEach((subItem) => {
          for (const key in subItem) {
            if (subItem[key] && subItem[key].Identifier === "GSTType") {
              slabFound = 1;
              // Preserve the selected key
              const selected =
                this.state.slabMainTemplateQuotation[mainIndex].selected;
              // Update the specific field in the selected row
              slabMainTemplateQuotation[mainIndex] = slabMainTemplateQuotation[
                mainIndex
              ].map((item) => {
                return {
                  ...item,
                  [key]: {
                    ...item[key],
                    value: savedValues, // Update value
                  },
                };
              });

              // Restore the selected key
              slabMainTemplateQuotation[mainIndex].selected = selected;
            }
          }
        });
      });
    }

    if (
      (this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        multiSolFound !== 1) ||
      (!this.props.multipleSolutionFlag && mainFound !== 1) ||
      (this.props.multipleSolutionFlag &&
        this.props.slabFlag &&
        slabFound !== 1)
    ) {
      const additionalInfoTemplate = _.cloneDeep(
        this.state.additionalInfoTemplate
      );
      for (const key in additionalInfoTemplate) {
        if (additionalInfoTemplate[key].Identifier === "GSTType") {
          additionalInfoTemplate[key].value = savedValues;
        }
      }
      this.setState({ additionalInfoTemplate });
    } else {
      // Update mainTemplateQuotation if mainFound is 1
      if (mainFound === 1) {
        this.setState({ mainTemplateQuotation });
      }

      // Update multiSolmainTemplateQuotation if multiSolFound is 1
      if (multiSolFound === 1) {
        this.setState({ multiSolmainTemplateQuotation });
      }

      // Update slabMainTemplateQuotation if slabFound is 1
      if (slabFound === 1) {
        this.setState({ slabMainTemplateQuotation });
      }
    }
  };

  handleCheckboxChange = (event, key) => {
    const { checked } = event.target; // Get the checked state of the checkbox
    const { errorArrayForMSParentName } = this.state;
    const updatedErrorArrayForMSParentName = { ...errorArrayForMSParentName };

    // added to solve : removing solution name error when item is unchecked :: START
    const { solutionNameErrors } = this.state;
    Object.keys(solutionNameErrors).forEach((key_sol) => {
      // Extract itemId or key prefix
      if (!checked) {
        if (key_sol.split("-")[0] == key) {
          delete solutionNameErrors[key_sol];
        }
      }
    });
    // added to solve : removing solution name error when item is unchecked :: END

    // Ensure that mainTemplateQuotation and lastTransactionTypeInThread exist before accessing their properties
    if (
      this.state.mainTemplateQuotation &&
      this.props.lastTransactionInThread &&
      this.state.mainTemplateQuotation[key] &&
      this.props.lastTransactionInThread[key]
    ) {
      const isNegotiation =
        this.props.lastTransactionTypeInThread === "Negotiation";
      const lastSelected =
        this.props.lastTransactionInThread[key].selected === "Y";
      const mainSelected =
        this.state.mainTemplateQuotation[key].selected === "Y";
      const is_deleted_from_master =
        this.props.items[key]?.is_deleted_from_master;
      const is_unlink_from_category =
        this.props.items[key]?.is_unlink_from_category;
      // Check if filteredSelectedNotData contains the key and its selected value is 'N'
      const filteredSelectedNotDataContainsKey =
        this.props.filteredSelectedNotData &&
        this.props.filteredSelectedNotData[key] &&
        this.props.filteredSelectedNotData[key].selected;

      if (!checked) {
        delete updatedErrorArrayForMSParentName[key]; // Remove the key from the error array
      }
      // Check if either is_deleted_from_master or is_unlink_from_category is true, if so, disable input fields
      if (is_deleted_from_master || is_unlink_from_category) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: "N",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (this.props.lastTransactionTypeInThread === "Inquiry") {
        if (!event.target.checked) {
          const updatedQuotationinitial = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.intialMaintemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: true, // Reset readOnly to false to enable input fields
            },
          };
          this.setState({ mainTemplateQuotation: updatedQuotationinitial });
        } else {
          const updatedQuotation = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.mainTemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: true, // Reset readOnly to false to enable input fields
            },
          };
          this.setState({ mainTemplateQuotation: updatedQuotation });
        }
      } else if (isNegotiation && lastSelected) {
        // Disable input fields if the transaction is negotiation and the last transaction selected the item
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: "Y",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey == "N" &&
        mainSelected
      ) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey == "N" &&
        !mainSelected
      ) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (
        isNegotiation &&
        !lastSelected &&
        filteredSelectedNotDataContainsKey !== "N"
      ) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: "Y",
            readOnly: true, // Set readOnly to true to disable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else if (mainSelected && !lastSelected) {
        // for issue TU2-I269
        if (!event.target.checked) {
          const updatedQuotationInitial = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.intialMaintemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: false, // Reset readOnly to false to enable input fields
            },
          };

          this.setState({ mainTemplateQuotation: updatedQuotationInitial });
        } else {
          const updatedQuotation = {
            ...this.state.mainTemplateQuotation,
            [key]: {
              ...this.state.mainTemplateQuotation[key],
              selected: event.target.checked ? "Y" : "N",
              readOnly: false, // Reset readOnly to false to enable input fields
            },
          };

          this.setState({ mainTemplateQuotation: updatedQuotation });
        }
      } else if (!mainSelected) {
        // Allow toggling between 'Y' and 'N' when mainSelected is 'N'
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };

        this.setState({ mainTemplateQuotation: updatedQuotation });
      } else {
        // Check if filteredSelectedNotData contains the key and its selected value is 'N'
        const filteredSelectedNotDataContainsKey =
          this.props.filteredSelectedNotData &&
          this.props.filteredSelectedNotData[key] &&
          this.props.filteredSelectedNotData[key].selected === "N";

        // If filteredSelectedNotData contains the key and its selected value is 'N', set selected to 'N'
        const selectedStatus = filteredSelectedNotDataContainsKey ? "N" : "Y";
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: selectedStatus,
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
    } else {
      if (
        this.state?.mainTemplateQuotation[key]?.added_after_rfq_created == true
      ) {
        const updatedQuotation = {
          ...this.state.mainTemplateQuotation,
          [key]: {
            ...this.state.mainTemplateQuotation[key],
            selected: event.target.checked ? "Y" : "N",
            readOnly: false, // Reset readOnly to false to enable input fields
          },
        };
        this.setState({ mainTemplateQuotation: updatedQuotation });
      }
      console.error(
        `Template with key ${key} does not exist in either mainTemplateQuotation or lastTransactionInThread.`
      );
    }
    // Update the state with the modified error array
    this.setState({
      errorArrayForMSParentName: updatedErrorArrayForMSParentName,
    });
  };

  clearMultiSolApplyToAll = (key, FieldId) => {
    this.setState((prevState) => ({
      multiSolApplyToAll: {
        ...prevState.multiSolApplyToAll,
        [`${key}_${FieldId}_multiSolApplyToAll`]: false,
      },
    }));
  };

  // Function to update multiSolmainTemplateQuotation
  updateMultiSolmainTemplateQuotation = (key) => {
    this.setState((prevState) => {
      const newState = { ...prevState.multiSolmainTemplateQuotation };

      if (newState[key] && Array.isArray(newState[key])) {
        // Reset the array to only contain the 0th index with the initial data
        newState[key] = [{ ...this.state.mainTemplateQuotation[key] }];
      } else {
        // Initialize the array with the initial data if it doesn't exist
        newState[key] = [{ ...this.state.mainTemplateQuotation[key] }];
      }

      return { multiSolmainTemplateQuotation: newState };
    });
  };

  handleQuotationCheckboxChange = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState({ quotationChecked: checked });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    //handle multiplant on of condition
    let updateRFQDynamic = this.state.RFQDynamic;
    if (
      this.props.location?.pathname.indexOf("/prcombination") == -1 &&
      this.props.rfqSettingValues.allowMultiPlant !=
        nextProps.rfqSettingValues.allowMultiPlant
    ) {
      for (const key in updateRFQDynamic) {
        if (
          !nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = "";
          // updateRFQDynamic[key]["error"] = true;
        } else if (
          nextProps.rfqSettingValues.allowMultiPlant &&
          updateRFQDynamic[key]["Identifier"] == "Destination"
        ) {
          updateRFQDynamic[key]["value"] = [];
          // updateRFQDynamic[key]["error"] = true;
        }
      }
      this.setState({
        RFQDynamic: updateRFQDynamic,
      });
    }
    if (
      _.isEqual(nextProps.defaultValues, this.props.defaultValues) === false
    ) {
      let RFQStaticUpdate = this.state.RFQStatic;
      const { validityDate, priorityRfq, sealedGroupRfq, validityTime } =
        nextProps.defaultValues;
      if ((validityDate._d || validityDate) && validityDate !== null) {
        RFQStaticUpdate.RFQValidity.value = validityDate;
        RFQStaticUpdate.RFQValidityTime.value = validityTime;
        RFQStaticUpdate.TBValidity.value = validityDate;
        RFQStaticUpdate.TBValidityTime.value = validityTime;
      } else {
        RFQStaticUpdate.RFQValidity.value = "";
        RFQStaticUpdate.TBValidity.value = "";
      }
      RFQStaticUpdate.RFQValidity.error = false; //set rfqvalidity error false
      RFQStaticUpdate.lock = sealedGroupRfq;
      RFQStaticUpdate.star = priorityRfq;
      this.setState({
        RFQStatic: RFQStaticUpdate,
      });
    }
    if (nextProps.remiderDrawer?.startDate) {
      this.setState({
        startDate: nextProps.remiderDrawer?.startDate,
      });
    } else {
      this.setState({
        startDate: "",
      });
    }

    this.setState({
      startDateTB: nextProps.remiderDrawer?.includeReminderInterval,
    });

    if (nextProps.selectedItems?.length !== this.props.selectedItems?.length) {
      this.setState({
        errorArray: [],
      });
      if (nextProps.selectedItems.length > this.props.selectedItems.length) {
        //item added,
        let itemsToAdd = nextProps.selectedItems.filter(
          (newItem) => !this.props.selectedItems.includes(newItem)
        );

        for (let index = 0; index < itemsToAdd.length; index++) {
          const newItem = itemsToAdd[index];
          for (
            let j = 0;
            j < this.props.quotationJsonTemplate?.ITEMIZE.length;
            j++
          ) {
            const jsonRow = this.props.quotationJsonTemplate?.ITEMIZE[j];
            if (
              !this.state.usedAttributesId.includes(
                jsonRow[1]?.Child?.AttributeId
              )
            ) {
              // add item here and push it into the usedAttributesId
              this.state.usedAttributesId.push(jsonRow[1]?.Child?.AttributeId);
              this.setState(
                {
                  usedAttributesId: this.state.usedAttributesId,
                },
                () => {
                  let obj = {};
                  jsonRow.forEach((field) => {
                    if (this.state.applyToAll[`${field.FieldId}_applyToAll`]) {
                      this.setState({
                        applyToAll: {
                          ...this.state.applyToAll,
                          [`${field.FieldId}_applyToAll`]: false,
                        },
                      });
                    }
                    if (field.VisibilityFlag === "Y") {
                      if (
                        field.Identifier === "Product" ||
                        field.Identifier === "ProductForeign"
                      ) {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.old_id,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      if (field.Identifier === "ProductCode") {
                        obj[field.FieldId] = {
                          HTMLAttributeName: field.HTMLAttributeName,
                          value: newItem.code,
                          Validation: "",
                          FieldLabel: field.FieldLabel,
                          error: false,
                          Identifier: field.Identifier,
                          ErrorMessage: "",
                        };
                        return;
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                      };
                      if (
                        field.Identifier === "EstimatedCost" ||
                        field.Identifier === "EstimatedCostForeign" ||
                        field.Identifier === "Budgetedrate" ||
                        field.Identifier === "BudgetedrateForeign"
                      ) {
                        obj[field.FieldId][
                          `upd_budget_master_${newItem.id}`
                        ] = false;

                        if (
                          this.props.featurePermission
                            ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                            ?.feature_value === "Y" &&
                          newItem?.budgeted_rate
                        ) {
                          if (parseFloat(newItem?.budgeted_rate)) {
                            obj[field.FieldId]["value"] = newItem.budgeted_rate;
                            obj["rate"] = newItem.budgeted_rate;
                          } else {
                            obj["rate"] = "";
                          }
                        } else {
                          obj["rate"] = "";
                        }
                      }
                      if (field.ElementType === "S") {
                        if (field.DropdownEdit === "Y") {
                          obj[field.FieldId]["options"] = "loading";
                          obj[field.FieldId]["options"] =
                            this.getItemizeOptions(field);
                        } else {
                          obj[field.FieldId]["options"] = field.DefaultValue;
                        }
                      }
                      if (
                        field.Identifier === "BasicHistoricalPrice" ||
                        field.Identifier === "LandedHistoricalPrice" ||
                        field.Identifier === "DateHP"
                      ) {
                        obj[field.FieldId]["DynamicDefaultValue"] = "loading";
                        this.getDynamicDefaultValues(
                          field,
                          index,
                          field.DefaultValue
                        );
                      }
                    }
                    if (field.Identifier === "EstimatedValue") {
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: "",
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                      };
                    }
                    if (
                      Object.keys(this.props.prlineitems).length &&
                      prconfig &&
                      prconfig[field.Identifier] !== undefined
                    ) {
                      let readOnly = "";
                      let value =
                        eval(this[prconfig[field.Identifier].function_name])(
                          field.Identifier,
                          newItem
                        ) || "";

                      if (
                        prconfig[field.Identifier]?.non_editable_function &&
                        prconfig[field.Identifier]?.non_editable_function !==
                          "0"
                      ) {
                        if (
                          prconfig[field.Identifier]?.non_editable_function ==
                          "1"
                        ) {
                          readOnly = true;
                        } else {
                          readOnly = eval(
                            this[
                              prconfig[field.Identifier]?.non_editable_function
                            ]
                          )(value);
                        }
                      }
                      obj[field.FieldId] = {
                        HTMLAttributeName: field.HTMLAttributeName,
                        value: value,
                        Validation: field.Validation,
                        FieldLabel: field.FieldLabel,
                        error: false,
                        ErrorMessage: field.ErrorMessage,
                        Identifier: field.Identifier,
                        ElementType: field.ElementType,
                        readOnly: readOnly,
                      };
                    }
                  });
                  obj.specification = "";
                  obj.qty = "";
                  if (!obj.hasOwnProperty("slab")) {
                    obj["slab"] = [
                      { id: 1, start: "1", end: "", error: false },
                      { id: 2, start: "More Than", end: "", error: false },
                    ];
                  }
                  this.setState((prevState) => ({
                    mainTemplateQuotation: {
                      ...prevState.mainTemplateQuotation,
                      [newItem.id]: obj,
                    },
                  }));
                  if (this.props.rfqSettingValues.includeSlab) {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: true },
                      },
                    }));
                  } else {
                    this.setState((prevState) => ({
                      slab: {
                        ...prevState.slab,
                        [newItem.id]: { isOpen: false },
                      },
                    }));
                  }
                  if (this.state.applyToAllSlab == true) {
                    this.setState({
                      applyToAllSlab: false,
                    });
                  }
                }
              );
              break;
            }
          }
        }
      } else if (
        nextProps.selectedItems.length < this.props.selectedItems.length
      ) {
        //item removed,
        let itemsToRemove = this.props.selectedItems.filter(
          (newItem) => !nextProps.selectedItems.includes(newItem)
        );
        itemsToRemove.forEach((item) => {
          let index = this.state.usedAttributesId?.indexOf(
            this.state.mainTemplateQuotation[item.id][
              Object.keys(this.state.mainTemplateQuotation[item.id])[0]
            ]?.HTMLAttributeName?.split("_")[1]
          );
          this.state.usedAttributesId.splice(index, 1);
          this.setState({
            usedAttributesId: this.state.usedAttributesId,
          });
          delete this.state.mainTemplateQuotation[item.id];
          delete this.state.slab[item.id];
        });
        this.setState({
          mainTemplateQuotation: this.state.mainTemplateQuotation,
          slab: this.state.slab,
        });
      }
    }
    if (
      nextProps.selectedVendors?.length !== this.props.selectedVendors?.length
    ) {
      setTimeout(() => {
        for (const key in this.state.additionalInfoTemplate) {
          const element = this.state.additionalInfoTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
        for (const key in this.state.IntlTemplate) {
          const element = this.state.IntlTemplate[key];
          if (element.value) {
            if (document.getElementsByName(element.HTMLAttributeName)[0]) {
              document.getElementsByName(element.HTMLAttributeName)[0].value =
                element.value;
            }
          }
        }
      });
    }
    //slab remove if not include slab
    // if(this.props.rfqSettingValues.includeSlab !=nextProps.rfqSettingValues.includeSlab){
    if (
      this.props.rfqSettingValues.includeSlab !=
        nextProps.rfqSettingValues.includeSlab &&
      this.props.location.pathname.indexOf("/prcombination") == -1
    ) {
      if (!nextProps.rfqSettingValues.includeSlab) {
        this.props.selectedItems.forEach((item) => {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [item.id]: {
                ...prevState.mainTemplateQuotation[item.id],
                slab: [
                  { id: 1, start: 1, end: "", error: false },
                  { id: 2, start: "More Than", end: "", error: false },
                ],
              },
            },
          }));
          this.setState((prevState) => ({
            ...prevState,
            slab: {
              ...prevState.slab,
              [item.id]: {
                ...prevState.slab[item.id],
                isOpen: false,
              },
            },
          }));
        });
      } else {
        this.props.selectedItems.forEach((item) => {
          this.setState((prevState) => ({
            ...prevState,
            slab: {
              ...prevState.slab,
              [item.id]: {
                ...prevState.slab[item.id],
                isOpen: true,
              },
            },
          }));
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the quotationInitializeFlag has changed
    if (
      this.state.quotationInitializeFlag !== prevState.quotationInitializeFlag
    ) {
      // If it has changed, conditionally initialize the state
      if (this.state.quotationInitializeFlag) {
        this.initializeState();
        // this.initializeSolutionNames();
      }
    }
    if (
      Object.keys(this.props.quotationExcelData).length > 0 &&
      !this.state.quotationImported
    ) {
      // set currecny
      if (
        this.props?.vendorType === "International" ||
        this.props?.vendorType === "Local Importer"
      ) {
        let currencyElement = this.state.allItemsTemplate.find((item) => {
          return item.Identifier === "Currency";
        });
        if (
          this.state.selectedCurrency !=
          this.props.quotationExcelData.result[
            currencyElement?.HTMLAttributeName
          ]
        ) {
          this.setState({
            selectedCurrency:
              this.props.quotationExcelData.result[
                currencyElement.HTMLAttributeName
              ] == "Select"
                ? ""
                : this.props.quotationExcelData.result[
                    currencyElement.HTMLAttributeName
                  ],
          });
        }
      }

      let mainTemplateQuotation = _.cloneDeep(this.state.mainTemplateQuotation);
      let updated = false;
      let updatedAdditionalInfo = false;
      if (
        this.state.industryType !=
        this.props.quotationExcelData.result.industry_type
      ) {
        this.setState({
          industryType: this.props.quotationExcelData.result.industry_type,
        });
      }
      if (
        this.state.msmeNumber !=
        this.props.quotationExcelData.result.industry_type_value
      ) {
        this.setState({
          msmeNumber: this.props.quotationExcelData.result.industry_type_value,
        });
      }

      for (const key in mainTemplateQuotation) {
        const currentItem = mainTemplateQuotation[key];

        for (const key2 in currentItem) {
          if (
            this.props.quotationExcelData.result.hasOwnProperty(
              currentItem[key2]["HTMLAttributeName"]
            )
          ) {
            const newValue =
              this.props.quotationExcelData.result[
                currentItem[key2]["HTMLAttributeName"]
              ];

            if (currentItem[key2]["value"] !== newValue) {
              if (currentItem[key2]["ElementType"] === "S") {
                if (
                  currentItem[key2]?.options?.length > 0 &&
                  currentItem[key2]?.options[0]?.dropdown_element_name
                ) {
                  let filteredOption = currentItem[key2]?.options.find(
                    (option) => {
                      return (
                        `${option.dropdown_element_code} - ${option.dropdown_element_name}` ==
                          this.props.quotationExcelData.result[
                            currentItem[key2]["HTMLAttributeName"]
                          ] ||
                        `${option.dropdown_element_code}-${option.dropdown_element_name}` ==
                          this.props.quotationExcelData.result[
                            currentItem[key2]["HTMLAttributeName"]
                          ] ||
                        (option.dropdown_element_code ==
                          option.dropdown_element_name &&
                          option.dropdown_element_name ==
                            this.props.quotationExcelData.result[
                              currentItem[key2]["HTMLAttributeName"]
                            ])
                      );
                    }
                  );
                  if (filteredOption) {
                    currentItem[key2]["value"] =
                      this.props.companyInfo?.default_dropdown_type === "10"
                        ? `${filteredOption.dropdown_element_name}-${filteredOption.dropdown_element_code}`
                        : `${filteredOption.dropdown_element_code}-${filteredOption.dropdown_element_name}`;
                    updatedAdditionalInfo = true;
                  }
                } else {
                  const filteredOption = currentItem[key2]?.options.find(
                    (option) => {
                      return (
                        option.name ==
                        this.props.quotationExcelData.result[
                          currentItem[key2]["HTMLAttributeName"]
                        ]
                      );
                    }
                  );
                  if (filteredOption) {
                    currentItem[key2]["value"] = filteredOption.code;
                    updatedAdditionalInfo = true;
                  }
                }
                // currentItem[key2]["value"] = newValue == "Select" ? "" : newValue;
              } else {
                currentItem[key2]["value"] =
                  !isNaN(newValue) && newValue != ""
                    ? parseFloat(newValue)
                    : newValue;
              }
              if (
                currentItem[key2]["Identifier"] === "Budgetedrate" ||
                currentItem[key2]["Identifier"] === "CIFRate" ||
                currentItem[key2]["Identifier"] === "rate" ||
                currentItem[key2]["Identifier"] === "Rate"
              ) {
                currentItem["rate"] = newValue;
              }
              updated = true;
            }
          } else if (
            this.props.quotationExcelData.result.hasOwnProperty(
              `datepicker${currentItem[key2]["HTMLAttributeName"]}`
            )
          ) {
            const newValue =
              this.props.quotationExcelData.result[
                `datepicker${currentItem[key2]["HTMLAttributeName"]}`
              ] != ""
                ? moment(
                    this.props.quotationExcelData.result[
                      `datepicker${currentItem[key2]["HTMLAttributeName"]}`
                    ],
                    "DD/MM/YYYY"
                  ).format("YYYY-MM-DD")
                : "";

            // console.log("newValue", newValue, currentItem);
            if (currentItem[key2]["value"] !== newValue) {
              currentItem[key2]["value"] = newValue;
              updated = true;
            }
          }
        }
      }

      let additionalInfoTemplate = _.cloneDeep(
        this.state.additionalInfoTemplate
      );

      Object.entries(additionalInfoTemplate).forEach(([key, currentField]) => {
        if (
          this.props.quotationExcelData.result[
            currentField["HTMLAttributeName"]
          ]
        ) {
          if (currentField["ElementType"] == "DT") {
            const newValueAdditional =
              this.props.quotationExcelData.result[
                currentField["HTMLAttributeName"]
              ];

            currentField["value"] = newValueAdditional
              .split("/")
              .reverse()
              .join("-");
            updatedAdditionalInfo = true;
          } else if (currentField["ElementType"] == "S") {
            if (
              currentField?.options?.length > 0 &&
              currentField?.options[0]?.dropdown_element_name
            ) {
              let filteredOption = currentField?.options.find((option) => {
                return (
                  `${option.dropdown_element_code} - ${option.dropdown_element_name}` ==
                    this.props.quotationExcelData.result[
                      currentField["HTMLAttributeName"]
                    ] ||
                  `${option.dropdown_element_code}-${option.dropdown_element_name}` ==
                    this.props.quotationExcelData.result[
                      currentField["HTMLAttributeName"]
                    ] ||
                  (option.dropdown_element_code ==
                    option.dropdown_element_name &&
                    option.dropdown_element_name ==
                      this.props.quotationExcelData.result[
                        currentField["HTMLAttributeName"]
                      ])
                );
              });
              if (filteredOption) {
                currentField["value"] =
                  this.props.companyInfo?.default_dropdown_type === "10"
                    ? `${filteredOption.dropdown_element_name}-${filteredOption.dropdown_element_code}`
                    : `${filteredOption.dropdown_element_code}-${filteredOption.dropdown_element_name}`;
                updatedAdditionalInfo = true;
              }
            } else {
              let addionalSvalue =
                this.props.quotationExcelData.result[
                  currentField["HTMLAttributeName"]
                ];
              if (
                currentField?.options?.length > 0 &&
                currentField?.options[0].hasOwnProperty["name"]
              ) {
                let dropdownName = currentField?.options.filter((option) => {
                  return option.name === addionalSvalue;
                });
                if (dropdownName.length > 0) {
                  addionalSvalue = dropdownName[0]?.code;
                }
              }
              if (currentField["value"] !== addionalSvalue) {
                currentField["value"] =
                  addionalSvalue === "Select" ? "" : addionalSvalue;
                updatedAdditionalInfo = true;
              }
            }
          } else {
            const newValueAdditional =
              this.props.quotationExcelData.result[
                currentField["HTMLAttributeName"]
              ];
            if (currentField["value"] !== newValueAdditional) {
              currentField["value"] =
                newValueAdditional === "Select" ? "" : newValueAdditional;
              updatedAdditionalInfo = true;
            }
          }
        }
      });
      if (updated) {
        this.setState({
          mainTemplateQuotation,
          quotationImported: true,
        });
      }
      if (updatedAdditionalInfo) {
        this.setState({
          additionalInfoTemplate,
          quotationImported: true,
        });
      }
    }
    if (this.state.mainTemplateQuotation || this.state.additionalInfoTemplate) {
      let EvalResults = {};

      for (const key in this.state.mainTemplateQuotation) {
        this.state.rearrangedArray.forEach((defaultIdentifier) => {
          let formula = defaultIdentifier.DefaultValue.Formula;
          let newFormula = this.arrangedEvalByKey(
            formula,
            key,
            EvalResults[key],
            defaultIdentifier.Identifier
          );

          if (
            newFormula &&
            newFormula.includes("ExchangeRate_SelectedCurrency")
          ) {
            let exchangeRate = this.props.currencyOptions.find(
              (currency) =>
                currency.Currency ===
                (this.state.selectedCurrency || this.props.quotedCurrency)
            );
            if (exchangeRate) {
              newFormula = newFormula.replace(
                /ExchangeRate_SelectedCurrency/g,
                exchangeRate.ExchangeRate
              );
            }
          }

          if (
            newFormula &&
            newFormula.includes("ExchangeRate_BuyerLocalCurrency")
          ) {
            let exchangeRate = this.props.currencyOptions.find(
              (currency) =>
                currency.Currency === (this.props.currencyDetails || "INR")
            );
            if (exchangeRate) {
              newFormula = newFormula.replace(
                /ExchangeRate_BuyerLocalCurrency/g,
                exchangeRate.ExchangeRate
              );
            }
          }

          // Now set the property
          if (newFormula && newFormula.includes("(f")) {
            const match = newFormula.match(/f\d+/);
            if (match) {
              newFormula = newFormula.replace(match[0], 0);
            }
          }
          if (
            newFormula && newFormula.includes('ExchangeRate_')
          ) {
            newFormula = this.evalMasterExchangeRate(newFormula);
          }
          if (!EvalResults[key]) {
            EvalResults[key] = {};
          }

          try {
            const evaluatedValue = new Function(`return ${newFormula}`)();
            EvalResults[key][defaultIdentifier.FieldId] =
              parseFloat(evaluatedValue).toFixed(3);
          } catch (error) {
            console.error("Error evaluating formula:", newFormula, error);
            EvalResults[key][defaultIdentifier.FieldId] = "NaN";
          }
        });
      }

      // Only update the state if EvalResults have changed
      if (
        JSON.stringify(this.state.EvalResults) !== JSON.stringify(EvalResults)
      ) {
        this.setState({ EvalResults });
      }
    }
    if (
      this.state.slabMainTemplateQuotation ||
      this.state.additionalInfoTemplate
    ) {
      let EvalResultsSlab = {};

      for (const key in this.state.slabMainTemplateQuotation) {
        for (const index in this.state.slabMainTemplateQuotation[key]) {
          if (index != "selected") {
            this.state.rearrangedArray.forEach((defaultIdentifier) => {
              let formula = defaultIdentifier.DefaultValue.Formula;
              let newFormula = this.arrangedEvalByKeySlab(
                formula,
                key,
                EvalResultsSlab?.[key]?.[index],
                defaultIdentifier.Identifier,
                index
              );
              if (
                newFormula &&
                newFormula.includes("ExchangeRate_SelectedCurrency")
              ) {
                let exchangeRate = this.props.currencyOptions.find(
                  (currency) =>
                    currency.Currency ===
                    (this.state.selectedCurrency || this.props.quotedCurrency)
                );
                if (exchangeRate) {
                  newFormula = newFormula.replace(
                    /ExchangeRate_SelectedCurrency/g,
                    exchangeRate.ExchangeRate
                  );
                }
              }

              if (
                newFormula &&
                newFormula.includes("ExchangeRate_BuyerLocalCurrency")
              ) {
                let exchangeRate = this.props.currencyOptions.find(
                  (currency) =>
                    currency.Currency === (this.props.currencyDetails || "INR")
                );
                if (exchangeRate) {
                  newFormula = newFormula.replace(
                    /ExchangeRate_BuyerLocalCurrency/g,
                    exchangeRate.ExchangeRate
                  );
                }
              }

              // Now set the property
              if (newFormula && newFormula.includes("(f")) {
                const match = newFormula.match(/f\d+/);
                if (match) {
                  newFormula = newFormula.replace(match[0], 0);
                }
              }
              if (
                newFormula && newFormula.includes("ExchangeRate_")
              ) {
                newFormula = this.evalMasterExchangeRate(newFormula);
              }

              if (!EvalResultsSlab[key]) {
                EvalResultsSlab[key] = {};
              }
              if (!EvalResultsSlab[key][index]) {
                EvalResultsSlab[key][index] = {};
              }

              try {
                const evaluatedValue = new Function(`return ${newFormula}`)();
                EvalResultsSlab[key][index][defaultIdentifier.FieldId] =
                  parseFloat(evaluatedValue).toFixed(3);
              } catch (error) {
                console.error("Error evaluating formula:", newFormula, error);
                EvalResultsSlab[key][defaultIdentifier.FieldId] = "NaN";
              }
            });
          }
        }
      }

      // Only update the state if EvalResultsSlab have changed
      if (
        JSON.stringify(this.state.EvalResultsSlab) !==
        JSON.stringify(EvalResultsSlab)
      ) {
        this.setState({ EvalResultsSlab });
      }
    }
  }

  toggleLoaderArray = (arg) => {
    let temp = this.state.loaderArray;
    let index = temp.indexOf(arg);
    if (index > -1) {
      temp.splice(index, 1);
    } else {
      temp.push(arg);
    }
    this.setState({
      loaderArray: temp,
    });
  };

  SUM = (field, item) => {
    let sum = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      sum += Number(expression_val);
    });
    return sum;
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //let replacedStr= pritems[pritems.length-1][prconfig[field].response_key].replace(/\^/,"\n");
    return pritems[pritems?.length - 1][prconfig[field].response_key];
  };

  COMMA = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ",";
    });
    return str.slice(0, -1);
  };

  COMMA_WITH_SPACE = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = "";
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}
      str += expression_val + ", ";
    });
    return str.slice(0, -2);
  };

  EstimatedCost = (field, item) => {
    let value = item.estimated_cost;
    if (
      this.props.featurePermission
        ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master?.feature_value === "N"
    ) {
      value = this.LATEST(field, item);
    }
    return value;
  };

  MIN = (field, item) => {
    let value = 0;
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  MIN_DATE = (field, item) => {
    let value = 0;

    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      let expression_val = 0;
      try {
        expression_val = eval(
          "prlineitem" + "." + prconfig[field].response_key
        );
        if (!value || moment(value).isAfter(moment(expression_val))) {
          value = expression_val;
        }
      } catch (e) {}

      if (expression_val < value) {
        value = expression_val;
      }
    });

    return value;
  };

  LATEST_Remove_Circumflex = (field, item) => {
    let value = this.LATEST(field, item);
    return value ? value.replace(/\^\^\^/g, "\n") : "";
  };

  PRLongText = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    return (
      this.LATEST_Remove_Circumflex(field, item) ||
      pritems[pritems.length - 1]["material_short_text"] ||
      ""
    );
  };

  DeliverySchedule = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    let pr_delivery_schedule = "";
    pritems.forEach(function (item, index) {
      let quantity = item.quantity;
      let delivery_date = item.delivery_date;
      let date_arr = delivery_date.split("-");
      date_arr = date_arr.reverse();
      //delivery_date = delivery_date.replace(/-/g, "\/");
      delivery_date = date_arr.join("-");
      let line_item = item.pr_line_item;
      let pr_number = item.pr.number;
      pr_delivery_schedule +=
        delivery_date +
        "," +
        quantity +
        "," +
        pr_number +
        "(" +
        line_item +
        ");";
    });
    pr_delivery_schedule = pr_delivery_schedule.slice(0, -1);
    return pr_delivery_schedule;
  };

  PRNoLineItem = (field, item) => {
    let str = "";
    this.props.prlineitems[item.id].forEach((prlineitem, i) => {
      str += prlineitem.pr.number + "(" + prlineitem.pr_line_item + "), ";
    });
    return str.slice(0, -2);
  };

  ReqDeliveryDate = (field, item) => {};

  LATEST = (field, item) => {
    let pritems = this.props.prlineitems[item.id];
    //return pritems[pritems?.length-1][prconfig[field].response_key] || '';
    let pr_line_item_object = pritems[pritems.length - 1];
    let expression = "pr_line_item_object" + "." + prconfig[field].response_key;
    let result = "";
    try {
      result = eval(expression);
    } catch (e) {}

    return result;
  };

  LATEST_PO_DATE = (field, item) => {
    let value = "";
    value = this.LATEST(field, item);
    if (value) {
      let pattern = /\b(\d{4})(\d{2})(\d{2})\b/;
      let result = value.match(pattern);
      if (result) {
        result.shift();
        value = result.join("-");
      }
    }
    return value;
  };

  QTY_Check = (qty) => {
    let $moq_permission =
      this.props.accountModulePermission[
        "Allow more Quantity in PR Qty Distribution"
      ]?.module_activated;
    let $service_rfq_permission =
      this.props.accountModulePermission["Service PR"]?.module_activated;
    let readOnly = true;
    if ($moq_permission === "Y" || $service_rfq_permission === "Y") {
      readOnly = false;
    }
    return readOnly;
  };

  COMPANY_CODE_Check = (company_code) => {
    return company_code ? true : false;
  };

  initializeState = async () => {
    let initialSlab = {};
    let finalState = {};
    if (this.props.quotationJsonTemplate?.ITEMIZE?.length) {
      for (
        let index = 0;
        index < Object.keys(this.props.selectedQuotedItems.items).length;
        index++
      ) {
        let obj = {};
        let templateIndexes;
        for (
          let i = 0;
          i < this?.props?.quotationJsonTemplate.ITEMIZE.length;
          i++
        ) {
          if (
            this?.props?.quotationJsonTemplate?.ITEMIZE[i][1]?.Child
              ?.AttributeName ==
            this.props.items[this.props.transactionItemSequence[index]]
              ?.attributeName
          ) {
            templateIndexes = i;
            break;
          }
        }
        this.state.usedAttributesId.push(
          this.props.quotationJsonTemplate?.ITEMIZE[index][1]?.Child
            ?.AttributeId
        );
        this.setState({
          usedAttributesId: this.state.usedAttributesId,
        });
        for (
          let fieldIndex = 0;
          fieldIndex < this.props.quotationJsonTemplate?.ITEMIZE[index].length;
          fieldIndex++
        ) {
          const row =
            this.props.quotationJsonTemplate?.ITEMIZE[templateIndexes][
              fieldIndex
            ];
          if (row.VisibilityFlag === "Y") {
            if (
              row.Identifier === "Product" ||
              row.Identifier === "ProductForeign"
            ) {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.keys(this.props.selectedQuotedItems.items)[index],
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
                CurrencyFormula: row.CurrencyFormula,
                UnitsFlag: row.UnitsFlag,
                InstructionText: row.InstructionText,
              };
              continue;
            }
            if (row.Identifier === "ProductCode") {
              obj[row.FieldId] = {
                HTMLAttributeName: row.HTMLAttributeName,
                value: Object.values(this.props.selectedQuotedItems.items)[
                  index
                ].code,
                Validation: "",
                FieldLabel: row.FieldLabel,
                error: false,
                Identifier: row.Identifier,
                ErrorMessage: "",
                DefaultFormula: row.DefaultFormula,
                DefaultValue: row.DefaultValue,
                CurrencyFormula: row.CurrencyFormula,
                UnitsFlag: row.UnitsFlag,
                InstructionText: row.InstructionText,
              };
              continue;
            }

            let value = "";
            if (row.Identifier === "MasterSpecification") {
              value =
                this.props.selectedQuotedItems.items[index]?.specifications;
            } else if (row.Identifier === "MasterHSNCode") {
              value =
                this.props.selectedQuotedItems.items[index]?.hsn_code || "";
            }

            let readOnly = false;
            if (
              Object.keys(this.props.prlineitems).length &&
              prconfig &&
              prconfig[row.Identifier] !== undefined
            ) {
              value =
                eval(this[prconfig[row.Identifier].function_name])(
                  row.Identifier,
                  this.props.items.selectedQuotedItems[index]
                ) || "";
              if (
                prconfig[row.Identifier]?.non_editable_function &&
                prconfig[row.Identifier]?.non_editable_function !== "0"
              ) {
                if (prconfig[row.Identifier]?.non_editable_function == "1") {
                  readOnly = true;
                } else {
                  readOnly = eval(
                    this[prconfig[row.Identifier]?.non_editable_function]
                  )(value);
                }
              }
            }
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: value,
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              ElementType: row.ElementType,
              readOnly: readOnly,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
              CurrencyFormula: row.CurrencyFormula,
              UnitsFlag: row.UnitsFlag,
              InstructionText: row.InstructionText,
            };
            if (
              row.Identifier === "EstimatedCost" ||
              row.Identifier === "EstimatedCostForeign" ||
              row.Identifier === "Budgetedrate" ||
              row.Identifier === "BudgetedrateForeign"
            ) {
              obj[row.FieldId][
                `upd_budget_master_${this.props.selectedQuotedItems.items[index].id}`
              ] = false;

              if (
                this.props.featurePermission
                  ?.Fetch_Budget_Value_in_RFQ_Form_from_Item_Master
                  ?.feature_value === "Y" &&
                this.props.selectedQuotedItems.items[index]?.budgeted_rate
              ) {
                if (
                  parseFloat(
                    this.props.selectedQuotedItems.items[index]?.budgeted_rate
                  )
                ) {
                  obj[row.FieldId]["value"] =
                    this.props.selectedQuotedItems.items[index].budgeted_rate;
                  obj["rate"] =
                    this.props.selectedQuotedItems.items[index].budgeted_rate;
                } else {
                  obj["rate"] = "";
                }
              } else {
                obj["rate"] = value;
              }
            }

            if (row.ElementType === "S") {
              if (row.DropdownEdit === "Y") {
                obj[row.FieldId]["options"] = [];
                //getting options data from redux if it is available else calling api
                let optionsData = this.getOptionsFromRedux(row, "itemize");
                if (optionsData) {
                  obj[row.FieldId]["options"] = optionsData;
                  this.setState({
                    commonOptions: {
                      ...this.state.commonOptions,
                      [row.Identifier]: optionsData,
                    },
                  });
                } else {
                  obj[row.FieldId]["options"] = await this.getItemizeOptions(
                    row,
                    index
                  );
                }
              } else {
                obj[row.FieldId]["options"] = row.DefaultValue;
              }
            }
            if (
              row.Identifier === "BasicHistoricalPrice" ||
              row.Identifier === "LandedHistoricalPrice" ||
              row.Identifier === "DateHP"
            ) {
              obj[row.FieldId]["DynamicDefaultValue"] = "loading";
              this.getDynamicDefaultValues(row, index, row.DefaultValue);
            }
          }
          if (row.Identifier === "EstimatedValue") {
            obj[row.FieldId] = {
              HTMLAttributeName: row.HTMLAttributeName,
              value: "",
              Validation: row.Validation,
              FieldLabel: row.FieldLabel,
              error: false,
              ErrorMessage: row.ErrorMessage,
              Identifier: row.Identifier,
              DefaultFormula: row.DefaultFormula,
              DefaultValue: row.DefaultValue,
              CurrencyFormula: row.CurrencyFormula,
              UnitsFlag: row.UnitsFlag,
              InstructionText: row.InstructionText,
            };
          }
          if (row.Identifier === "ProductQuantity" && obj[row.FieldId])
            obj.qty = obj[row.FieldId].value;
        }
        obj.specification = "";
        const itemize = this.props.lastTransactionData.itemize;
        const key = this.props.transactionItemSequence[index]; // Get the key at the specified index
        obj.added_after_rfq_created =
          this.props?.items[key]?.added_after_rfq_created;
        obj.is_deleted_from_master =
          this.props?.items[key]?.is_deleted_from_master;
        obj.is_unlink_from_category =
          this.props?.items[key]?.is_unlink_from_category;
        obj.selected =
          this.props?.items[key]?.is_deleted_from_master ||
          this.props?.items[key]?.is_unlink_from_category
            ? "N"
            : itemize[key]?.selected;
        for (
          let i = 0;
          i < this?.props?.quotationJsonTemplate.ITEMIZE.length;
          i++
        ) {
          if (
            this?.props?.quotationJsonTemplate?.ITEMIZE[i][1]?.Child
              ?.AttributeName == this.props.items[key]?.attributeName
          ) {
            obj.formdetailRowId =
              this?.props?.quotationJsonTemplate?.ITEMIZE[
                i
              ][1]?.Child?.AttributeId;
            break;
          }
        }

        if (!obj.hasOwnProperty("qty")) {
          obj.qty = "";
        }
        if (!obj.hasOwnProperty("slab")) {
          obj["slab"] = [
            { id: 1, start: "1", end: "", error: false },
            { id: 2, start: "More Than", end: "", error: false },
          ];
        }
        // finalState[Object.keys(this.props.selectedQuotedItems.items)[index]] = obj;
        finalState[this.props.transactionItemSequence[index]] = obj;
        if (this.props.rfqSettingValues.includeSlab) {
          initialSlab[this.props.transactionItemSequence[index]] = {
            isOpen: true,
          };
        } else {
          initialSlab[this.props.transactionItemSequence[index]] = {
            isOpen: false,
          };
        }
      }
      this.setState(
        {
          mainTemplateQuotation: finalState,
          slab: initialSlab,
        },
        () => {
          if (
            this.props.featurePermission?.prev_tran_value?.feature_value ===
              "Y" &&
            this.props.prplants.length === 0
          ) {
            this.fetchPrevious();
          } else {
            this.fetchPrevious();
          }
        }
      );
    }
  };
  fetchPrevTemplate = async () => {
    let templateURL = "";
    if (process.env.NODE_ENV === "production") {
      templateURL = templateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.selectedQuotedItems.template.id,
        this.props.selectedQuotedItems.template.batch_id,
        1
      );
    } else {
      templateURL = devTemplateURi(
        this.props.userInfo.division_id,
        this.props.companyInfo.display_name,
        this.props.selectedQuotedItems.template.id,
        this.props.selectedQuotedItems.template.batch_id,
        1
      );
    }
    await axios
      .get(templateURL)
      .then((response) => {
        let FieldIDIdentifierMapping = this.convertToFieldIdIdentifierPairs(
          response.data
        );
        this.setState(
          {
            fieldIdentifierMapping: FieldIDIdentifierMapping,
          },
          () => {
            this.initializeState();
          }
        );
      })
      .catch((err) => {
        console.log(err, "main json err");
      });
  };

  convertToFieldIdIdentifierPairs = (jsonData) => {
    const fieldIdIdentifierPairs = {};

    // Loop through FOREIGN section
    if (jsonData.FOREIGN) {
      jsonData.FOREIGN.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Consider only the first array inside ITEMIZE section if present
    if (jsonData.ITEMIZE && jsonData.ITEMIZE.length > 0) {
      jsonData.ITEMIZE[0].forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through LOCAL section if present
    if (jsonData.LOCAL) {
      jsonData.LOCAL.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    // Loop through COMMON section if present
    if (jsonData.COMMON) {
      jsonData.COMMON.forEach((obj) => {
        fieldIdIdentifierPairs[obj.Identifier] = obj.FieldId;
      });
    }

    return fieldIdIdentifierPairs;
  };

  initializeLocalRecipientsState = () => {
    let row = {};
    const combinedTemplate = this.props.quotationJsonTemplate?.COMMON.concat(
      this.props.quotationJsonTemplate?.LOCAL
    );
    combinedTemplate.forEach((cell) => {
      if (cell.Identifier == "VendorType") {
        return;
      }
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
        VisibilityFlag: cell.VisibilityFlag,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = "";
      }
    });
    return row;
  };

  initializeIntlRecipientsState = () => {
    let row = {};

    const combinedTemplate = this.props.quotationJsonTemplate?.COMMON.concat(
      this.props.quotationJsonTemplate?.FOREIGN
    );

    combinedTemplate.forEach((cell) => {
      if (cell.Identifier == "VendorType") {
        return;
      }
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        Identifier: cell.Identifier,
        ElementType: cell.ElementType,
        DefaultFormula: cell.DefaultFormula,
        DefaultValue: cell.DefaultValue,
        VisibilityFlag: cell.VisibilityFlag,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          //getting options data from redux if it is available else calling api
          let optionsData = this.getOptionsFromRedux(cell, "additionalInfo");
          if (optionsData) {
            row[cell.FieldId]["options"] = optionsData;
          } else {
            this.getOptionsFromApi(cell, "additionalInfo");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue || "";
        }
      }
    });
    this.setState({
      additionalInfoTemplate: row,
    });
    return row;
  };

  getItemizeOptions = async (cell, index) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    if (index !== 0) {
      return this.state.commonOptions[cell.Identifier];
    }
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.newSessionID}`;
    const url = getDropdownOptions(
      cell.Identifier,
      this.props.quotationJsonTemplate?.PARAM[0]?.template_id
    );
    this.toggleLoaderArray(
      index ? `${cell.Identifier}_${index}` : cell.Identifier
    );
    try {
      // setIsLoading(true);
      const res = await axios.get(url);
      this.toggleLoaderArray(
        index ? `${cell.Identifier}_${index}` : cell.Identifier
      );
      if (res.data.dropdown_mapping_status !== false) {
        this.setState({
          commonOptions: {
            ...this.state.commonOptions,
            [cell.Identifier]: res.data.data,
          },
        });
        //setting itemize data into redux
        this.setOptionsInRedux(
          templateId,
          cell.Identifier,
          res.data.data,
          "itemize"
        );
        return res.data.data;
      } else {
        this.setState({
          commonOptions: {
            ...this.state.commonOptions,
            [cell.Identifier]: cell.DefaultValue,
          },
        });
        this.setOptionsInRedux(
          templateId,
          cell.Identifier,
          cell.DefaultValue,
          "itemize"
        );
        return cell.DefaultValue;
      }
    } catch (err) {
      console.log(err, "options error");
      this.toggleLoaderArray(
        index ? `${cell.Identifier}_${index}` : cell.Identifier
      );
      return [];
    }
  };

  //setting options data into redux

  getOptionsFromApi = async (cell, template) => {
    try {
      let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.sessionId}`;
      const url = getDropdownOptions(
        cell.Identifier,
        this.props.quotationJsonTemplate?.PARAM[0]?.template_id
      );
      this.toggleLoaderArray(cell.Identifier);
      const res = await axios.get(url);
      if (template === "additionalInfo") {
        if (res.data.dropdown_mapping_status !== false) {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data?.data,
              },
            },
          }));
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            res.data.data,
            template
          );
        } else {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: cell.DefaultValue,
              },
            },
          }));
        }
      }
      this.toggleLoaderArray(cell.Identifier);
    } catch (error) {
      console.log(error, "options error");
      if (template === "additionalInfo") {
        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [cell.FieldId]: {
              ...prevState.additionalInfoTemplate[cell.FieldId],
              options: [],
            },
          },
        }));
      }
      this.toggleLoaderArray(cell.Identifier);
    }
  };

  handleUpdateQuotation = (key, newRow) => {
    this.setState((prevState) => ({
      multiSolmainTemplateQuotation: {
        ...prevState.multiSolmainTemplateQuotation,
        [key]: newRow,
      },
    }));
  };

  setSolutionNames = (solName) => {
    this.setState({ solutionNames: solName });
  };

  setSolutionNamesErrors = (solName) => {
    this.setState({ solutionNameErrors: solName });
  };

  updateAdditionalInfoFromCurrencyOptions = (cell, template) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = "http://192.168.1.161:8888/v1/dropdowns-currency-list";
    this.toggleLoaderArray(cell.Identifier);

    axios
      .get(url)
      .then((res) => {
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          // Assuming the response is an array of currency objects
          const options = res.data.map((currency) => ({
            value: currency.CurrencyID,
            label: currency.Currency,
          }));

          // Update state with the options
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: res.data,
              },
            },
          }));

          // Assuming setOptionsInRedux function is defined
          this.setOptionsInRedux(
            templateId,
            cell.Identifier,
            options,
            template
          );
        }
        this.toggleLoaderArray(cell.Identifier);
      })
      .catch((err) => {
        console.log(err, "options error");
        if (cell.Identifier === "Currency" && template === "additionalInfo") {
          this.setState((prevState) => ({
            ...prevState,
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [cell.FieldId]: {
                ...prevState.additionalInfoTemplate[cell.FieldId],
                options: [],
              },
            },
          }));
        }
        this.toggleLoaderArray(cell.Identifier);
      });
  };

  setOptionsInRedux = (templateId, name, data, template) => {
    let tempObject = {
      data,
      template,
    };
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (Object.keys(updateTemplateValues).some((val) => val == templateId)) {
        if (
          !Object.keys(updateTemplateValues[templateId]).some(
            (item) => item == name
          )
        ) {
          let newTemplateValues = {
            ...updateTemplateValues[templateId],
            [name]: tempObject,
          };
          this.props.setTemplateValues({ [templateId]: newTemplateValues });
        }
      } else {
        this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
      }
    } else {
      this.props.setTemplateValues({ [templateId]: { [name]: tempObject } });
    }
  };

  //getting options data from redux
  getOptionsFromRedux = (cell, template) => {
    let templateId = this.props.quotationJsonTemplate?.PARAM[0]?.template_id;
    let updateTemplateValues = this.props.templateValues;
    if (Object.keys(updateTemplateValues).length !== 0) {
      if (updateTemplateValues[templateId]) {
        let cellData = updateTemplateValues[templateId][cell.Identifier];
        if (cellData && cellData.template == template) return cellData.data;
      }
    }
  };

  getDynamicDefaultValues = (cell, index, DefaultValue) => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.sessionId}`;
    const url = getIdentifiersDynamicValues(
      cell.Identifier,
      this.props.selectedItems[index].id,
      this.props.selectedCategory.id
    );
    this.toggleLoaderArray(
      `${cell.Identifier}_getDynamicDefaultValues_${index}`
    );
    axios
      .get(url)
      .then((res) => {
        if (res.data) {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
            },
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [this.props.selectedItems[index]?.id]: {
                ...prevState.mainTemplateQuotation[
                  this.props.selectedItems[index]?.id
                ],
                [cell.FieldId]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ][cell.FieldId],
                  DynamicDefaultValue: DefaultValue,
                },
              },
            },
          }));
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      })
      .catch((err) => {
        console.log(err, "getDynamicDefaultValues");
        if (this.props.selectedItems) {
          if (this.props.selectedItems[index]) {
            this.setState((prevState) => ({
              ...prevState,
              mainTemplateQuotation: {
                ...prevState.mainTemplateQuotation,
                [this.props.selectedItems[index]?.id]: {
                  ...prevState.mainTemplateQuotation[
                    this.props.selectedItems[index]?.id
                  ],
                  [cell.FieldId]: {
                    ...prevState.mainTemplateQuotation[
                      this.props.selectedItems[index]?.id
                    ][cell.FieldId],
                    options: [],
                  },
                },
              },
            }));
          }
        }

        this.toggleLoaderArray(
          `${cell.Identifier}_getDynamicDefaultValues_${index}`
        );
      });
  };

  checkIsLinkLocal = (cell) => {
    //first check if the vendor master check is present or not
    if (
      this.props.rfqSettingValues?.vendorMasterPaymentTerm &&
      this.props.rfqSettingValues?.vendorMasterIncoTerm
    ) {
      // check from both
      let str = this.props.companyInfo?.inco_term_identifier;
      let str1 = this.props.companyInfo?.payment_term_identifier;
      if (str) {
        let arr = str.split(";");
        if (arr.includes(cell.Identifier)) {
          return "inco";
        }
      }
      if (str1) {
        let arr1 = str1.split(";");
        if (arr1.includes(cell.Identifier)) {
          return "payment";
        }
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterPaymentTerm) {
      // paymentterm array
      if (!this.props.companyInfo?.payment_term_identifier) return false;
      let str = this.props.companyInfo?.payment_term_identifier;
      let arr = str.split(";");
      // let arr = ["PaymentType", "PaymentTypeForeign"];
      if (arr.includes(cell.Identifier)) {
        return "payment";
      }
      return false;
    } else if (this.props.rfqSettingValues?.vendorMasterIncoTerm) {
      // incoterm array
      if (!this.props.companyInfo?.inco_term_identifier) return false;
      let str = this.props.companyInfo?.inco_term_identifier;
      let arr = str.split(";");
      // let arr = ["DeliveryBasis", "DeliveryRemarks"];
      if (arr.includes(cell.Identifier)) {
        return "inco";
      }
      return false;
    }
    return false;
  };

  download = () => {
    this.setState({
      isLoading: true,
    });
    let destination = this.getDestinationValue();
    const dest = destination?.split("-");
    const code = Object.values(this.props.items).map((item) => item.code);
    if (destination == "") {
      this.setState({
        isLoading: false,
      });

      return false;
    }
    axios
      .post(
        downloadmaterialDescription,
        {
          buyerID: this.props.userInfo.division_id,
          pr_dest_code: dest[dest.length - 1],
          items: code,
        },
        {
          responseType: "blob", // Move responseType option inside the configuration object
        }
      )
      .then((response) => {
        this.setState({
          isLoading: false,
        });
        const href = window.webkitURL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "Material Description.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
      });
  };

  getFieldLocal = (cell) => {
    let error =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["error"];
    let ErrorMessage =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["ErrorMessage"];
    let name =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["HTMLAttributeName"];
    let value =
      this.state.additionalInfoTemplate[cell?.FieldId] &&
      this.state.additionalInfoTemplate[cell?.FieldId]["value"];
    let isLink = this.checkIsLinkLocal(cell);
    if (isLink) {
      let index1 = this.state.errorArray.indexOf(name);
      if (index1 !== -1) {
        this.state.errorArray.splice(index1, 1);
        this.setState({ errorArray: this.state.errorArray });
      }
      return (
        <React.Fragment>
          <Button
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              border: "1x solid",
              borderColor: (theme) => theme.palette.bluegrey[500],
              padding: "16.5px 14px",
              color: "text.primary",
              fontWeight: 400,
              fontSize:
                "calc(14px - (14 - 16) * ((100vw - 300px) / (1920 - 300)))",
              lineHeight: 1,
              textTransform: "capitalize",
            }}
            onClick={() => {
              this.handleLocalVendorTermsOpen(isLink, cell.FieldId);
            }}
            endIcon={
              <OpenInNew
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            }
          >
            {cell.FieldLabel}
          </Button>
        </React.Fragment>
      );
    } else if (cell?.ElementType === "T" && !Array.isArray(value)) {
      const renderTextField = (value, error, ErrorMessage, name) => (
        <TextField
          key={this.state.prevRFQLoading}
          fullWidth
          value={value || ""}
          className="local_template_textfield"
          variant="outlined"
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          multiline
          maxRows={3}
          type="text"
          error={error}
          helperText={error && ErrorMessage}
          name={name}
        />
      );

      if (
        this.props.multiplePlantsFlag &&
        (cell.Identifier === "Destination" ||
          cell.Identifier === "DestinationForeign")
      ) {
        if (value && !value.includes("###") && !this.props.multiplePlantsFlag) {
          return renderTextField(value, error, ErrorMessage, name);
        }
      } else {
        if (value) {
          return renderTextField(value, error, ErrorMessage, name);
        } else {
          return <Typography>{cell.FieldLabel}</Typography>;
        }
      }

      // Do not render anything if the FieldLabel is "Destination" and value contains "###"
      return null;
    } else if (cell?.ElementType === "TB") {
      if (cell?.CurrencyFormula && /^\(?f\d+\)?$/.test(cell?.CurrencyFormula)) {
        const currency_name = this.getMasterCurrencyByFieldId(cell.CurrencyFormula);
        return (
          <TextField
            key={this.state.prevRFQLoading}
            className="local_template_textfield"
            fullWidth
            variant="outlined"
            label={
              cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")
            }
            type="text"
            error={error}
            helperText={error && ErrorMessage}
            name={name}
            value={value ? value : ""}
            onChange={(e) => {
              this.handleLocalChange(e, cell.FieldId);
              this.validateInputAdditionalInfo(cell.FieldId);
            }}
            InputProps={
              {
                endAdornment: (
                  <InputAdornment position="start">
                    {getSymbol(currency_name)}
                  </InputAdornment>
                ),
              }
            }
          />
        );
      }
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textfield"
          fullWidth
          variant="outlined"
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          type="text"
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          value={value ? value : ""}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
            this.validateInputAdditionalInfo(cell.FieldId);
          }}
          InputProps={
            (cell.Identifier === "FreightChargesForeign" ||
              cell.Identifier === "FreightChargesLocal" ||
              cell.Identifier === "LoadingCharges" ||
              cell.Identifier === "UpoadingCharges" ||
              (cell.CurrencyFlag === "1" && cell.CurrencyFormula != "") ||
              cell.Identifier === "TwoPointDelivery" ||
              cell.Identifier === "DetentionCharges") && {
              endAdornment: (
                <InputAdornment position="start">
                  {getSymbol(
                    this.props?.lastTransactionTypeInThread === "Inquiry" &&
                      (this.props?.vendorType === "International" ||
                        this.props.vendorType === "Local Importer")
                      ? this.state.selectedCurrency
                      : this.props.quotedCurrency
                  )}
                </InputAdornment>
              ),
            }
          }
        />
      );
    } else if (cell?.ElementType === "TA") {
      return (
        <TextField
          key={this.state.prevRFQLoading}
          className="local_template_textarea"
          fullWidth
          multiline
          variant="outlined"
          maxRows={3}
          label={
            cell.FieldLabel +
            (cell.InstructionText ? ` (${cell.InstructionText})` : "")
          }
          error={error}
          helperText={error && ErrorMessage}
          name={name}
          value={value ? value : ""}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
            this.validateInputAdditionalInfo(cell.FieldId);
          }}
        />
      );
    } else if (cell?.ElementType === "M") {
      return (
        <Checkbox
          color="primary"
          checked={!!value}
          name={name}
          onChange={(e) => {
            this.handleLocalChange(e, cell.FieldId);
          }}
        />
      );
    } else if (cell?.ElementType === "DT") {
      return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="dd/MM/yyyy"
            sx={{ width: "100%" }}
            slotProps={{
              textField: {
                size: "fullWidth",
                error: error,
                helperText: error && (cell.ErrorMessage || "Invalid Date"),
              },
            }}
            label={
              cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")
            }
            name={name}
            value={value ? new Date(value) : null}
            onChange={(newVal) => {
              this.handleDateChange(
                moment(newVal),
                cell.FieldId,
                "additionalInfoTemplate"
              );
            }}
          />
        </LocalizationProvider>
      );
    } else if (cell?.ElementType === "F") {
      return (
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.setState({
                  currentFieldId: cell.FieldId,
                  currentTemplate: "additionalInfoTemplate",
                });
                if (
                  value !== null &&
                  value !== undefined &&
                  value !== "" &&
                  value.length > 0 &&
                  value[0]?.id
                ) {
                  this.getExistingFilesForAttachment(value);
                } else {
                  this.setState({
                    dynamicFileUploadOpen: true,
                  });
                }
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              Attachment
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {value?.length ? value?.length : 0}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (
      (this.props.PermissionInquiry?.is_payment_term === "Y" &&
        this.props.companyInfo.payment_term_identifier
          .split(";")
          .includes(cell.Identifier)) ||
      (this.props.PermissionInquiry?.is_inco_term === "Y" &&
        this.props.companyInfo.inco_term_identifier
          .split(";")
          .includes(cell.Identifier))
    ) {
      if (
        this.state.additionalInfoTemplate[cell.FieldId] &&
        this.state.additionalInfoTemplate[cell.FieldId]["options"] &&
        this.state.additionalInfoTemplate[cell.FieldId]["options"] !=
          "loading" &&
        this.state.additionalInfoTemplate[cell.FieldId]["options"].length > 0
      ) {
        return (
          <TextField
            key={this.state.prevRFQLoading}
            fullWidth
            value={value || ""}
            className="textfield"
            variant="outlined"
            label={
              cell.FieldLabel +
              (cell.InstructionText ? ` (${cell.InstructionText})` : "")
            }
            multiline
            maxRows={3}
            type="text"
            error={error}
            helperText={error && ErrorMessage}
            name={name}
          />
        );
      }
    } else if (cell?.ElementType === "S") {
      if (
        (this.props.multiplePlantsFlag && cell.Identifier === "GSTType") ||
        cell.Identifier === "VendorType"
      ) {
        // GSTType will not shown in common in case of multiple plants
        return null;
      }
      if (typeof cell?.Identifier === "string" && cell?.Identifier.includes("CurrencyMaster")) {
        return null;
      }

      return (
        <FormControl fullWidth variant="outlined" error={error}>
          <InputLabel>{cell.FieldLabel}</InputLabel>
          <Tooltip title={value} disableInteractive placement="top-start">
            <Select
              className="template_dropdown"
              label={
                cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")
              }
              name={name}
              attr-id={cell.FieldId}
              id="additionalSelect"
              value={value ? value : ""}
              onChange={(e) => {
                this.handleLocalChange(e, cell.FieldId);
              }}
              onBlur={(e) => {
                this.validateInputAdditionalInfo(cell.FieldId);
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    width: 250,
                  },
                },
              }}
            >
              <MenuItem value={""}>Select</MenuItem>
              {this.state.additionalInfoTemplate[cell.FieldId] &&
                this.state.additionalInfoTemplate[cell.FieldId]["options"] &&
                (this.state.additionalInfoTemplate[cell.FieldId]["options"]
                  .length > 0 ? (
                  this.state.additionalInfoTemplate[cell.FieldId]["options"] ===
                  "loading" ? (
                    <MenuItem value="">Loading...</MenuItem>
                  ) : (
                    this.state.additionalInfoTemplate[cell.FieldId][
                      "options"
                    ].map((option, id) => {
                      return (
                        <MenuItem
                          key={id}
                          value={
                            option.dropdown_element_name
                              ? this.props.companyInfo
                                  ?.default_dropdown_type === "10" ||
                                cell.Identifier === "Destination" ||
                                cell.Identifier === "DestinationForeign" ||
                                cell.Identifier === "Currency"
                                ? `${option.dropdown_element_name}-${option.dropdown_element_code}`
                                : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                              : option.code
                          }
                        >
                          <Typography style={{ whiteSpace: "pre-line" }}>
                            {option.dropdown_element_name
                              ? option.dropdown_element_code ===
                                option.dropdown_element_name
                                ? option.dropdown_element_name
                                : `${option.dropdown_element_code}-${option.dropdown_element_name}`
                              : option.name}
                          </Typography>
                        </MenuItem>
                      );
                    })
                  )
                ) : (
                  <MenuItem value="">Not found</MenuItem>
                ))}
            </Select>
          </Tooltip>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else if (cell?.ElementType === "T" && Array.isArray(value)) {
      return (
        <FormControl error={error} sx={{ width: "100%" }}>
          <Box
            sx={{
              border: "solid 1px",
              borderColor: (theme) => theme.palette.bluegrey[500],
              borderRadius: "4px",
              minWidth: "100%",
              height: "56px",
              display: "flex",
              alignItems: "center",
              padding: "0 10px",
              position: "relative",
            }}
          >
            <InputLabel
              sx={{
                color: "text.secondary",
                backgroundColor: (theme) => theme.palette.grey[0],
                position: "absolute",
                left: 0,
                top: 0,
                paddingLeft: "5px",
                paddingRight: "5px",
                transformOrigin: "top left",
                webkitTransform: " translate(14px, -9px) scale(0.75)",
                transform: "translate(14px, -9px) scale(0.75)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "calc(133% - 24px)",
                zIndex: 1,
              }}
            >
              {cell.FieldLabel +
                (cell.InstructionText ? ` (${cell.InstructionText})` : "")}
            </InputLabel>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                this.handleFileSpecificationsOpen(value, cell.FieldLabel);
              }}
            >
              <UploadIcon sx={{ marginRight: "10px" }} />
              View Attachment
            </Button>
            <FormHelperText sx={{ ml: "auto" }}>
              Number Of Files {value?.length ? value?.length : 0}
            </FormHelperText>
          </Box>
          <Typography variant="caption">
            {this.state.additionalInfoTemplate[cell.HTMLAttributeName]}
          </Typography>
          {error && <FormHelperText>{ErrorMessage}</FormHelperText>}
        </FormControl>
      );
    } else {
      return cell?.FieldLabel;
    }
  };

  finddependacy = (FieldId, validation) => {
    let field_ids = validation?.match(/f(\d)*/g);
    let is_dependacy = 0;
    for (const field_id of field_ids) {
      if ("f" + FieldId != field_id) {
        is_dependacy = 1;
        break;
      }
    }
    return is_dependacy;
  };

  findMSdependacy = (FieldId, validation) => {
    let field_ids = validation?.match(/f(\d)*/g);
    let is_dependacy = 0;
    if (field_ids) {
      for (const field_id of field_ids) {
        if ("f" + FieldId != field_id) {
          is_dependacy = 1;
          break;
        }
      }
    }
    return is_dependacy;
  };

  validateInputRFQ = (FieldId, submitting) => {
    if (submitting) {
      if (
        this.state.RFQDynamic[FieldId]["error"] &&
        !this.finddependacy(
          FieldId,
          this.state.RFQDynamic[FieldId]["Validation"]
        )
      )
        return;
    }
    if (this.state.RFQDynamic[FieldId]?.["Validation"]) {
      let temp = this.state.RFQDynamic[FieldId]["Validation"].replace(
        /f(\d)*/g,
        (match) => {
          if (this.state.RFQDynamic[match.replace("f", "")]) {
            return `this.state.RFQDynamic[${match.replace("f", "")}]["value"]`;
          } else if (
            this.state.additionalInfoTemplate[match.replace("f", "")]
          ) {
            return `this.state.additionalInfoTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else {
            return null;
          }
        }
      );
      if (temp !== null) {
        if (this.state.RFQDynamic[FieldId]["ElementType"] == "DT") {
          this.state.RFQDynamic[FieldId]["value"] = moment(
            this.state.RFQDynamic[FieldId]["value"]
          ).format("YYYY-MM-DD");
        }
        if (eval(temp)) {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: false,
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            RFQDynamic: {
              ...prevState.RFQDynamic,
              [FieldId]: {
                ...prevState.RFQDynamic[FieldId],
                error: true,
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              this.state.RFQDynamic[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputLocal = (FieldId, submitting) => {
    //function name has to be change
    if (submitting) {
      if (this.state.additionalInfoTemplate[FieldId]["error"]) {
        if (
          !this.state.errorArray.includes(
            this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.additionalInfoTemplate[FieldId]["HTMLAttributeName"]
          );
        }
        return;
      }
    }
  };

  validateAdditionalInfo = (FieldId, index, submitting) => {
    const { vendorType } = this.props;
    const { additionalInfoTemplate, errorArray } = this.state;
    const fieldTemplate = additionalInfoTemplate[FieldId];
    const fieldLabel = fieldTemplate?.FieldLabel;

    // if (vendorType === "Local" && fieldLabel === "Currency") {
    if (fieldLabel === "Currency") {
      return null; // Skip further processing for "Currency"
    }

    if (
      submitting &&
      fieldTemplate?.error &&
      !this.finddependacy(FieldId, fieldTemplate.Validation)
    ) {
      return;
    }
    const validation = fieldTemplate?.Validation;
    if (validation) {
      const temp = validation.replace(/f(\d)+/g, (match) => {
        const id = match.replace("f", "");
        const dependentField = additionalInfoTemplate[id];
        return dependentField
          ? dependentField.value === null
            ? '""'
            : `this.state.additionalInfoTemplate[${id}].value`
          : "";
      });

      if (temp !== null) {
        if (fieldTemplate.ElementType === "DT") {
          fieldTemplate.value =
            fieldTemplate.value && fieldTemplate.value != "Invalid Date"
              ? moment(fieldTemplate.value).format("YYYY-MM-DD")
              : "";
        }
        const isValid = eval(temp);
        const updatedFieldTemplate = {
          ...fieldTemplate,
          error: !isValid,
        };

        const updatedErrorArray = isValid
          ? errorArray.filter(
              (attrName) => attrName !== fieldTemplate.HTMLAttributeName
            )
          : [...new Set([...errorArray, fieldTemplate.HTMLAttributeName])];

        this.setState((prevState) => ({
          ...prevState,
          additionalInfoTemplate: {
            ...prevState.additionalInfoTemplate,
            [FieldId]: updatedFieldTemplate,
          },
          errorArray: updatedErrorArray,
        }));
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  initializeRFQDynamicState = () => {
    let row = {};
    let cellsToUpdate = [];
    this.props.quotationJsonTemplate?.COMMON?.forEach((cell) => {
      row[cell.FieldId] = {
        HTMLAttributeName: cell.HTMLAttributeName,
        value: "",
        Validation: cell.Validation,
        FieldLabel: cell.FieldLabel,
        Identifier: cell.Identifier,
        error: false,
        ErrorMessage: cell.ErrorMessage,
        ElementType: cell.ElementType,
        InstructionText: cell.InstructionText,
      };
      if (cell.ElementType === "S") {
        if (cell.DropdownEdit === "Y") {
          row[cell.FieldId]["options"] = "loading";
          cellsToUpdate.push(cell);
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          //getting options data from redux if it is not available then calling api
          if (!optionsData) {
            this.getOptionsFromApi(cell, "rfq");
          }
        } else {
          row[cell.FieldId]["options"] = cell.DefaultValue;
        }
      } else {
        row[cell.FieldId]["value"] = cell.DefaultValue || "";
      }
      if (cell.ElementType === "T") {
        row[cell.FieldId]["value"] = cell.DefaultValue;
      }

      let readOnly = false;
      if (
        Object.keys(this.props.prlineitems).length &&
        prconfig &&
        prconfig[cell.Identifier] !== undefined
      ) {
        row[cell.FieldId]["value"] =
          eval(this[prconfig[cell.Identifier]?.function_name])(
            cell.Identifier,
            this.props.selectedItems[0]
          ) || "";
        if (cell.ElementType === "S" && cell.DropdownEdit !== "Y") {
          Object.keys(row[cell.FieldId]["options"]).map((key) => {
            const plant_arr =
              row[cell.FieldId]["options"][key]["code"].split("-");
            if (plant_arr[plant_arr.length - 1] == row[cell.FieldId]["value"]) {
              row[cell.FieldId]["value"] =
                row[cell.FieldId]["options"][key]["code"];
            }
          });
        }
        if (
          prconfig[cell.Identifier]?.non_editable_function &&
          prconfig[cell.Identifier]?.non_editable_function !== "0"
        ) {
          if (prconfig[cell.Identifier]?.non_editable_function == "1") {
            readOnly = true;
          } else {
            readOnly = eval(
              this[prconfig[cell.Identifier]?.non_editable_function]
            )(row[cell.FieldId]["value"]);
          }
        }
      }
      row[cell.FieldId]["readOnly"] = readOnly;
      row[cell.FieldId]["DefaultValue"] = cell["DefaultValue"];
    });
    this.setState(
      {
        RFQDynamic: row,
      },
      () => {
        //getting options data from redux if it is available setting rfq data
        cellsToUpdate.map((cell) => {
          let optionsData = this.getOptionsFromRedux(cell, "rfq");
          if (cell.ElementType === "S") {
            if (cell.DropdownEdit === "Y") {
              if (optionsData) {
                let value = this.state.RFQDynamic[cell.FieldId]["value"];
                if (value) {
                  if (optionsData) {
                    optionsData.forEach((option, id) => {
                      if (option.dropdown_element_code === value) {
                        value = `${option.dropdown_element_code}-${option.dropdown_element_name}`;
                        if (
                          this.props.companyInfo?.default_dropdown_type ===
                            "10" ||
                          cell.Identifier === "Destination" ||
                          cell.Identifier === "DestinationForeign"
                        ) {
                          value = `${option.dropdown_element_name}-${option.dropdown_element_code}`;
                        }
                      }
                    });
                    this.setState((prevState) => ({
                      ...prevState,
                      RFQDynamic: {
                        ...prevState.RFQDynamic,
                        [cell.FieldId]: {
                          ...prevState.RFQDynamic[cell.FieldId],
                          options: optionsData,
                          value,
                        },
                      },
                    }));
                  }
                } else {
                  this.setState((prevState) => ({
                    ...prevState,
                    RFQDynamic: {
                      ...prevState.RFQDynamic,
                      [cell.FieldId]: {
                        ...prevState.RFQDynamic[cell.FieldId],
                        options: optionsData,
                      },
                    },
                  }));
                }
              }
            }
          }
        });
      }
    );
  };

  validateInput = (FieldId, index, submitting, item) => {
    const mainTemplateQuotationSet = new Set(
      Object.keys(this.state.mainTemplateQuotation)
    );
    let filteredTransactionItemSequence =
      this.props.transactionItemSequence.filter((item) =>
        mainTemplateQuotationSet.has(item)
      );
    let itemKey = filteredTransactionItemSequence[index];

    const currentItem = this.state.mainTemplateQuotation[itemKey];

    if (currentItem?.selected === "N") {
      Object.keys(currentItem).forEach((field) => {
        if (currentItem[field] && currentItem[field]["HTMLAttributeName"]) {
          const errorIndex = this.state.errorArray.indexOf(
            currentItem[field]["HTMLAttributeName"]
          );
          if (errorIndex !== -1) {
            this.state.errorArray.splice(errorIndex, 1);
          }
        }
      });

      this.setState({
        errorArray: this.state.errorArray,
      });
      // If selected is "N", skip validation
      return;
    }

    if (submitting) {
      if (
        currentItem[FieldId] &&
        currentItem[FieldId]["error"] &&
        currentItem[FieldId]["Validation"] &&
        !this.finddependacy(FieldId, currentItem[FieldId]["Validation"])
      ) {
        if (
          !this.state.errorArray.includes(
            currentItem[FieldId]["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(currentItem[FieldId]["HTMLAttributeName"]);
          this.setState({
            errorArray: this.state.errorArray,
          });
          return;
        }
      }
    }

    if (currentItem[FieldId] && currentItem[FieldId]["Validation"]) {
      let temp = currentItem[FieldId]["Validation"].replace(
        /f(\d)+/g,
        (match) => {
          if (currentItem[match.replace("f", "")]) {
            return `this.state.mainTemplateQuotation[${itemKey}][${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (
            this.state.additionalInfoTemplate[match.replace("f", "")]
          ) {
            return `this.state.additionalInfoTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.IntlTemplate[match.replace("f", "")]) {
            return `this.state.IntlTemplate[${match.replace(
              "f",
              ""
            )}]["value"]`;
          } else if (this.state.EvalResults[itemKey][match.replace("f", "")]) {
            return `this.state.EvalResults[${itemKey}][${match.replace(
              "f",
              ""
            )}]`;
          } else {
            return null;
          }
        }
      );

      if (temp !== null) {
        if (eval(temp)) {
          currentItem[FieldId]["error"] = false;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [itemKey]: {
                ...prevState.mainTemplateQuotation[itemKey],
                [FieldId]: {
                  ...prevState.mainTemplateQuotation[itemKey][FieldId],
                  error: false,
                },
              },
            },
          }));

          let index1 = this.state.errorArray.indexOf(
            currentItem[FieldId]["HTMLAttributeName"]
          );
          if (index1 !== -1) {
            this.state.errorArray.splice(index1, 1);
          }
        } else {
          currentItem[FieldId]["error"] = true;

          this.setState((prevState) => ({
            ...prevState,
            mainTemplateQuotation: {
              ...prevState.mainTemplateQuotation,
              [itemKey]: {
                ...prevState.mainTemplateQuotation[itemKey],
                [FieldId]: {
                  ...prevState.mainTemplateQuotation[itemKey][FieldId],
                  error: true,
                },
              },
            },
          }));

          if (
            !this.state.errorArray.includes(
              currentItem[FieldId]["HTMLAttributeName"]
            )
          ) {
            this.state.errorArray.push(
              currentItem[FieldId]["HTMLAttributeName"]
            );
          }
        }
        this.setState({
          mainTemplateQuotation: this.state.mainTemplateQuotation,
          errorArray: this.state.errorArray,
        });
      } else {
        console.log("dependent field not found in template");
      }
    }
  };

  validateInputAdditionalInfo = (FieldId, index, submitting) => {
    const additionalInfoTemplate = this.state.additionalInfoTemplate;
    const field = additionalInfoTemplate[FieldId];

    if (!field) {
      console.error(
        `Field with FieldId ${FieldId} not found in additionalInfoTemplate.`
      );
      return;
    }

    if (field["Identifier"] === "Currency") {
      // skip validation for currency
      return;
    }

    // Check if the field is required (if it has an asterisk in the FieldLabel)
    const isRequired = true; //fields validation check on onchange for additional info fields
    //  field["FieldLabel"]
    //   ? field["FieldLabel"].includes("*")
    //   : false;

    // Determine if we should validate this field
    const shouldValidate = isRequired || submitting;
    if (shouldValidate) {
      // Evaluate if field has dependencies that are satisfied
      const dependenciesSatisfied = this.findDependency(
        FieldId,
        field["Validation"]
      );
      if (!dependenciesSatisfied) {
        return;
      }

      // Proceed with validation if required or dependencies are satisfied
      if (field["Validation"]) {
        let temp = field["Validation"].replace(/f(\d)+/g, (match) => {
          const matchField = additionalInfoTemplate[match.replace("f", "")];
          return matchField
            ? `additionalInfoTemplate[${match.replace("f", "")}]["value"]`
            : null;
        });

        if (temp !== null) {
          // Log the actual values used in the validation expression
          const evalString = temp.replace(
            /additionalInfoTemplate\[(\d+)\]\["value"\]/g,
            (match, p1) => {
              return JSON.stringify(additionalInfoTemplate[p1]["value"]);
            }
          );

          let evalResult;
          try {
            evalResult = eval(evalString);
          } catch (error) {
            console.error("Error evaluating expression:", evalString, error);
            evalResult = false; // If evaluation fails, consider it as invalid
          }

          // Prepare updated errorArray
          if (evalResult) {
            // Validation passed, remove error
            field["error"] = false;
            let errorIndex = this.state.errorArray.indexOf(
              field["HTMLAttributeName"]
            );
            if (errorIndex !== -1) {
              this.state.errorArray.splice(errorIndex, 1);
            }
          } else {
            // Validation failed, add error
            field["error"] = true;
          }

          // Update state with new error status and errorArray
          this.setState((prevState) => ({
            additionalInfoTemplate: {
              ...prevState.additionalInfoTemplate,
              [FieldId]: {
                ...prevState.additionalInfoTemplate[FieldId],
                error: field["error"],
              },
            },
          }));
        } else {
          console.log("dependent field not found in template");
        }
      }
    }
  };

  validateInputForMultiSol = (FieldId, mainIndex, subIndex, submitting) => {
    const currentItem =
      this.state.multiSolmainTemplateQuotation[mainIndex][subIndex];
    const currentMainTemplateItemKey =
      this.state.mainTemplateQuotation[mainIndex];
    const errorKey = mainIndex;
    // Skip validation if the FieldId corresponds to GSTType
    if (this.state.switchState[mainIndex]) {
      if (currentMainTemplateItemKey.selected !== "N") {
        if (submitting) {
          if (
            currentItem[FieldId] &&
            currentItem[FieldId].error &&
            currentItem[FieldId].Validation &&
            !this.findMSdependacy(FieldId, currentItem[FieldId].Validation)
          ) {
            if (
              !this.state.errorArrayForMS[errorKey]?.[subIndex]?.includes(
                currentItem[FieldId].HTMLAttributeName
              )
            ) {
              this.setState((prevState) => {
                const newErrorArray = { ...prevState.errorArrayForMS };
                if (!newErrorArray[errorKey]) {
                  newErrorArray[errorKey] = {};
                }
                if (!newErrorArray[errorKey][subIndex]) {
                  newErrorArray[errorKey][subIndex] = [];
                }
                newErrorArray[errorKey][subIndex].push(
                  currentItem[FieldId].HTMLAttributeName
                );
                return { errorArrayForMS: newErrorArray };
              });
              return;
            }
          }
        }
        if (currentItem[FieldId] && currentItem[FieldId]["Validation"]) {
          let temp = currentItem[FieldId]["Validation"].replace(
            /f(\d)+/g,
            (match) => {
              const fieldId = match.replace("f", "");
              if (currentItem[fieldId]) {
                return `this.state.multiSolmainTemplateQuotation[${mainIndex}][${subIndex}][${fieldId}]["value"]`;
              } else {
                console.log(`dependent field ${fieldId} not found in template`);
                return null;
              }
            }
          );

          if (temp !== null) {
            if (eval(temp)) {
              currentItem[FieldId]["error"] = false;
              this.setState((prevState) => {
                const newErrorArray = { ...prevState.errorArrayForMS };

                if (newErrorArray[errorKey]?.[subIndex]) {
                  const index = newErrorArray[errorKey][subIndex].indexOf(
                    currentItem[FieldId].HTMLAttributeName
                  );
                  if (index !== -1) {
                    newErrorArray[errorKey][subIndex].splice(index, 1);
                  }
                  // Remove the subIndex if it's empty
                  if (newErrorArray[errorKey][subIndex].length === 0) {
                    delete newErrorArray[errorKey][subIndex];
                  }
                  // Remove the mainIndex if it's empty
                  if (Object.keys(newErrorArray[errorKey]).length === 0) {
                    delete newErrorArray[errorKey];
                  }
                }

                return {
                  multiSolmainTemplateQuotation: {
                    ...prevState.multiSolmainTemplateQuotation,
                    [mainIndex]: {
                      ...prevState.multiSolmainTemplateQuotation[mainIndex],
                      [subIndex]: {
                        ...prevState.multiSolmainTemplateQuotation[mainIndex][
                          subIndex
                        ],
                        [FieldId]: {
                          ...prevState.multiSolmainTemplateQuotation[mainIndex][
                            subIndex
                          ][FieldId],
                          error: false,
                        },
                      },
                    },
                  },
                  errorArrayForMS: newErrorArray,
                };
              });
            } else {
              currentItem[FieldId]["error"] = true;
              this.setState((prevState) => {
                let newErrorArray = { ...prevState.errorArrayForMS };

                if (!newErrorArray[errorKey]) {
                  newErrorArray[errorKey] = {};
                }
                if (!newErrorArray[errorKey][subIndex]) {
                  newErrorArray[errorKey][subIndex] = [];
                }
                if (
                  !newErrorArray[errorKey][subIndex].includes(
                    currentItem[FieldId].HTMLAttributeName
                  )
                ) {
                  newErrorArray[errorKey][subIndex].push(
                    currentItem[FieldId].HTMLAttributeName
                  );
                }

                return {
                  multiSolmainTemplateQuotation: {
                    ...prevState.multiSolmainTemplateQuotation,
                    [mainIndex]: {
                      ...prevState.multiSolmainTemplateQuotation[mainIndex],
                      [subIndex]: {
                        ...prevState.multiSolmainTemplateQuotation[mainIndex][
                          subIndex
                        ],
                        [FieldId]: {
                          ...prevState.multiSolmainTemplateQuotation[mainIndex][
                            subIndex
                          ][FieldId],
                          error: true,
                        },
                      },
                    },
                  },
                  errorArrayForMS: newErrorArray,
                };
              });
            }

            this.setState({
              multiSolmainTemplateQuotation:
                this.state.multiSolmainTemplateQuotation,
            });
          } else {
            console.log("dependent field not found in template");
          }
        }
      }
    }
  };

  validateInputForSlab = (FieldId, mainIndex, subIndex, submitting) => {
    const currentItem =
      this.state.slabMainTemplateQuotation[mainIndex][subIndex];
    const currentItemSelected =
      this.state.slabMainTemplateQuotation[mainIndex].selected;
    const errorKey = mainIndex;

    // Check if select_slab is undefined or "Y", continue with normal flow
    if (
      (currentItem.select_slab === undefined ||
        currentItem.select_slab === "Y") &&
      currentItemSelected !== "N"
    ) {
      if (submitting) {
        if (
          currentItem[FieldId] &&
          currentItem[FieldId].error &&
          currentItem[FieldId].Validation &&
          !this.findMSdependacy(FieldId, currentItem[FieldId].Validation)
        ) {
          if (
            !this.state.errorArrayForSlab[errorKey]?.[subIndex]?.includes(
              currentItem[FieldId].HTMLAttributeName
            )
          ) {
            this.setState((prevState) => {
              const newErrorArray = { ...prevState.errorArrayForSlab };
              if (!newErrorArray[errorKey]) {
                newErrorArray[errorKey] = {};
              }
              if (!newErrorArray[errorKey][subIndex]) {
                newErrorArray[errorKey][subIndex] = [];
              }
              newErrorArray[errorKey][subIndex].push(
                currentItem[FieldId].HTMLAttributeName
              );
              return { errorArrayForSlab: newErrorArray };
            });
            return;
          }
        }
      }

      if (currentItem[FieldId] && currentItem[FieldId]["Validation"]) {
        let temp = currentItem[FieldId]["Validation"].replace(
          /f(\d)+/g,
          (match) => {
            const fieldId = match.replace("f", "");
            if (currentItem[fieldId]) {
              return `this.state.slabMainTemplateQuotation[${mainIndex}][${subIndex}][${fieldId}]["value"]`;
            } else {
              console.log(`dependent field ${fieldId} not found in template`);
              return null;
            }
          }
        );

        if (temp !== null) {
          if (eval(temp)) {
            currentItem[FieldId]["error"] = false;
            this.setState((prevState) => {
              const newErrorArray = { ...prevState.errorArrayForSlab };
              if (newErrorArray[errorKey]?.[subIndex]) {
                const index = newErrorArray[errorKey][subIndex].indexOf(
                  currentItem[FieldId].HTMLAttributeName
                );
                if (index !== -1) {
                  newErrorArray[errorKey][subIndex].splice(index, 1);
                }
                if (newErrorArray[errorKey][subIndex].length === 0) {
                  delete newErrorArray[errorKey][subIndex];
                }
                if (Object.keys(newErrorArray[errorKey]).length === 0) {
                  delete newErrorArray[errorKey];
                }
              }

              return {
                slabMainTemplateQuotation: {
                  ...prevState.slabMainTemplateQuotation,
                  [mainIndex]: {
                    ...prevState.slabMainTemplateQuotation[mainIndex],
                    [subIndex]: {
                      ...prevState.slabMainTemplateQuotation[mainIndex][
                        subIndex
                      ],
                      [FieldId]: {
                        ...prevState.slabMainTemplateQuotation[mainIndex][
                          subIndex
                        ][FieldId],
                        error: false,
                      },
                    },
                  },
                },
                errorArrayForSlab: newErrorArray,
              };
            });
          } else {
            currentItem[FieldId]["error"] = true;
            this.setState((prevState) => {
              const newErrorArray = { ...prevState.errorArrayForSlab };
              if (!newErrorArray[errorKey]) {
                newErrorArray[errorKey] = {};
              }
              if (!newErrorArray[errorKey][subIndex]) {
                newErrorArray[errorKey][subIndex] = [];
              }
              if (
                !newErrorArray[errorKey][subIndex].includes(
                  currentItem[FieldId].HTMLAttributeName
                )
              ) {
                newErrorArray[errorKey][subIndex].push(
                  currentItem[FieldId].HTMLAttributeName
                );
              }

              return {
                slabMainTemplateQuotation: {
                  ...prevState.slabMainTemplateQuotation,
                  [mainIndex]: {
                    ...prevState.slabMainTemplateQuotation[mainIndex],
                    [subIndex]: {
                      ...prevState.slabMainTemplateQuotation[mainIndex][
                        subIndex
                      ],
                      [FieldId]: {
                        ...prevState.slabMainTemplateQuotation[mainIndex][
                          subIndex
                        ][FieldId],
                        error: true,
                      },
                    },
                  },
                },
                errorArrayForSlab: newErrorArray,
              };
            });
          }

          this.setState({
            slabMainTemplateQuotation: this.state.slabMainTemplateQuotation,
          });
        } else {
          console.log("dependent field not found in template");
        }
      }
    }
  };

  // Helper function to find dependencies and check if they are satisfied
  findDependency = (FieldId, validation) => {
    let dependenciesSatisfied = true;
    const matches = validation?.match(/f(\d+)/g);
    if (matches) {
      matches.forEach((match) => {
        const dependentFieldId = match.replace("f", "");
        if (this.state.additionalInfoTemplate[dependentFieldId]) {
          let dependentFieldValue =
            this.state.additionalInfoTemplate[dependentFieldId]["value"];
          if (dependentFieldValue == null) {
            dependenciesSatisfied = false;
          }
        }
      });
    }
    return dependenciesSatisfied;
  };

  validateInputRFQStatic = (FieldId) => {
    if (FieldId === "RFQValidity") {
      if (this.state.RFQStatic.RFQValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQValidity: {
              ...prevState.RFQStatic.RFQValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.RFQValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    if (FieldId === "TBValidity") {
      if (this.state.RFQStatic.TBValidity.value === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: true,
            },
          },
        }));
        if (
          !this.state.errorArray.includes(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          )
        ) {
          this.state.errorArray.push(
            this.state.RFQStatic.TBValidity["HTMLAttributeName"]
          );
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            TBValidity: {
              ...prevState.RFQStatic.TBValidity,
              error: false,
            },
          },
        }));
        let index1 = this.state.errorArray.indexOf(
          this.state.RFQStatic.TBValidity["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
    }
    this.setState({
      errorArray: this.state.errorArray,
    });
  };

  handleLocalChange = (e, FieldId) => {
    const { value, type, checked } = e.target;

    const { additionalInfoTemplate } = this.state;
    additionalInfoTemplate[FieldId]["value"] =
      type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      additionalInfoTemplate,
    });
  };

  setTermValue = (value, vendorType, FieldId) => {
    const { additionalInfoTemplate, IntlTemplate } = this.state;
    if (vendorType === true) {
      additionalInfoTemplate[FieldId]["value"] = "Hello";
      this.setState({
        additionalInfoTemplate,
      });
    } else {
      IntlTemplate[FieldId]["value"] = value;
      this.setState({
        IntlTemplate,
      });
    }
  };

  setTermError = () =>
    this.state.vendorTerms === "inco"
      ? this.setState({ incoTermError: true })
      : this.setState({ paymentTermError: true });

  setAttachmentsValue = (
    value,
    currentTemplate = null,
    currentFieldId = null
  ) => {
    this.setState((prevState) => ({
      ...prevState,
      [this.state.currentTemplate || currentTemplate]: {
        ...prevState[this.state.currentTemplate || currentTemplate],
        [this.state.currentFieldId || currentFieldId]: {
          ...prevState[this.state.currentTemplate || currentTemplate][
            this.state.currentFieldId || currentFieldId
          ],
          value: this.state[this.state.currentTemplate || currentTemplate][
            this.state.currentFieldId || currentFieldId
          ]["value"]
            ? [
                ...this.state[this.state.currentTemplate || currentTemplate][
                  this.state.currentFieldId || currentFieldId
                ]["value"],
                ...value,
              ]
            : value,
        },
      },
    }));
  };
  setAttachmentsValueForItemize = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      [prevState.currentTemplateItem]: {
        ...prevState[prevState.currentTemplateItem],
        [prevState.currentItemId]: {
          ...prevState[prevState.currentTemplateItem][prevState.currentItemId],
          [prevState.currentFieldItemId]: {
            ...prevState[prevState.currentTemplateItem][
              prevState.currentItemId
            ][prevState.currentFieldItemId],
            value: [
              ...(prevState[prevState.currentTemplateItem][
                prevState.currentItemId
              ][prevState.currentFieldItemId].value || []),
              ...value,
            ],
          },
        },
      },
    }));
  };
  /**
   * for slab item level attachment
   */
  // setAttachmentsValueForItemizeAlternativeOption = (value) => {
  //   let templateState = this.state.currentTemplateItem
  //   this.setState((prevState) => {
  //     const updatedQuotations = { ...prevState[templateState] };
  //     // Preserve the selected key
  //     const selected = prevState[templateState][this.state.currentItemId].selected;

  //     // Update the specific field in the selected row
  //     updatedQuotations[this.state.currentItemId] = updatedQuotations[this.state.currentItemId].map((item, idx) => {
  //       if (idx === this.state.currentItemIndex) {
  //         const currentValue = item[this.state.currentFieldItemId].value || [];
  //         const newValue = Array.isArray(value) ? value : [value]; // Ensure `value` is an array

  //         return {
  //           ...item,
  //           [this.state.currentFieldItemId]: {
  //             ...item[this.state.currentFieldItemId],
  //             value: [...currentValue, ...newValue], // Concatenate into a single array

  //           },
  //         };
  //       }
  //       return item;
  //     });

  //     // Restore the selected key
  //     updatedQuotations[this.state.currentItemId].selected = selected;
  //     return {
  //       [templateState] : updatedQuotations,
  //     };
  //   });

  // }

  setQuotationImport = (imported) => {
    this.setState({ quotationImported: imported });
  };
  handleIntlChange = (e, FieldId) => {
    const { value, type, checked } = e.target;
    const { IntlTemplate } = this.state;
    IntlTemplate[FieldId]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      IntlTemplate,
    });
  };
  //added val for autocomplete multiplant
  handleRFQDynamicChange = (e, FieldId, val) => {
    const { RFQDynamic } = this.state;
    if (val) {
      RFQDynamic[FieldId]["value"] = val;
    } else {
      const { value, type, checked } = e.target;
      RFQDynamic[FieldId]["value"] = type !== "checkbox" ? value : checked;
    }
    if (RFQDynamic[FieldId]["Identifier"] === "LDDApplicable") {
      for (const common_field_id in RFQDynamic) {
        if (RFQDynamic[common_field_id]["Identifier"] === "LateDeliveryC") {
          RFQDynamic[common_field_id]["value"] =
            RFQDynamic[FieldId]["value"] == "Yes"
              ? RFQDynamic[common_field_id]["DefaultValue"]
              : "";
          break;
        }
      }
    }
    // e.target.blur();
    this.setState(
      {
        RFQDynamic,
      },
      () => {
        this.validateInputRFQ(FieldId);
      }
    );
  };

  handleRFQStaticChange = (e) => {
    const { name, value, type, checked } = e.target;

    const { RFQStatic } = this.state;
    RFQStatic[name]["value"] = type !== "checkbox" ? value : checked;
    // e.target.blur();
    this.setState({
      RFQStatic,
    });
    if (name === "RFQCCUsersmails") {
      let flag = false;
      if (value?.trim() === "") {
        this.setState((prevState) => ({
          ...prevState,
          RFQStatic: {
            ...prevState.RFQStatic,
            RFQUpdateCCUsersCheck: {
              ...prevState.RFQStatic.RFQUpdateCCUsersCheck,
              value: false,
            },
          },
        }));
      } else {
        value
          ?.trim()
          .split(",")
          .forEach((email) => {
            if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
              flag = true;
            }
          });
      }

      this.setState((prevState) => ({
        ...prevState,
        RFQStatic: {
          ...prevState.RFQStatic,
          RFQCCUsersmails: {
            ...prevState.RFQStatic.RFQCCUsersmails,
            error: flag,
          },
        },
      }));

      if (flag) {
        if (!this.state.errorArray.includes("RFQCCUsersmails")) {
          this.state.errorArray.push("RFQCCUsersmails");
        }
      } else {
        let index1 = this.state.errorArray.indexOf("RFQCCUsersmails");
        if (index1 !== -1) {
          this.state.errorArray.splice(index1, 1);
        }
      }
      this.setState({
        errorArray: this.state.errorArray,
      });
    }
  };

  handleDateChange = (value, FieldId, state, index, Validation, key) => {
    if (state === "mainTemplateQuotation") {
      if (
        value &&
        this.state.mainTemplateQuotation[key][FieldId]["value"] !== value
      ) {
        this.setState({
          applyToAll: {
            ...this.state.applyToAll,
            [`${FieldId}_applyToAll`]: false,
          },
        });
      }
      this.setState((prevState) => ({
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [key]: {
            ...prevState.mainTemplateQuotation[key],
            [FieldId]: {
              ...prevState.mainTemplateQuotation[key][FieldId],
              // value: value || "",
              // value: moment(value).format("DD/MM/YYYY") || "",
              value: moment(value).isValid()
                ? moment(value).format("YYYY-MM-DD") || ""
                : "",
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value: moment(value).isValid()
              ? moment(value).format("YYYY-MM-DD") || ""
              : "",
            // new Date(value) && new Date(value._d) && value._d != "Invalid Date" ? new Date(value._d) : "",
          },
        },
      }));
    }

    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "mainTemplateQuotation") {
        this.setState((prevState) => ({
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [key]: {
              ...prevState.mainTemplateQuotation[key],
              [FieldId]: {
                ...prevState.mainTemplateQuotation[key][FieldId],
                error: true,
              },
            },
          },
        }));

        if (
          !this.state.errorArray.includes(
            this.state.mainTemplateQuotation[key][FieldId]["HTMLAttributeName"]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.mainTemplateQuotation[key][FieldId][
                "HTMLAttributeName"
              ],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "mainTemplateQuotation") {
        this.setState((prevState) => ({
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [key]: {
              ...prevState.mainTemplateQuotation[key],
              [FieldId]: {
                ...prevState.mainTemplateQuotation[key][FieldId],
                error: false,
              },
            },
          },
        }));

        const index1 = this.state.errorArray.indexOf(
          this.state.mainTemplateQuotation[key][FieldId]["HTMLAttributeName"]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleDateMSChange = (value, FieldId, state, index, Validation, key) => {
    const {
      multiSolmainTemplateQuotation,
      multiSolApplyToAll,
      mainTemplateQuotation,
    } = this.state;

    if (state === "multiSolmainTemplateQuotation") {
      this.setState({
        multiSolApplyToAll: {
          ...multiSolApplyToAll,
          [`${key}_${FieldId}_multiSolApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });

      this.setState((prevState) => {
        const updatedQuotations = {
          ...prevState.multiSolmainTemplateQuotation,
        };
        const selected = prevState.multiSolmainTemplateQuotation[key].selected; // Preserve the selected key

        updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [FieldId]: {
                ...item[FieldId],
                value: moment(value).isValid()
                  ? moment(value).format("YYYY-MM-DD") || ""
                  : "",
              },
            };
          }
          return item;
        });

        // Restore the selected key
        updatedQuotations[key].selected = selected;

        return {
          multiSolmainTemplateQuotation: updatedQuotations,
          ...(index === 0 &&
            multiSolmainTemplateQuotation[key].length > 1 && {
              mainTemplateQuotation: {
                ...prevState.mainTemplateQuotation,
                [key]: {
                  ...prevState.mainTemplateQuotation[key],
                  [FieldId]: {
                    ...prevState.mainTemplateQuotation[key][FieldId],
                    value: moment(value).isValid()
                      ? moment(value).format("YYYY-MM-DD") || ""
                      : "",
                  },
                },
              },
            }),
        };
      });
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value:
              new Date(value) && new Date(value._d) ? new Date(value._d) : "",
          },
        },
      }));
    }

    // Error Handling
    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "multiSolmainTemplateQuotation") {
        this.setState((prevState) => {
          const updatedQuotations = {
            ...prevState.multiSolmainTemplateQuotation,
          };
          const selected =
            prevState.multiSolmainTemplateQuotation[key].selected;

          updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: true,
                },
              };
            }
            return item;
          });

          updatedQuotations[key].selected = selected;

          return {
            multiSolmainTemplateQuotation: updatedQuotations,
          };
        });

        if (
          !this.state.errorArray.includes(
            this.state.multiSolmainTemplateQuotation[key][index][FieldId][
              "HTMLAttributeName"
            ]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.multiSolmainTemplateQuotation[key][index][FieldId][
                "HTMLAttributeName"
              ],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "multiSolmainTemplateQuotation") {
        this.setState((prevState) => {
          const updatedQuotations = {
            ...prevState.multiSolmainTemplateQuotation,
          };
          const selected =
            prevState.multiSolmainTemplateQuotation[key].selected;

          updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: false,
                },
              };
            }
            return item;
          });

          updatedQuotations[key].selected = selected;

          return {
            multiSolmainTemplateQuotation: updatedQuotations,
          };
        });

        const index1 = this.state.errorArray.indexOf(
          this.state.multiSolmainTemplateQuotation[key][index][FieldId][
            "HTMLAttributeName"
          ]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleDateSlabChange = (value, FieldId, state, index, Validation, key) => {
    const { slabMainTemplateQuotation, slabApplyToAll } = this.state;

    if (state === "slabMainTemplateQuotation") {
      this.setState({
        slabApplyToAll: {
          ...slabApplyToAll,
          [`${key}_${FieldId}_slabApplyToAll`]: false, // Set Apply to All to false for the changed field
        },
      });

      this.setState((prevState) => {
        const updatedQuotations = { ...prevState.slabMainTemplateQuotation };

        // Preserve the selected key
        const selected = prevState.slabMainTemplateQuotation[key].selected;

        // Update the specific field in the selected row
        updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [FieldId]: {
                ...item[FieldId],
                value: moment(value).isValid()
                  ? moment(value).format("YYYY-MM-DD") || ""
                  : "",
              },
            };
          }
          return item;
        });

        // Restore the selected key
        updatedQuotations[key].selected = selected;

        return {
          slabMainTemplateQuotation: updatedQuotations,
        };
      });
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value:
              new Date(value) && new Date(value._d) ? new Date(value._d) : "",
          },
        },
      }));
    }

    // Error Handling
    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "slabMainTemplateQuotation") {
        this.setState((prevState) => {
          const updatedQuotations = { ...prevState.slabMainTemplateQuotation };

          // Preserve the selected key
          const selected = prevState.slabMainTemplateQuotation[key].selected;

          updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: true,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedQuotations[key].selected = selected;

          return {
            slabMainTemplateQuotation: updatedQuotations,
          };
        });

        if (
          !this.state.errorArray.includes(
            this.state.slabMainTemplateQuotation[key][index][FieldId][
              "HTMLAttributeName"
            ]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.slabMainTemplateQuotation[key][index][FieldId][
                "HTMLAttributeName"
              ],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "slabMainTemplateQuotation") {
        this.setState((prevState) => {
          const updatedQuotations = { ...prevState.slabMainTemplateQuotation };

          // Preserve the selected key
          const selected = prevState.slabMainTemplateQuotation[key].selected;

          updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
            if (idx === index) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: false,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedQuotations[key].selected = selected;

          return {
            slabMainTemplateQuotation: updatedQuotations,
          };
        });

        const index1 = this.state.errorArray.indexOf(
          this.state.slabMainTemplateQuotation[key][index][FieldId][
            "HTMLAttributeName"
          ]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleDateWithOutSlabChange = (
    value,
    FieldId,
    state,
    index,
    Validation,
    key
  ) => {
    const { slabMainTemplateQuotation } = this.state;

    if (state === "slabMainTemplateQuotation") {
      if (
        value &&
        slabMainTemplateQuotation[key] &&
        slabMainTemplateQuotation[key][0]?._range === undefined
      ) {
        this.setState((prevState) => {
          const updatedQuotations = { ...prevState.slabMainTemplateQuotation };

          // Preserve the selected key
          const selected = prevState.slabMainTemplateQuotation[key].selected;

          updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  value: moment(value).isValid()
                    ? moment(value).format("YYYY-MM-DD") || ""
                    : "",
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedQuotations[key].selected = selected;

          return {
            slabMainTemplateQuotation: updatedQuotations,
          };
        });
      }
    } else {
      this.setState((prevState) => ({
        [state]: {
          ...prevState[state],
          [FieldId]: {
            ...prevState[state][FieldId],
            value:
              new Date(value) && new Date(value._d) ? new Date(value._d) : "",
          },
        },
      }));
    }

    // Error Handling
    if (
      !(Validation?.trim() === "" && value === null) &&
      !(
        moment(value).isValid() &&
        (FieldId.match(/Validity/i)
          ? moment(value).isSame(moment(), "day") ||
            moment(value).isAfter(moment())
          : true)
      )
    ) {
      if (state === "slabMainTemplateQuotation") {
        this.setState((prevState) => {
          const updatedQuotations = { ...prevState.slabMainTemplateQuotation };

          // Preserve the selected key
          const selected = prevState.slabMainTemplateQuotation[key].selected;

          updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: true,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedQuotations[key].selected = selected;

          return {
            slabMainTemplateQuotation: updatedQuotations,
          };
        });

        if (
          !this.state.errorArray.includes(
            this.state.slabMainTemplateQuotation[key][0][FieldId][
              "HTMLAttributeName"
            ]
          )
        ) {
          this.setState((prevState) => ({
            errorArray: [
              ...prevState.errorArray,
              this.state.slabMainTemplateQuotation[key][0][FieldId][
                "HTMLAttributeName"
              ],
            ],
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: true,
            },
          },
        }));
      }
    } else {
      if (state === "slabMainTemplateQuotation") {
        this.setState((prevState) => {
          const updatedQuotations = { ...prevState.slabMainTemplateQuotation };

          // Preserve the selected key
          const selected = prevState.slabMainTemplateQuotation[key].selected;

          updatedQuotations[key] = updatedQuotations[key].map((item, idx) => {
            if (idx === 0) {
              return {
                ...item,
                [FieldId]: {
                  ...item[FieldId],
                  error: false,
                },
              };
            }
            return item;
          });

          // Restore the selected key
          updatedQuotations[key].selected = selected;

          return {
            slabMainTemplateQuotation: updatedQuotations,
          };
        });

        const index1 = this.state.errorArray.indexOf(
          this.state.slabMainTemplateQuotation[key][0][FieldId][
            "HTMLAttributeName"
          ]
        );
        if (index1 !== -1) {
          this.setState((prevState) => ({
            errorArray: prevState.errorArray.filter((_, i) => i !== index1),
          }));
        }
      } else {
        this.setState((prevState) => ({
          [state]: {
            ...prevState[state],
            [FieldId]: {
              ...prevState[state][FieldId],
              error: false,
            },
          },
        }));
      }
    }
  };

  handleLocalVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "additionalInfo",
      vendorRecipintOpen: true,
    });
  };

  handleIntlVendorRecipintsOpen = () => {
    this.setState({
      vendorRecipintType: "foreign",
      vendorRecipintOpen: true,
    });
  };

  handleVendorRecipintsClose = () => {
    this.setState({
      vendorRecipintOpen: false,
    });
  };

  handleLocalVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "additionalInfo",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleIntlVendorTermsOpen = (term, currentFieldID) => {
    this.setState({
      vendorType: "foreign",
      vendorTerms: term,
      vendorTermsOpen: true,
      currentFieldID,
    });
  };

  handleVendorTermsClose = () => {
    this.setState({
      vendorTermsOpen: false,
      currentFieldID: "",
    });
  };

  allItemsHasVendors = () => {
    let result = true;
    this.props.selectedItems.forEach((item) => {
      if (!item.vendor_count?.total_count) {
        result = false;
      }
    });
    return result;
  };
  handleRegret = async () => {
    // convert this to api and then get response to open pop up
    let data = {
      action: "offline_regret_form",
      perform: "open_regret_form",
      vendor_id: this.props.vendorId,
      guid: this.props?.lastTransactionGuidInThread,
    };
    axios
      .post(q_plus_screen, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          // You can add any other headers here if needed
        },
      })
      .then((res) => {})
      .catch((error) => {
        console.log(error, "error");
      });
  };

  handleQuotationSubmit = (event) => {
    let newErr = [];
    let solutionNameErrors = {};
    let errorArrayForMSParentName = {};
    event.preventDefault(); // Prevent default form submission behavior
    if (this.props.multipleSolutionFlag && this.props.slabFlag) {
      this.setState({
        errorArray: [],
        errorArrayForSlab: [],
      });
    }
    if (this.props.multipleSolutionFlag && !this.props.slabFlag) {
      this.setState({
        errorArray: [],
        errorArrayForMS: [],
      });
    }
    if (
      this.state.excelSourceName == "" &&
      isEmpty(this.props.ServiceFileName) &&
      this.props.isServiceRfq
    ) {
      this.props.openSnackbar(
        "error",
        `Please Fill ${
          this.props.AlternativeNames?.service_option || "Activity"
        } Data`
      );
      return false;
    }
    if (this.props.multipleSolutionFlag && !this.props.slabFlag) {
      // Reset previous errors
      this.setState({
        // errorArrayForMSParentName: {}, // Clear previous parent solution name errors
      });

      // Validate solution names
      const solutionNames = this.state.solutionNames;
      const uniqueNamesSet = new Set();

      Object.keys(solutionNames).forEach((key) => {
        const solutionName = solutionNames[key];

        if (
          (solutionName === "" || solutionName.trim() === "") &&
          this.state.switchState[key.split("-")[0]]
        ) {
          if (
            this.state.mainTemplateQuotation[key.split("-")[0]]?.selected ===
            "Y"
          ) {
            solutionNameErrors[key] = `${
              this.props.AlternativeNames?.alternate_options || "Solution"
            } name cannot be empty`;
          }
        } else if (
          solutionName !== "" &&
          uniqueNamesSet.has(solutionName) &&
          this.state.switchState[key.split("-")[0]]
        ) {
          // Solution name is duplicated
          if (
            this.state.mainTemplateQuotation[key.split("-")[0]]?.selected ===
            "Y"
          ) {
            solutionNameErrors[key] = "Solution name must be unique";
          }
        } else {
          // Add the name to the set for uniqueness checking
          uniqueNamesSet.add(solutionName);
        }
      });

      // If there are errors in solution names, update state and display error
      if (Object.keys(solutionNameErrors).length > 0) {
        this.setState({
          solutionNameErrors: solutionNameErrors,
          rfqSubmitLoading: false, // Stop loading indicator
        });
        // return; // Stop the form submission if there are errors
      }

      // Validate errorArrayForMSParentName
      Object.keys(this.state.solutionNameMainTemplate).forEach((key) => {
        if (
          this.state.solutionNameMainTemplate[key] === "" &&
          this.state.mainTemplateQuotation[key]?.selected === "Y" &&
          this.state.switchState[key]
        ) {
          errorArrayForMSParentName[key] = "Solutions name cannot be empty";
        }
      });

      // If there are errors in errorArrayForMSParentName, update state and display error
      if (Object.keys(errorArrayForMSParentName).length > 0) {
        this.setState({
          errorArrayForMSParentName: errorArrayForMSParentName,
        });
      }
    }

    {
      !this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(this.state.mainTemplateQuotation).forEach((item, index) => {
          for (const key in this.state.mainTemplateQuotation[item]) {
            this.validateInput(key, index, true, item);
          }
        });
    }

    Object.keys(this.state.additionalInfoTemplate).forEach((item, index) => {
      if (this.state.additionalInfoTemplate[item]?.VisibilityFlag === "Y") {
        this.validateInputAdditionalInfo(item, index, true);
      }
    });

    Object.keys(this.state.additionalInfoTemplate).forEach((item, index) => {
      if (this.state.additionalInfoTemplate[item]?.VisibilityFlag === "Y") {
        this.validateAdditionalInfo(item, index, true);
      }
    });

    // Validate multiSolmainTemplateQuotation
    {
      this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(this.state.multiSolmainTemplateQuotation).forEach(
          (mainIndex) => {
            this.state.multiSolmainTemplateQuotation[mainIndex].forEach(
              (subItem, subIndex) => {
                for (const key in subItem) {
                  if (
                    this.state.mainTemplateQuotation[mainIndex]?.selected == "Y"
                  ) {
                    this.validateInputForMultiSol(
                      key,
                      mainIndex,
                      subIndex,
                      true
                    );
                  }
                }
              }
            );
          }
        );
    }

    {
      this.props.multipleSolutionFlag &&
        !this.props.slabFlag &&
        Object.keys(this.state.mainTemplateQuotation).forEach((item, index) => {
          for (const key in this.state.mainTemplateQuotation[item]) {
            // if(!this.state.switchState[key]){
            this.validateInput(key, index, true, item);
            // }
          }
        });
    }

    // Validate Slab
    {
      this.props.multipleSolutionFlag &&
        this.props.slabFlag &&
        Object.keys(this.state.slabMainTemplateQuotation).forEach(
          (mainIndex) => {
            this.state.slabMainTemplateQuotation[mainIndex].forEach(
              (subItem, subIndex) => {
                for (const key in subItem) {
                  this.validateInputForSlab(key, mainIndex, subIndex, true);
                }
              }
            );
          }
        );
    }

    {
      this.props.lastTransactionTypeInThread === "Inquiry" &&
        this.props.vendorType === "Local" &&
        this.props.multiplePlantsFlag &&
        this.props.multipleSolutionFlag &&
        this.props.slabFlag &&
        Object.keys(this.state.mainTemplateQuotation).forEach((item, index) => {
          for (const key in this.state.mainTemplateQuotation[item]) {
            // Skip validation for all keys except "GSTType"
            if (
              this.state.mainTemplateQuotation[item][key].Identifier ===
              "GSTType"
            ) {
              this.validateInput(key, index, true, item);
            }
          }
        });
    }

    const { dataFromQuotationDetails } = this.state;
    let errorMessageDate = "";
    let errorMessageNumber = "";
    let errorMessageReference = "";
    let errorMessageAttachment = "";
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);
    if (dataFromQuotationDetails) {
      const {
        offlineQuotationDate,
        offlineRequoteDate,
        offlineRequoteNo,
        offlineQuotationNo,
        reference,
        attachment,
      } = dataFromQuotationDetails;

      // Validation for offlineRequoteNo and offlineQuotationNo is always enabled
      if (!(offlineRequoteNo || offlineQuotationNo)) {
        errorMessageNumber = `Please provide offline ${
          this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote"
        } Number.`;
      }

      // Validation for offlineQuotationDate, offlineRequoteDate, and reference is conditional
      if (supplier !== 1) {
        if (
          !(offlineQuotationDate || offlineRequoteDate) &&
          this.props.featurePermission.offline_quote_requote_date_and_attachment
            ?.feature_value === "Y"
        ) {
          errorMessageDate = "Please provide offline quotation/Requote Date.";
        }
        if (!(offlineRequoteNo || offlineQuotationNo)) {
          errorMessageNumber = `Please provide offline ${
            this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote"
          } Number.`;
        }
        if (
          (!attachment || attachment.length === 0) &&
          this.props.featurePermission.offline_quote_requote_date_and_attachment
            ?.feature_value === "Y"
        ) {
          errorMessageAttachment = "Please provide an attachment.";
        }

        if (!reference) {
          errorMessageReference = "Please provide Offline Reference.";
        }
      }
    }

    // const { industryType } = this.state;
    // if (
    //   !industryType &&
    //   this.props.featurePermission?.industry_type_mandatory?.feature_value ===
    //   "Y"
    // ) {
    //   this.setState({
    //     industryTypeValidator: true,
    //     industryTypeErrorMessage: "Please provide Industry Type.",
    //     error: "Please provide Industry Type.",
    //   });
    // }
    // if (!this.state.msmeNumber) {
    //   this.setState({
    //     msmeValidator: true,
    //   });
    // }

    const { selectedCurrency } = this.state;

    let message = "";
    const allSelectedN = Object.values(this.state.mainTemplateQuotation).every(
      (item) => item.selected === "N"
    );
    if (allSelectedN) {
      message = "Please select atleast one Product Category.";
    }

    const allSelectedNForSlab = Object.values(
      this.state.slabMainTemplateQuotation
    ).every((item) => item.selected === "N");
    if (
      this.props.multipleSolutionFlag &&
      this.props.slabFlag &&
      allSelectedNForSlab
    ) {
      message = "Please select atleast one Product Category.";
    }
    if (
      (this.props.vendorType === "International" ||
        this.props.vendorType === "Local Importer") &&
      this.props.transactionType === "Inquiry" &&
      !selectedCurrency
    ) {
      this.setState({
        selectedCurrencyValidator: true,
        selectedCurrencyErrorMessage: "Please select currency from dropdown.",
        error: "Please select currency from dropdown.",
      });
    }

    this.setState({
      offlineDateError: errorMessageDate,
      offlineFieldError: errorMessageNumber,
      offlineReferenceError: errorMessageReference,
      offlineAttachment: errorMessageAttachment,
      error:
        errorMessageDate ||
        errorMessageNumber ||
        errorMessageReference ||
        errorMessageAttachment ||
        // (!industryType &&
        //   this.props.featurePermission?.industry_type_mandatory?.feature_value ===
        //   "Y"
        //   ? "Please provide Industry Type."
        //   : "") ||
        // (industryType ? "" : "Please provide Industry Type.") ||
        ((this.props.vendorType === "International" ||
          this.props.vendorType === "Local Importer") &&
        this.props.transactionType === "Inquiry" &&
        !selectedCurrency
          ? "Please select currency from dropdown."
          : ""),
    });
    setTimeout(() => {
      if (
        message != "" ||
        errorMessageDate ||
        Object.keys(solutionNameErrors).length > 0 ||
        Object.keys(errorArrayForMSParentName).length > 0 ||
        errorMessageNumber ||
        errorMessageReference ||
        errorMessageAttachment ||
        this.state.errorArray.length ||
        (this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          Object.keys(this.state.errorArrayForMS).length) ||
        (this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          Object.keys(this.state.errorArrayForMSParentName).length) ||
        (this.props.multipleSolutionFlag &&
          this.props.slabFlag &&
          Object.keys(this.state.errorArrayForSlab).length) ||
        Object.keys(this.state.additionalInfoTemplate).some(
          (key) => this.state.additionalInfoTemplate[key].error === true
        ) ||
        newErr.length ||
        // (!industryType &&
        //   this.props.featurePermission?.industry_type_mandatory
        //     ?.feature_value === "Y") ||
        // (industryType === "MSME" && !this.state.msmeNumber) ||
        ((this.props.vendorType === "International" ||
          this.props.vendorType === "Local Importer") &&
          this.props.transactionType === "Inquiry" &&
          !selectedCurrency)
      ) {
        this.setState({
          rfqSubmitLoading: false,
        });
        this.props.openSnackbar(
          "error",
          `There are errors in your form. Please resolve in order to continue.\n${message}`
        );
        return false;
      }

      // Determine the confirmation message based on the status, vendor type, and selected currency
      const status = queryParams.get("supplier") ? "Online" : "Offline";
      let confirmAlertMsg = "Are you sure want to submit this Form?";

      if (
        this.props.vendorType === "International" ||
        this.props.vendorType === "Local Importer"
      ) {
        if (
          (status === "Offline" || status === "Online") &&
          (this.props.vendorType === "International" ||
            this.props.vendorType === "Local Importer") &&
          (this.state.selectedCurrency || this.props.quotedCurrency)
        ) {
          let txnType =
            this?.props?.transactionType === "Inquiry"
              ? "Quotation"
              : "Requote";
          confirmAlertMsg = `An ${
            status === "Offline" ? "Offline" : ""
          } ${txnType} will be submitted with ${
            this.state.selectedCurrency || this.props.quotedCurrency
          }. Are you sure you want to submit this ${
            status === "Offline" ? "Offline" : ""
          } ${txnType}?`;
        }
      }

      this.setState({
        confirmDialogOpen: true,
        confirmAlertTitle: "Submission",
        confirmAlertMsg: confirmAlertMsg,
      });
    }, 500);
  };

  findFormulaValues = (element, itemize) => {
    let hiddenLabelValue = 0;
    let itemFormula = element["value"].Formula;
    for (const key in itemize) {
      let object = itemize[key];
      for (const key1 in object) {
        if (itemFormula.includes(key1)) {
          hiddenLabelValue = hiddenLabelValue + Number(object[key1].value);
        }
      }
    }
    return hiddenLabelValue;
  };

  handleUplaod = (index, res) => {
    const { mainTemplateQuotation } = this.state;
    mainTemplateQuotation[this.props.selectedItems[index].id]["specification"] =
      res;
    this.setState({
      mainTemplateQuotation,
    });
  };

  handleRFQSubmitDialogOpen = (msg) => {
    this.setState({
      RFQSubmitDialogOpen: true,
      rfqSubmitMsg: msg,
    });
  };

  RFQSubmitDialogOnClose = () => {
    if (this.props.location?.pathname.indexOf("/prcombination") === -1) {
      this.props.resetSettingDrawer();
      this.props.resetReminderSettings();
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.handleTBAction(
        "vendxRating",
        null, // itemId
        null, // oldItemId
        0,
        0
      );
    } else {
      this.setState({
        RFQSubmitDialogOpen: false,
      });
      this.props.setPRselectedChange();
    }
  };

  handleApplyToAllClick = (FieldId, textbox = false, Identifier) => {
    // Retrieve keys from mainTemplateQuotation state
    const mainTemplateQuotationKeys = Object.keys(
      this.state.mainTemplateQuotation
    );
    // Create a Set from mainTemplateQuotation keys
    const mainTemplateQuotationSet = new Set(mainTemplateQuotationKeys);

    // Filter transaction item sequence based on mainTemplateQuotationSet
    const filteredTransactionItemSequence =
      this.props.transactionItemSequence.filter((item) =>
        mainTemplateQuotationSet.has(item)
      );

    let firstRowItemId = null;
    let updated = false;

    // Find the first selected item where switchState is false
    filteredTransactionItemSequence.forEach((itemid) => {
      const isSelected =
        this.state.mainTemplateQuotation[itemid].selected === "Y";
      const inquiryItemsIdLen =
        this.props.inquiryItemsId.length > 0
          ? this.props.inquiryItemsId.includes(Number(itemid))
          : true;

      // We need to process only items that are selected and have switchState set to false
      if (
        !updated &&
        isSelected &&
        inquiryItemsIdLen &&
        !this.state.switchState[itemid]
      ) {
        firstRowItemId = itemid;
        updated = true;
      }
    });

    if (!firstRowItemId) {
      console.log("No item found for applyToAll action");
      return;
    }

    // Validate if value is present and no error exists
    if (!this.state.mainTemplateQuotation[firstRowItemId][FieldId]?.value) {
      this.props.openSnackbar("error", "Please enter the value first");
      return;
    }

    if (this.state.mainTemplateQuotation[firstRowItemId][FieldId]?.error) {
      this.props.openSnackbar("error", "Please resolve error first");
      return;
    }

    // Apply the value to all selected items where switchState is false
    filteredTransactionItemSequence.forEach((itemid) => {
      if (
        this.state.mainTemplateQuotation[itemid].selected === "Y" &&
        !this.state.switchState[itemid]
      ) {
        let FieldIndex = this.state.errorArray.indexOf(
          this.state.mainTemplateQuotation[itemid][FieldId]?.HTMLAttributeName
        );

        if (FieldIndex !== -1) {
          this.state.errorArray.splice(FieldIndex, 1);
        }

        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [itemid]: {
              ...prevState.mainTemplateQuotation[itemid],
              [FieldId]: {
                ...prevState.mainTemplateQuotation[itemid][FieldId],
                error: false,
                value:
                  this.state.mainTemplateQuotation[firstRowItemId][FieldId]
                    ?.value,
              },
              ...(Identifier === "ProductQuantity" && {
                qty: this.state.mainTemplateQuotation[firstRowItemId]["qty"],
              }),
              ...(["Budgetedrate", "CIFRate", "rate", "Rate"].includes(
                Identifier
              ) && {
                rate: this.state.mainTemplateQuotation[firstRowItemId]["rate"],
              }),
            },
          },
        }));
      }
    });

    // Toggle applyToAll state
    this.setState((prevState) => ({
      applyToAll: {
        ...prevState.applyToAll,
        [`${FieldId}_applyToAll`]:
          !prevState.applyToAll[`${FieldId}_applyToAll`],
      },
    }));
  };

  handleMSApplyToAllClick = (key, FieldId, textbox = false, Identifier) => {
    // Check if the field value is empty
    if (!this.state.multiSolmainTemplateQuotation[key][0][FieldId]["value"]) {
      this.props.openSnackbar(
        "error",
        "Please enter a value before applying to all rows."
      );
      return;
    }

    // Check if there is an error in the first row's field
    if (this.state.multiSolmainTemplateQuotation[key][0][FieldId]["error"]) {
      this.props.openSnackbar(
        "error",
        "Please resolve the error in the first row."
      );
      return;
    }

    // Extract the value to be applied from the first row
    const firstRowValue =
      this.state.multiSolmainTemplateQuotation[key][0][FieldId]["value"];
    // Apply the value to all rows for the specific key
    this.setState((prevState) => {
      const updatedQuotations = { ...prevState.multiSolmainTemplateQuotation };

      // Only update the specific key
      updatedQuotations[key] = updatedQuotations[key].map((item) => ({
        ...item,
        [FieldId]: {
          ...item[FieldId],
          value: firstRowValue,
          error: false,
        },
        ...(Identifier === "ProductQuantity" ? { qty: firstRowValue } : {}),
        ...(Identifier === "Budgetedrate" ||
        Identifier === "CIFRate" ||
        Identifier === "rate" ||
        Identifier === "Rate"
          ? { rate: firstRowValue }
          : {}),
      }));

      return {
        multiSolmainTemplateQuotation: updatedQuotations,
        multiSolApplyToAll: {
          ...prevState.multiSolApplyToAll,
          [`${key}_${FieldId}_multiSolApplyToAll`]:
            !prevState.multiSolApplyToAll[
              `${key}_${FieldId}_multiSolApplyToAll`
            ],
        },
      };
    });
  };

  handleSlabApplyToAllClick = (key, FieldId, textbox = false, Identifier) => {
    // Find the first row where select_slab is "Y" and get the value from that row
    const firstValidRow = this.state.slabMainTemplateQuotation[key]?.find(
      (row) => row.select_slab === "Y"
    );
    const firstRowValue = firstValidRow[FieldId]?.value;

    // Check if the field value is empty
    if (!firstRowValue) {
      this.props.openSnackbar(
        "error",
        "Please enter a value before applying to all rows."
      );
      return;
    }

    // Check if there is an error in the first row's field
    if (firstValidRow[FieldId]?.error) {
      this.props.openSnackbar(
        "error",
        "Please resolve the error in the first row."
      );
      return;
    }

    // Apply the value to all rows for the specific key where select_slab is "Y"
    this.setState((prevState) => {
      const updatedQuotations = { ...prevState.slabMainTemplateQuotation };

      // Preserve the selected key
      const selected = prevState.slabMainTemplateQuotation[key].selected;

      // Update only the rows where select_slab is "Y"
      updatedQuotations[key] = updatedQuotations[key].map((item) => {
        if (item.select_slab === "Y") {
          return {
            ...item,
            [FieldId]: {
              ...item[FieldId],
              value: firstRowValue,
              error: false,
            },
            ...(Identifier === "ProductQuantity" ? { qty: firstRowValue } : {}),
            ...(Identifier === "Budgetedrate" ||
            Identifier === "CIFRate" ||
            Identifier === "rate" ||
            Identifier === "Rate"
              ? { rate: firstRowValue }
              : {}),
          };
        }
        return item;
      });

      // Restore the selected key
      updatedQuotations[key].selected = selected;
      return {
        slabMainTemplateQuotation: updatedQuotations,
        slabApplyToAll: {
          ...prevState.slabApplyToAll,
          [`${key}_${FieldId}_slabApplyToAll`]:
            !prevState.slabApplyToAll[`${key}_${FieldId}_slabApplyToAll`],
        },
      };
    });
  };

  handleSlabParentApplyToAllClick = (
    key,
    FieldId,
    textbox = false,
    Identifier
  ) => {
    // Get the first row for the specified key
    const firstRow = this.state.slabMainTemplateQuotation[key]?.[0];
    const firstRowValue = firstRow?.[FieldId]?.value;

    // Check if the field value is empty in the first row
    if (!firstRowValue) {
      this.props.openSnackbar(
        "error",
        "Please enter a value before applying to all rows."
      );
      return;
    }

    // Check if there's an error in the first row's field
    if (firstRow?.[FieldId]?.error) {
      this.props.openSnackbar(
        "error",
        "Please resolve the error in the first row."
      );
      return;
    }

    // Get all keys from slabMainTemplateQuotation
    const allKeys = Object.keys(this.state.slabMainTemplateQuotation);

    // Filter rows where _range is undefined
    const rowsWithUndefinedRange = (rows) =>
      rows.filter((row) => row._range === undefined);

    this.setState((prevState) => {
      const updatedQuotations = { ...prevState.slabMainTemplateQuotation };
      const selected = prevState.slabMainTemplateQuotation[key].selected;

      allKeys.forEach((currentKey) => {
        const rows = updatedQuotations[currentKey];
        const filteredRows = rowsWithUndefinedRange(rows);

        filteredRows.forEach((row, rowIndex) => {
          updatedQuotations[currentKey][rowIndex] = {
            ...updatedQuotations[currentKey][rowIndex],
            [FieldId]: {
              ...updatedQuotations[currentKey][rowIndex][FieldId],
              value: firstRowValue,
              error: false,
            },
            // Update specific fields based on Identifier
            ...(Identifier === "ProductQuantity" ? { qty: firstRowValue } : {}),
            ...(Identifier === "Budgetedrate" ||
            Identifier === "CIFRate" ||
            Identifier === "rate" ||
            Identifier === "Rate"
              ? { rate: firstRowValue }
              : {}),
          };
        });
      });

      updatedQuotations[key].selected = selected;

      // Toggle the apply to all flag
      return {
        slabMainTemplateQuotation: updatedQuotations,
        slabParentApplyToAll: {
          ...prevState.slabParentApplyToAll,
          [`${FieldId}_slabParentApplyToAll`]:
            !prevState.slabParentApplyToAll[`${FieldId}_slabParentApplyToAll`],
        },
      };
    });
  };

  handleVendorType = (type) => {
    return type == "additionalInfo"
      ? this.props.selectedVendors?.some(
          (e) =>
            e.country?.toUpperCase() ===
            this.props.accountlevelInfo?.country?.toUpperCase()
        )
      : this.props.selectedVendors?.some(
          (e) =>
            e.country?.toUpperCase() !==
            this.props.accountlevelInfo?.country?.toUpperCase()
        );
  };

  vendorTypeExist = (type) => {
    let result = false;
    this.props.selectedItems.forEach((item) => {
      if (item?.vendor_count?.[type]) {
        result = true;
      }
    });
    return result;
  };

  fetchTermsAndCondtions = () => {
    this.toggleLoaderArray("TermsAndCondtions");
    let newTermsAndConditions = this.props.termsAndConditions;
    let categoryId = this.props.selectedCategory?.id;
    if (
      Object.keys(newTermsAndConditions).length !== 0 &&
      newTermsAndConditions[categoryId]
    ) {
      this.setState({
        termsAndConditionsData: newTermsAndConditions[categoryId],
      });
      this.toggleLoaderArray("TermsAndCondtions");
    } else {
      const url = getTermsAndConditions(categoryId);
      axios
        .get(url)
        .then((res) => {
          this.setState({
            termsAndConditionsData: res.data?.data,
          });
          this.props.setTermsAndConditions({ [categoryId]: res.data?.data });
          this.toggleLoaderArray("TermsAndCondtions");
        })
        .catch((err) => {
          console.log(err, "getfilespecs error");
          this.toggleLoaderArray("TermsAndCondtions");
        });
    }
  };

  getDestinationValue = () => {
    for (const key in this.state.additionalInfoTemplate) {
      const element = this.state.additionalInfoTemplate[key];
      if (
        element.Identifier === "Destination" ||
        element.Identifier === "DestinationForeign"
      ) {
        return element.value;
      }
    }
  };

  handleUpdateInMasterClick = (item_id, FieldId) => {
    this.setState({
      mainTemplateQuotation: {
        ...this.state.mainTemplateQuotation,
        [item_id]: {
          ...this.state.mainTemplateQuotation[item_id],
          [FieldId]: {
            ...this.state.mainTemplateQuotation[item_id][FieldId],
            [`upd_budget_master_${item_id}`]:
              !this.state.mainTemplateQuotation[item_id][FieldId][
                `upd_budget_master_${item_id}`
              ],
          },
        },
      },
    });
  };

  updateDeliveryScheduleFieldValue = (value, item_id, FieldId, RowIndex) => {
    if (typeof RowIndex != "undefined") {
      this.setState((prevState) => ({
        multiSolmainTemplateQuotation: {
          ...prevState.multiSolmainTemplateQuotation,
          [item_id]: prevState.multiSolmainTemplateQuotation[item_id].map(
            (item, index) =>
              index === RowIndex
                ? {
                    ...item,
                    [FieldId]: {
                      ...item[FieldId],
                      value,
                    },
                  }
                : item
          ),
        },
      }));
      if (RowIndex == 0) {
        this.setState((prevState) => ({
          ...prevState,
          mainTemplateQuotation: {
            ...prevState.mainTemplateQuotation,
            [item_id]: {
              ...prevState.mainTemplateQuotation[item_id],
              [FieldId]: {
                ...prevState.mainTemplateQuotation[item_id]?.[FieldId],
                value,
              },
            },
          },
        }));
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        mainTemplateQuotation: {
          ...prevState.mainTemplateQuotation,
          [item_id]: {
            ...prevState.mainTemplateQuotation[item_id],
            [FieldId]: {
              ...prevState.mainTemplateQuotation[item_id]?.[FieldId],
              value,
            },
          },
        },
      }));
    }
  };

  updateDeliveryScheduleFieldValueSlab = (
    value,
    item_id,
    FieldId,
    RowIndex
  ) => {
    if (typeof RowIndex != "undefined") {
      this.setState((prevState) => {
        const updatedQuotation = prevState.slabMainTemplateQuotation[
          item_id
        ].map((item, idx) => {
          if (idx === RowIndex) {
            return {
              ...item,
              [FieldId]: {
                ...item[FieldId],
                value: value,
                // error: value,
              },
            };
          }
          return item;
        });

        // Preserve the selected key
        updatedQuotation.selected =
          prevState.slabMainTemplateQuotation[item_id].selected;

        return {
          ...prevState,
          slabMainTemplateQuotation: {
            ...prevState.slabMainTemplateQuotation,
            [item_id]: updatedQuotation,
          },
        };
      });
    } else {
      this.setState((prevState) => {
        const updatedQuotation = prevState.slabMainTemplateQuotation[
          item_id
        ].map((item, idx) => {
          if (idx === 0) {
            return {
              ...item,
              [FieldId]: {
                ...item[FieldId],
                value: value,
                // error: value,
              },
            };
          }
          return item;
        });

        // Preserve the selected key
        updatedQuotation.selected =
          prevState.slabMainTemplateQuotation[item_id].selected;

        return {
          ...prevState,
          slabMainTemplateQuotation: {
            ...prevState.slabMainTemplateQuotation,
            [item_id]: updatedQuotation,
          },
        };
      });
    }
  };

  // Define sendMail function outside of quotationSubmitAPI
  sendMail = async (payloadData, responseData) => {
    const queryParams = new URLSearchParams(window.location.search);

    const formData = new FormData();

    // Sonali :: Start

    formData.append("ReverseAuction", "n");
    formData.append("userid", this.props.userInfo.user_id); // UserID = Who submit the quote/requote
    formData.append("buyerid", this.props.userInfo.division_id); // Buyer ID
    formData.append("LinkedBusinessGrID", this.props.userInfo.company_id); //Company ID

    formData.append("prevtranguid", this.props.CurrentTransaction.guid); // Guid Column value of TransactionLog table
    formData.append("activity_amend_mail", ""); // if service RFQ than 1 else blank
    formData.append("max_item_version_view", ""); // if service RFQ than 1 else blank
    formData.append("vendor_amended_item_ref", ""); // if service RFQ than 1 else blank
    formData.append("InquiryID", this.props.inquiryId); // inquiry.rowid
    formData.append("inquiry_id", this.props.inquiryId); // inquiry.rowid
    formData.append("VendorID", this.props.vendorId); // VendorID
    formData.append(
      "PrevTranNo",
      this.props.CurrentTransaction.no || this.props.selectedQuotedItems.ref_no
    ); // quote/requote  no or inquiry no
    formData.append(
      "SenderName",
      this.props.selectedQuotedItems.vendors[0].name
    ); // vendor code
    formData.append(
      "SenderCode",
      this.props.selectedQuotedItems.vendors[0].code
    ); // vendor name
    formData.append(
      "productid",
      this.props.selectedQuotedItems.categories[0].id
    ); // Category ID/Product ID

    formData.append(
      "LocationFlag",
      this.props.vendorType == "Local" ? "L" : "I"
    ); // L OR I

    formData.append(
      "local_currency",
      this.props.selectedQuotedItems.vendors[0].quoted_currency ||
        this.props.selectedQuotedItems.currency
    ); // Quotation currency

    if (queryParams.get("supplier")) {
      // vendor / Online
      formData.append("OfflineTransaction", "N"); // Y if online submitted else N
    } else {
      formData.append("OfflineTransaction", "Y"); // Y if online submitted else N
    }
    formData.append("RecepientIdList", 1); // Receiver ID -Vendor id if online quote else buyer id
    formData.append("RecepientName", this.props.accountlevelInfo.company_name); // account name
    formData.append("cdn_path", HOSTMAP); // domain
    formData.append("GroupRfqId", this.props.selectedQuotedItems.group_id); // group rfq no / when rfq generated from PR else blank

    // from submit response
    formData.append("TransactionGuid", responseData.mail_data.TransactionGUID); // latest value TransactionGuid Column value of TransactionLog table
    formData.append("latestTransGUID", responseData.mail_data.latestTransGUID);

    formData.append("NewTranNo", responseData.mail_data.NewTranNo); // quotation/requote ref no
    formData.append("TranTypeGuid", responseData.mail_data.TranTypeGuid); // latest value TransactionLog.TranTypeGUID
    if (responseData.mail_data.pr_flag == true) {
      // true
      formData.append("pr_flag", 1); // if rfq floated from PR than 1 else blank
    } else {
      // false
      formData.append("pr_flag", ""); // if rfq floated from PR than 1 else blank
    }

    formData.append("prevtranid", responseData.mail_data.prevtranid); // Value of TranTypeID  TranType = Inquiry TranStatus = Inquiry
    formData.append("master_type", responseData.mail_data.masterType); // category / product
    // from submit response

    // Payload
    formData.append("newtranstype", payloadData.tran_status); // Quotation|Requote
    if (queryParams.get("supplier")) {
      // vendor / Online
      formData.append("NewTranStatus", payloadData.tran_status); // 1. Offline Quotation 2. Quotation
    } else {
      formData.append("NewTranStatus", `Offline ${payloadData.tran_status}`); // 1. Offline Quotation 2. Quotation
    }

    formData.append("datepicker", payloadData.validity_date); // Quote/Requote - validity date

    // Sonali :: END

    this.setState({
      quotationSubmitLoading: false,
      quotationSubmitSuccess: true, // Reset success state
    });
    try {
      const response = await axios.post(sendMailDataWriter, formData);
    } catch (error) {
      console.error("Error sending mail:", error);
    }
  };
  getSumOfField = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      if (data[key][fieldId]) {
        sum += parseFloat(data[key][fieldId].value);
      }
    }
    return sum;
  };
  arrangedEvalByKey = (formula, key, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.mainTemplateQuotation[key].hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue?.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                ?.value === "string" &&
              this.state.mainTemplateQuotation[key]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.mainTemplateQuotation[key]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfField(
                  this.state.mainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.mainTemplateQuotation[key]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                    ? parseFloat(
                        this.state.mainTemplateQuotation[key]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    : `"${this.state.mainTemplateQuotation[key]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  arrangedEvalByKeySlab = (formula, key, result, identifier, index) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.slabMainTemplateQuotation[key][index].hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue?.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.slabMainTemplateQuotation[key][index]?.[
                formulaIdentifier
              ]?.value === "string" &&
              this.state.slabMainTemplateQuotation[key][index]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.slabMainTemplateQuotation[key][index]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfField(
                  this.state.mainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.slabMainTemplateQuotation[key][
                  index
                ]?.[formulaIdentifier]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.slabMainTemplateQuotation[key][index]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.slabMainTemplateQuotation[key][index]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    ? parseFloat(
                        this.state.slabMainTemplateQuotation[key][index]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    : `"${this.state.slabMainTemplateQuotation[key][index]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  arrangedEval = (formula, key, result, identifier) => {
    if (typeof formula != "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[formulaIdentifier]
              ? !isNaN(parseFloat(result[formulaIdentifier]))
                ? parseFloat(result[formulaIdentifier])
                : `"${result[formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.mainTemplateQuotation[key]?.hasOwnProperty(
              formulaIdentifier
            )
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue?.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue?.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                ?.value === "string" &&
              this.state.mainTemplateQuotation[key]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.mainTemplateQuotation[key]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfField(
                  this.state.mainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.mainTemplateQuotation[key]?.[
                  formulaIdentifier
                ]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.mainTemplateQuotation[key]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.mainTemplateQuotation[key]?.[formulaIdentifier]
                        ?.value
                    )
                    ? parseFloat(
                        this.state.mainTemplateQuotation[key]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    : `"${this.state.mainTemplateQuotation[key]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };
  sumFieldValue = (data, fieldId) =>
    Object.values(data)
      .map((item) => parseFloat(item[fieldId]) || 0)
      .reduce((acc, value) => acc + value, 0);

  prepareQuotationPayload = () => {
    let {
      additionalInfoTemplate,
      mainTemplateQuotation,
      msmainTemplateQuotation,
    } = this.state;
    const { lastTransactionData, quotationJsonTemplate } = this.props;
    if (this.props.itemSpecific) {
      mainTemplateQuotation = {
        ...mainTemplateQuotation,
        ...this.state.notFilteredMainTemplateQuotation,
      };
    }
    let finalRemainingQuotation = {};

    // Prepare itemize object
    let item_id_arr =
      this.props.inquiryItemsId.length > 0
        ? this.props.inquiryItemsId
        : Object.keys(mainTemplateQuotation);
    // item_id_arr.forEach((item_id) => {
    let EvalResultWithItem = {};
    this.props.transactionItemSequence.forEach((item_id) => {
      let arr = [];
      let obj = {};
      let key = item_id;
      let EvalResults = {};
      this.state.rearrangedArray.forEach((defaultIdentifier) => {
        let formula = defaultIdentifier.DefaultValue.Formula;
        let newFormula = this.arrangedEval(
          formula,
          key,
          EvalResults,
          defaultIdentifier.Identifier
        );
        if (
          newFormula &&
          newFormula.includes("ExchangeRate_SelectedCurrency")
        ) {
          let exchangeRate = this.props.currencyOptions.find(
            (currency) =>
              currency.Currency ==
              (this.state.selectedCurrency || this.props.quotedCurrency)
          );
          if (exchangeRate) {
            newFormula = newFormula.replace(
              /ExchangeRate_SelectedCurrency/g,
              exchangeRate.ExchangeRate
            );
          }
        }

        if (
          newFormula &&
          newFormula.includes("ExchangeRate_BuyerLocalCurrency")
        ) {
          let exchangeRate = this.props.currencyOptions.find(
            (currency) =>
              currency.Currency === (this.props.currencyDetails || "INR")
          );
          if (exchangeRate) {
            newFormula = newFormula.replace(
              /ExchangeRate_BuyerLocalCurrency/g,
              exchangeRate.ExchangeRate
            );
          }
        }

        if (newFormula && newFormula.includes("(f")) {
          const match = newFormula.match(/f\d+/)[0];
          newFormula = newFormula.replace(match, 0);
        }
        if (
          newFormula && newFormula.includes("ExchangeRate_")
        ) {
          newFormula = this.evalMasterExchangeRate(newFormula);
        }
        // Now set the property
        if (!EvalResultWithItem[item_id]) {
          EvalResultWithItem[item_id] = {};
        }

        EvalResultWithItem[item_id][defaultIdentifier.FieldId] = parseFloat(
          eval(newFormula)
        ).toFixed(3);
        EvalResults[defaultIdentifier.FieldId] = parseFloat(
          eval(newFormula)
        ).toFixed(3);
      });

      Object.entries(this.props.quotationJsonTemplate.ITEMIZE[0]).forEach(
        (value, index) => {
          let field_id = value[1]?.FieldId;
          if (!obj[key]) {
            obj[key] = [];
            // obj[key][0] = {};
          }
          if (this.props.itemBoxId !== field_id) {
            if (
              this.state.switchState[key] &&
              this.props.multipleSolutionFlag &&
              !this.props.slabFlag &&
              msmainTemplateQuotation[key]
            ) {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }

              Object.entries(msmainTemplateQuotation[key]).forEach(
                ([rowindex, rowdata]) => {
                  let msmaintemp = rowdata["mainData"];
                  if (!obj[key][rowindex + 1]) {
                    obj[key][rowindex + 1] = {};
                  }
                  if (msmaintemp[field_id]) {
                    obj[key][rowindex + 1][field_id] =
                      msmaintemp[field_id]?.value || "";
                    arr.push(field_id);
                    return;
                  }
                }
              );
            } else {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }
              Object.entries(mainTemplateQuotation).forEach(
                ([itemId, mainTemp]) => {
                  if (mainTemp[field_id] && itemId === key) {
                    obj[key][0][field_id] =
                      mainTemp[field_id]?.value ||
                      // mainTemp[field_id]?.DefaultValue ||
                      "";
                    arr.push(field_id);
                    return;
                  }
                }
              );
            }
          }
        }
      );
      obj[key].forEach((rowdata, rowindex) => {
        Object.entries(quotationJsonTemplate.ITEMIZE[0]).forEach(
          ([index, currentField]) => {
            if (
              this.state.mainTemplateQuotation &&
              this.state.mainTemplateQuotation[key] &&
              this.state.mainTemplateQuotation[key].hasOwnProperty(
                currentField.FieldId
              ) &&
              currentField.Identifier !== "Product" &&
              currentField.ElementType !== "M"
            ) {
              if (currentField.ElementType === "D") {
                obj[key][rowindex][currentField.FieldId] = String(
                  EvalResults[currentField.FieldId]
                );
              } else {
                if (currentField.ElementType === "F") {
                  obj[key][rowindex][currentField.FieldId] =
                    this.state.mainTemplateQuotation[key][currentField.FieldId]
                      ?.value.length === 0
                      ? ""
                      : this.state.mainTemplateQuotation[key][
                          currentField.FieldId
                        ]?.value;
                } else {
                  obj[key][rowindex][currentField.FieldId] = String(
                    this.state.mainTemplateQuotation[key][currentField.FieldId]
                      ?.value
                  );
                }
              }
              // }
            } else {
              // else {

              if (arr.indexOf(currentField.FieldId) === -1) {
                let formula =
                  currentField.DefaultFormula ||
                  currentField.DefaultValue.Formula;
                if (this.props.itemBoxId !== currentField.FieldId) {
                  let value = 0;

                  if (formula && formula !== "") {
                    let placeholders = formula.match(/\(f\d+\)/g);
                    if (placeholders) {
                      try {
                        if (
                          currentField.ElementType === "D" &&
                          this.state.mainTemplateQuotation[key]?.selected ===
                            "Y"
                        ) {
                          if (
                            EvalResults.hasOwnProperty(currentField.FieldId)
                          ) {
                            value = EvalResults[currentField.FieldId];
                          }
                        } else {
                          let defaultFormula = currentField.DefaultFormula;
                          if (defaultFormula) {
                            const formattedResult =
                              this.extractAndFormat(defaultFormula);

                            if (
                              formattedResult &&
                              formattedResult.length >= 1
                            ) {
                              const valuesFound = formattedResult
                                .map((identifier, index) => {
                                  const attributeKey = identifier.split(".");
                                  const subStringAttrKey =
                                    attributeKey[1].substring(1);
                                  // Check if the solution array exists and has the identifier
                                  const valuesInSolution =
                                    lastTransactionData.itemize[key]?.solution
                                      ?.map(
                                        (obj) =>
                                          obj?.data[0]?.[subStringAttrKey]
                                      )
                                      ?.filter((value) => value !== undefined);

                                  // Fallback to checking in common if valuesInSolution is empty or undefined
                                  const fallbackValue =
                                    lastTransactionData.common?.[
                                      subStringAttrKey
                                    ];
                                  const fetchTxn = this.props.fetchedTxn?.[
                                    attributeKey[0]
                                  ]?.itemize[key]?.solution
                                    ?.map(
                                      (obj) => obj?.data[0]?.[subStringAttrKey]
                                    )
                                    ?.filter((value) => value !== undefined);
                                  if (
                                    valuesInSolution &&
                                    valuesInSolution.length > 0
                                  ) {
                                    return valuesInSolution;
                                  } else if (
                                    valuesInSolution &&
                                    valuesInSolution.length == 0 &&
                                    fetchTxn &&
                                    fetchTxn.length > 0
                                  ) {
                                    return fetchTxn;
                                  } else if (fallbackValue !== undefined) {
                                    return [fallbackValue];
                                  } else {
                                    return [];
                                  }
                                })
                                .flat();

                              let finalValue = null;
                              // Evaluate conditional statements in DefaultFormula
                              for (let i = 0; i < valuesFound.length; i++) {
                                if (valuesFound[i] !== null) {
                                  finalValue = valuesFound[i];
                                  break;
                                }
                              }
                              value = finalValue;
                            }
                          }
                        }
                      } catch (error) {
                        console.log("error in eval", error);
                      }
                    }
                  } else {
                    if (typeof currentField.DefaultValue == "object") {
                      value = 0;
                    } else {
                      value = currentField.DefaultValue || 0;
                    }
                  }
                  obj[key][rowindex][currentField.FieldId] = String(value || 0);
                  // }
                }
              }
              // }
            }
          }
        );
        if (!finalRemainingQuotation[key]) {
          finalRemainingQuotation[key] = [];
          finalRemainingQuotation[key][rowindex] = {};
        }
        finalRemainingQuotation[key][rowindex] = obj[key][rowindex];
      });
    });
    const common = {};
    const commonDElemnts = {};
    Object.keys(additionalInfoTemplate).map((fieldId, index) => {
      let fieldValue = additionalInfoTemplate[fieldId]?.value;
      if (additionalInfoTemplate[fieldId].VisibilityFlag === "Y") {
        const isAttachmentField =
          additionalInfoTemplate[fieldId].Identifier === "Attachment" ||
          additionalInfoTemplate[fieldId].Identifier === "AttachmentForeign";

        if (isAttachmentField) {
          if (Array.isArray(fieldValue)) {
            common[fieldId] = fieldValue.length > 0 ? fieldValue : "";
          } else {
            common[fieldId] = "";
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      } else {
        let commonDelements = {};
        if (additionalInfoTemplate[fieldId]?.ElementType === "D") {
          if (
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQty" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQtyFor" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalQuantity"
          ) {
            let exactIndex = Object.keys(this.state.mainTemplateQuotation)[0];
            common[fieldId] = EvalResultWithItem[exactIndex][fieldId];
          } else {
            if (
              additionalInfoTemplate[fieldId].DefaultValue.hasOwnProperty(
                "Formula"
              ) &&
              additionalInfoTemplate[fieldId].DefaultValue.Formula != ""
            ) {
              let pattern = /f(\d+)/g;
              let formula =
                additionalInfoTemplate[fieldId].DefaultValue?.Formula;
              const matches = [...formula.matchAll(pattern)];
              const matchedIdentifiersFormula = matches.map(
                (match) => match[1]
              );
              matchedIdentifiersFormula.forEach((formulaIdentifier) => {
                let FromItemize = this.state.allItemsTemplate.find((field) => {
                  return (
                    field.FieldId === formulaIdentifier &&
                    field.HTMLAttributeName.includes("_")
                  );
                });
                if (FromItemize) {
                  if (FromItemize?.ElementType === "D") {
                    const sum = this.sumFieldValue(
                      EvalResultWithItem,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sum
                        ? !isNaN(parseFloat(sum))
                          ? parseFloat(sum)
                          : `"${sum}"`
                        : 0
                    );
                  } else {
                    const sumOfFieldQty = this.getSumOfField(
                      mainTemplateQuotation,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sumOfFieldQty
                    );
                  }
                } else if (commonDElemnts.hasOwnProperty(formulaIdentifier)) {
                  formula = formula.replace(
                    `f${formulaIdentifier}`,
                    parseFloat(commonDElemnts[formulaIdentifier])
                  );
                } else {
                  let exactIndex = Object.keys(
                    this.state.mainTemplateQuotation
                  )[0];
                  let replacedValue =
                    EvalResultWithItem[exactIndex][formulaIdentifier];
                  if (
                    EvalResultWithItem[exactIndex].hasOwnProperty(
                      formulaIdentifier
                    )
                  ) {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      replacedValue
                        ? !isNaN(parseFloat(replacedValue))
                          ? parseFloat(replacedValue)
                          : `"${replacedValue}"`
                        : 0
                    );
                  } else {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                        ? !isNaN(
                            parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          )
                          ? parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                        : 0
                    );
                  }
                }
              });
              if (formula.includes("ExchangeRate_")
              ) {
                formula = this.evalMasterExchangeRate(formula);
                let exchangeRate = this.props.currencyOptions.find(
                  (currency) =>
                    currency.Currency === (this.props.currencyDetails || "INR")
                );
                if (exchangeRate) {
                  formula = formula.replace(
                    /ExchangeRate_BuyerLocalCurrency/g,
                    exchangeRate.ExchangeRate
                  );
                }
              }
              common[fieldId] = parseFloat(
                eval(formula)
              ).toFixed(3);
              commonDElemnts[fieldId] = parseFloat(
                eval(formula)
              ).toFixed(3)
            } else {
              if (
                typeof additionalInfoTemplate[fieldId].DefaultValue == "object"
              ) {
                common[fieldId] = 0;
              } else {
                common[fieldId] =
                  additionalInfoTemplate[fieldId].DefaultValue || 0;
              }
            }
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      }
    });

    // for itemize field
    const itemize = {};
    this.props.transactionItemSequence.forEach((itemId) => {
      let solution = [];
      for (const rowindex in finalRemainingQuotation[itemId]) {
        let obj = {
          // currency: item.currency,
          data: [finalRemainingQuotation[itemId][rowindex]],
        };
        solution.push(obj);
      }
      if (mainTemplateQuotation.hasOwnProperty(itemId)) {
        const item = mainTemplateQuotation[itemId];
        const itemData = {
          selected: item.selected,
          formdetailRowId: item.formdetailRowId,
          itemBoxId: this.props.itemBoxId,
          solution: solution,
        };
        itemize[itemId] = itemData;
      }
    });
    // Construct payload
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);
    const quotationDate = dayjs(
      this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
        this?.state?.dataFromQuotationDetails?.offlineRequoteDate
    );

    const formattedDate = quotationDate.isValid()
      ? quotationDate.format("YYYY-MM-DD HH:mm:ss IST")
      : null;

    // Use formattedDate wherever needed
    const payload = {
      inquiry_id: this.props.inquiryId,
      quotation_number:
        this?.state?.dataFromQuotationDetails?.offlineRequoteNo ||
        this?.state?.dataFromQuotationDetails?.offlineQuotationNo,
      validity_date: dayjs(
        this?.state?.dataFromQuotationDetails?.requoteValidity ||
          this?.state?.dataFromQuotationDetails?.quotationValidity
      ).format("YYYY-MM-DD HH:mm:ss IST"),
      tran_status:
        this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote",
      currency: this.state.selectedCurrency || this.props.quotedCurrency,
      terms_and_conditions_text: "",
      location_flag: this.props.vendorType === "Local" ? "L" : "I",
      shipment_mark: this.props.CurrentTransaction.shipment_mark || "",
      template: {
        itemize: itemize,
        common: common,
      },
      ...(supplier !== 1 && {
        offline: {
          reference: this?.state?.dataFromQuotationDetails?.reference,
          vendor_id: this.props.vendorId,
          vendor_quotation_date: dayjs(
            this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
              this?.state?.dataFromQuotationDetails?.offlineRequoteDate
          ).format("YYYY-MM-DD HH:mm:ss IST"),
          vendor_quotation_date: formattedDate,
          attachment: this?.state?.dataFromQuotationDetails?.attachment,
        },
      }),
    };
    if (this.props.isServiceRfq) {
      let dataSeq = this.props.transactionItemSequence.filter((seq) => {
        return Object.keys(this.state.mainTemplateQuotation).includes(seq);
      });

      payload.item_id_seq = this.props.itemSpecific
        ? dataSeq
        : this.props.transactionItemSequence;
      payload.item_ids_data = dataSeq;
      payload.filename = this.state.excelSourceName
        ? this.state.excelSourceName
        : this.props.ServiceFileName;
      payload.activity_template_id = this.props.activity_template_id;
      payload.serviceRFQ = this.props.isServiceRfq;
    }
    return payload;
  };

  arrangedEvalForMS = (formula, key, rowIndex, result, identifier) => {
    if (typeof formula !== "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result[rowIndex]?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[rowIndex][formulaIdentifier]
              ? !isNaN(parseFloat(result[rowIndex][formulaIdentifier]))
                ? parseFloat(result[rowIndex][formulaIdentifier])
                : `"${result[rowIndex][formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.multiSolmainTemplateQuotation?.[key]?.[
              rowIndex
            ].hasOwnProperty(formulaIdentifier)
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.multiSolmainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value === "string" &&
              this.state.multiSolmainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.multiSolmainTemplateQuotation[key][rowIndex]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfFieldForSlab(
                  this.state.multiSolmainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.multiSolmainTemplateQuotation[
                  key
                ][rowIndex]?.[formulaIdentifier]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.multiSolmainTemplateQuotation[key][
                          rowIndex
                        ]?.[formulaIdentifier]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.multiSolmainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    ? parseFloat(
                        this.state.multiSolmainTemplateQuotation[key][
                          rowIndex
                        ]?.[formulaIdentifier]?.value
                      )
                    : `"${this.state.multiSolmainTemplateQuotation[key][rowIndex]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  getSumOfFieldForSlab = (data, fieldId) => {
    let sum = 0;
    for (let key in data) {
      for (let rowIndex in data[key]) {
        if (data[key][rowIndex][fieldId]) {
          sum += parseFloat(data[key][rowIndex][fieldId].value);
        }
      }
    }
    return sum;
  };

  arrangedEvalForSlab = (formula, key, rowIndex, result, identifier) => {
    if (typeof formula !== "undefined") {
      let pattern = /f(\d+)/g;
      const matches = [...formula.matchAll(pattern)];
      const matchedIdentifiersFormula = matches.map((match) => match[1]);
      matchedIdentifiersFormula.forEach((formulaIdentifier) => {
        if (result[rowIndex]?.hasOwnProperty(formulaIdentifier)) {
          formula = formula.replace(
            `f${formulaIdentifier}`,
            result[rowIndex][formulaIdentifier]
              ? !isNaN(parseFloat(result[rowIndex][formulaIdentifier]))
                ? parseFloat(result[rowIndex][formulaIdentifier])
                : `"${result[rowIndex][formulaIdentifier]}"`
              : 0
          );
        } else if (
          this.state.additionalInfoTemplate.hasOwnProperty(formulaIdentifier)
        ) {
          if (
            typeof this.state.additionalInfoTemplate[formulaIdentifier]
              ?.value === "string" &&
            this.state.additionalInfoTemplate[
              formulaIdentifier
            ]?.value.includes("###")
          ) {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              `"${
                this.state.additionalInfoTemplate[
                  formulaIdentifier
                ]?.value.split("###")[0]
              }"`
            );
          } else {
            formula = formula.replace(
              `f${formulaIdentifier}`,
              this.state.additionalInfoTemplate[formulaIdentifier]?.value
                ? !isNaN(
                    parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  )
                  ? parseFloat(
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                    )
                  : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                : 0
            );
          }
        } else {
          if (
            !this.state.slabMainTemplateQuotation?.[key]?.[
              rowIndex
            ].hasOwnProperty(formulaIdentifier)
          ) {
            let defValue = this.state.allItemsTemplate.find((item) => {
              return item.FieldId == formulaIdentifier;
            });
            if (
              defValue &&
              defValue.ElementType != "D" &&
              !defValue.DefaultValue.hasOwnProperty("Formula") &&
              !defValue.DefaultValue.includes("(f")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                parseFloat(defValue.DefaultValue) || 0
              );
            }
          } else {
            if (
              typeof this.state.slabMainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value === "string" &&
              this.state.slabMainTemplateQuotation[key][rowIndex]?.[
                formulaIdentifier
              ]?.value?.includes("###")
            ) {
              formula = formula.replace(
                `f${formulaIdentifier}`,
                `"${
                  this.state.slabMainTemplateQuotation[key][rowIndex]?.[
                    formulaIdentifier
                  ]?.value.split("###")[0]
                }"`
              );
            } else {
              if (
                identifier == "TotalReqQty" ||
                identifier == "TotalReqQtyFor" ||
                identifier == "TotalQuantity"
              ) {
                const sumOfFieldQty = this.getSumOfFieldForSlab(
                  this.state.slabMainTemplateQuotation,
                  formulaIdentifier
                );
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  sumOfFieldQty
                );
              } else {
                let replaceValue = this.state.slabMainTemplateQuotation[key][
                  rowIndex
                ]?.[formulaIdentifier]?.value
                  ? !isNaN(
                      parseFloat(
                        this.state.slabMainTemplateQuotation[key][rowIndex]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    ) &&
                    isFinite(
                      this.state.slabMainTemplateQuotation[key][rowIndex]?.[
                        formulaIdentifier
                      ]?.value
                    )
                    ? parseFloat(
                        this.state.slabMainTemplateQuotation[key][rowIndex]?.[
                          formulaIdentifier
                        ]?.value
                      )
                    : `"${this.state.slabMainTemplateQuotation[key][rowIndex]?.[formulaIdentifier]?.value}"`
                  : 0;
                formula = formula.replace(
                  `f${formulaIdentifier}`,
                  replaceValue
                );
              }
            }
          }
        }
      });
      return formula;
    }
  };

  prepareQuotationPayloadForMS = () => {
    let {
      additionalInfoTemplate,
      mainTemplateQuotation,
      multiSolmainTemplateQuotation,
    } = this.state;
    const { lastTransactionData, quotationJsonTemplate } = this.props;
    let newMultiSolQuotationTemplate = this.state.multiSolmainTemplateQuotation;
    let itemize = {};
    let multiSolItemize = {};
    let finalRemainingQuotation = {};
    let finalRemainingQuotationForMS = {};
    let EvalResultWithItemForMS = {};
    // Clear previous errors
    this.setState({ solutionNameErrors: {} });
    this.setState({ errorArrayForMSParentName: {} });
    if (this.props.itemSpecific) {
      mainTemplateQuotation = {
        ...mainTemplateQuotation,
        ...this.state.notFilteredMainTemplateQuotation,
      };
    }

    // Prepare itemize object
    let item_id_arr =
      this.props.inquiryItemsId.length > 0
        ? this.props.inquiryItemsId
        : Object.keys(mainTemplateQuotation);
    this.props.transactionItemSequence.forEach((item_id) => {
      let arr = [];
      let obj = {};
      let key = item_id;
      let EvalResults = {};
      let EvalMultiSolResults = {};
      Object.keys(this.state.switchState).forEach((key) => {
        if (!this.state.switchState[key]) {
          this.state.rearrangedArray.forEach((defaultIdentifier) => {
            let formula = defaultIdentifier.DefaultValue.Formula;
            let newFormula = this.arrangedEval(formula, key, EvalResults);
            if (
              newFormula &&
              newFormula.includes("ExchangeRate_SelectedCurrency")
            ) {
              let exchangeRate = this.props.currencyOptions.find(
                (currency) =>
                  currency.Currency ==
                  (this.state.selectedCurrency || this.props.quotedCurrency)
              );
              if (exchangeRate) {
                newFormula = newFormula.replace(
                  /ExchangeRate_SelectedCurrency/g,
                  exchangeRate.ExchangeRate
                );
              }
            }

            if (
              newFormula &&
              newFormula.includes("ExchangeRate_BuyerLocalCurrency")
            ) {
              let exchangeRate = this.props.currencyOptions.find(
                (currency) =>
                  currency.Currency === (this.props.currencyDetails || "INR")
              );
              if (exchangeRate) {
                newFormula = newFormula.replace(
                  /ExchangeRate_BuyerLocalCurrency/g,
                  exchangeRate.ExchangeRate
                );
              }
            }

            if (newFormula && newFormula.includes("(f")) {
              const match = newFormula.match(/f\d+/)[0];
              newFormula = newFormula.replace(match, 0);
            }
            if (
              newFormula && newFormula.includes("ExchangeRate_")
            ) {
              newFormula = this.evalMasterExchangeRate(newFormula);
            }
            // Now set the property
            if (!EvalResultWithItemForMS[item_id]) {
              EvalResultWithItemForMS[item_id] = {};
            }

            EvalResultWithItemForMS[item_id][defaultIdentifier.FieldId] =
              parseFloat(eval(newFormula));
            EvalResults[defaultIdentifier.FieldId] = parseFloat(
              eval(newFormula)
            );
          });
        } else {
          // Now set the property
          Object.entries(newMultiSolQuotationTemplate).forEach(([key, obj]) => {
            if (Array.isArray(obj)) {
              EvalMultiSolResults[key] = {};
              EvalResultWithItemForMS[key] = {};
              obj.forEach((rowdata, rowIndex) => {
                if (!EvalMultiSolResults[key][rowIndex]) {
                  EvalMultiSolResults[key][rowIndex] = {};
                }
                if (!EvalResultWithItemForMS[key][rowIndex]) {
                  EvalResultWithItemForMS[key][rowIndex] = {};
                }
                this.state.rearrangedArray.forEach((defaultIdentifier) => {
                  let formula = defaultIdentifier.DefaultValue.Formula;
                  if (formula) {
                    let newFormulaMS = this.arrangedEvalForMS(
                      formula,
                      key,
                      rowIndex,
                      EvalMultiSolResults[key],
                      defaultIdentifier.Identifier
                    );
                    if (
                      newFormulaMS.includes("ExchangeRate_SelectedCurrency")
                    ) {
                      let exchangeRate = this.props.currencyOptions.find(
                        (currency) =>
                          currency.Currency ==
                          (this.state.selectedCurrency ||
                            this.props.quotedCurrency)
                      );
                      if (exchangeRate) {
                        newFormulaMS = newFormulaMS.replace(
                          /ExchangeRate_SelectedCurrency/g,
                          exchangeRate.ExchangeRate
                        );
                      }
                    }
                    if (
                      newFormulaMS.includes("ExchangeRate_BuyerLocalCurrency")
                    ) {
                      let exchangeRate = this.props.currencyOptions.find(
                        (currency) =>
                          currency.Currency ===
                          (this.props.currencyDetails || "INR")
                      );
                      if (exchangeRate) {
                        newFormulaMS = newFormulaMS.replace(
                          /ExchangeRate_BuyerLocalCurrency/g,
                          exchangeRate.ExchangeRate
                        );
                      }
                    }
                    if (
                      newFormulaMS && newFormulaMS.includes("ExchangeRate_")
                    ) {
                      newFormulaMS = this.evalMasterExchangeRate(newFormulaMS);
                    }
                    if (newFormulaMS && newFormulaMS.includes("(f")) {
                      const match = newFormulaMS.match(/f\d+/)[0];
                      newFormulaMS = newFormulaMS.replace(match, 0);
                    }
                    if (
                      this.props.multipleSolutionFlag &&
                      !this.props.slabFlag &&
                      this.state.switchState[key]
                    ) {
                      const evaluatedValue = parseFloat(eval(newFormulaMS));

                      if (!isNaN(evaluatedValue)) {
                        EvalMultiSolResults[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = evaluatedValue;
                      } else {
                        EvalMultiSolResults[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = 0; // or any default value you prefer
                      }
                    }

                    if (
                      this.props.multipleSolutionFlag &&
                      !this.props.slabFlag &&
                      this.state.switchState[key]
                    ) {
                      const evaluatedValueItemResult = parseFloat(
                        eval(newFormulaMS)
                      );

                      if (!isNaN(evaluatedValueItemResult)) {
                        EvalResultWithItemForMS[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = evaluatedValueItemResult;
                      } else {
                        EvalResultWithItemForMS[key][rowIndex][
                          defaultIdentifier?.FieldId
                        ] = 0; // or any default value you prefer
                      }
                    }
                  }
                });
              });
            }
          });
        }

        if (
          this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          !this.state.switchState[key]
        ) {
          // For mainTemplateQuotation
          Object.entries(this.props.quotationJsonTemplate.ITEMIZE[0]).forEach(
            (value, index) => {
              let field_id = value[1]?.FieldId;
              if (!obj[key]) {
                obj[key] = [];
                // obj[key][0] = {};
              }
              if (this.props.itemBoxId !== field_id) {
                if (!obj[key][0]) {
                  obj[key][0] = {};
                }
                Object.entries(mainTemplateQuotation).forEach(
                  ([itemId, mainTemp]) => {
                    if (mainTemp[field_id] && itemId === key) {
                      obj[key][0][field_id] = mainTemp[field_id]?.value || "";
                      arr.push(field_id);
                      return;
                    }
                  }
                );
              }
            }
          );
          obj[key].forEach((rowdata, rowindex) => {
            Object.entries(quotationJsonTemplate.ITEMIZE[0]).forEach(
              ([index, currentField]) => {
                if (
                  this.state.mainTemplateQuotation &&
                  this.state.mainTemplateQuotation[key] &&
                  this.state.mainTemplateQuotation[key].hasOwnProperty(
                    currentField.FieldId
                  ) &&
                  currentField.Identifier !== "Product" &&
                  currentField.ElementType !== "M"
                ) {
                  if (currentField.ElementType === "D") {
                    obj[key][rowindex][currentField.FieldId] = String(
                      EvalResults[currentField.FieldId]
                    );
                  } else {
                    obj[key][rowindex][currentField.FieldId] = String(
                      this.state.mainTemplateQuotation[key][
                        currentField.FieldId
                      ]?.value
                    );
                  }
                } else {
                  if (arr.indexOf(currentField.FieldId) === -1) {
                    let formula =
                      currentField.DefaultFormula ||
                      currentField.DefaultValue.Formula;
                    if (this.props.itemBoxId !== currentField.FieldId) {
                      let value = 0;
                      if (formula && formula !== "") {
                        let placeholders = formula.match(/\(f\d+\)/g);
                        if (placeholders) {
                          try {
                            if (currentField.ElementType === "D") {
                              if (
                                EvalResults.hasOwnProperty(currentField.FieldId)
                              ) {
                                value = EvalResults[currentField.FieldId];
                              }
                            } else {
                              let defaultFormula = currentField.DefaultFormula;
                              if (defaultFormula) {
                                const formattedResult =
                                  this.extractAndFormat(defaultFormula);

                                if (
                                  formattedResult &&
                                  formattedResult.length >= 1
                                ) {
                                  const valuesFound = formattedResult
                                    .map((identifier, index) => {
                                      const attributeKey =
                                        identifier.split(".");
                                      const subStringAttrKey =
                                        attributeKey[1].substring(1);
                                      const valuesInSolution =
                                        lastTransactionData.itemize[
                                          key
                                        ]?.solution
                                          ?.map(
                                            (obj) =>
                                              obj?.data[0]?.[subStringAttrKey]
                                          )
                                          ?.filter(
                                            (value) => value !== undefined
                                          );
                                      const fallbackValue =
                                        lastTransactionData.common?.[
                                          subStringAttrKey
                                        ];
                                      const fetchTxn = this.props.fetchedTxn?.[
                                        attributeKey[0]
                                      ]?.itemize[key]?.solution
                                        ?.map(
                                          (obj) =>
                                            obj?.data[0]?.[subStringAttrKey]
                                        )
                                        ?.filter(
                                          (value) => value !== undefined
                                        );
                                      if (
                                        valuesInSolution &&
                                        valuesInSolution.length > 0
                                      ) {
                                        return valuesInSolution;
                                      } else if (
                                        valuesInSolution &&
                                        valuesInSolution.length == 0 &&
                                        fetchTxn &&
                                        fetchTxn.length > 0
                                      ) {
                                        return fetchTxn;
                                      } else if (fallbackValue !== undefined) {
                                        return [fallbackValue];
                                      } else {
                                        return [];
                                      }
                                    })
                                    .flat();

                                  let finalValue = null;
                                  for (let i = 0; i < valuesFound.length; i++) {
                                    if (valuesFound[i] !== null) {
                                      finalValue = valuesFound[i];
                                      break;
                                    }
                                  }
                                  value = finalValue;
                                }
                              }
                            }
                          } catch (error) {
                            console.log("error in eval", error);
                          }
                        }
                      } else {
                        value = currentField.DefaultValue || 0;
                      }
                      obj[key][rowindex][currentField.FieldId] = String(
                        value || 0
                      );
                    }
                  }
                }
              }
            );
            if (!finalRemainingQuotation[key]) {
              finalRemainingQuotation[key] = [];
              finalRemainingQuotation[key][rowindex] = {};
            }
            finalRemainingQuotation[key][rowindex] = obj[key][rowindex];
          });
        } else {
          // For multiple multiSolmainTemplateQuotation
          Object.entries(newMultiSolQuotationTemplate).forEach(([key, obj]) => {
            let productElement = this.state.allItemsTemplate.find((item) => {
              return (
                item.Identifier === "Product" ||
                item.Identifier === "ProductForeign"
              );
            });
            let productNewElement = productElement.FieldId;
            let copiedObj = JSON.parse(JSON.stringify(obj));
            const updatedData = copiedObj.map((item) => {
              const { [productNewElement]: excluded, ...rest } = item; // Destructure and exclude the productNewElement field
              return rest;
            });
            copiedObj = updatedData;
            copiedObj.forEach((rowdata, rowIndex) => {
              Object.entries(quotationJsonTemplate.ITEMIZE[0]).forEach(
                ([index, currentField]) => {
                  if (
                    newMultiSolQuotationTemplate &&
                    newMultiSolQuotationTemplate[key][rowIndex] &&
                    newMultiSolQuotationTemplate[key][rowIndex].hasOwnProperty(
                      currentField.FieldId
                    ) &&
                    (currentField.Identifier !== "Product" ||
                      currentField.Identifier !== "ProductForeign") &&
                    currentField.ElementType !== "M"
                  ) {
                    if (currentField.ElementType === "D") {
                      copiedObj[rowIndex][currentField.FieldId] =
                        EvalMultiSolResults[key][rowIndex][
                          currentField.FieldId
                        ]; //Change
                    } else {
                      copiedObj[rowIndex][currentField.FieldId] =
                        newMultiSolQuotationTemplate[key][rowIndex][
                          currentField.FieldId
                        ]["value"];
                    }
                  } else {
                    if (arr.indexOf(currentField.FieldId) === -1) {
                      let formula =
                        currentField.DefaultFormula ||
                        currentField.DefaultValue.Formula;

                      if (this.props.itemBoxId !== currentField.FieldId) {
                        let value = 0;
                        if (formula && formula !== "") {
                          let placeholders = formula.match(/\(f\d+\)/g);
                          if (placeholders) {
                            try {
                              if (currentField.ElementType === "D") {
                                if (
                                  EvalMultiSolResults[key][
                                    rowIndex
                                  ].hasOwnProperty(currentField.FieldId)
                                ) {
                                  value =
                                    EvalMultiSolResults[key][rowIndex][
                                      currentField.FieldId
                                    ];
                                }
                              } else {
                                let defaultFormula =
                                  currentField.DefaultFormula;
                                if (defaultFormula) {
                                  const formattedResult =
                                    this.extractAndFormat(defaultFormula);

                                  if (
                                    formattedResult &&
                                    formattedResult.length >= 1
                                  ) {
                                    const valuesFound = formattedResult
                                      .map((identifier, index) => {
                                        const attributeKey =
                                          identifier.split(".");
                                        const subStringAttrKey =
                                          attributeKey[1].substring(1);
                                        const valuesInSolution =
                                          lastTransactionData.itemize[
                                            key
                                          ]?.solution
                                            ?.map(
                                              (obj) =>
                                                obj?.data[0]?.[subStringAttrKey]
                                            )
                                            ?.filter(
                                              (value) => value !== undefined
                                            );
                                        const fallbackValue =
                                          lastTransactionData.common?.[
                                            subStringAttrKey
                                          ];
                                        const fetchTxn =
                                          this.props.fetchedTxn?.[
                                            attributeKey[0]
                                          ]?.itemize[key]?.solution
                                            ?.map(
                                              (obj) =>
                                                obj?.data[0]?.[subStringAttrKey]
                                            )
                                            ?.filter(
                                              (value) => value !== undefined
                                            );
                                        if (
                                          valuesInSolution &&
                                          valuesInSolution.length > 0
                                        ) {
                                          return valuesInSolution;
                                        } else if (
                                          valuesInSolution &&
                                          valuesInSolution.length == 0 &&
                                          fetchTxn &&
                                          fetchTxn.length > 0
                                        ) {
                                          return fetchTxn;
                                        } else if (
                                          fallbackValue !== undefined
                                        ) {
                                          return [fallbackValue];
                                        } else {
                                          return [];
                                        }
                                      })
                                      .flat();

                                    let finalValue = null;
                                    for (
                                      let i = 0;
                                      i < valuesFound.length;
                                      i++
                                    ) {
                                      if (valuesFound[i] !== null) {
                                        finalValue = valuesFound[i];
                                        break;
                                      }
                                    }
                                    value = finalValue;
                                  }
                                }
                              }
                            } catch (error) {
                              console.log("error in eval", error);
                            }
                          }
                        } else {
                          value = currentField.DefaultValue || 0;
                        }
                        copiedObj[rowIndex][currentField.FieldId] = value || 0;
                      }
                    }
                  }
                }
              );

              if (!finalRemainingQuotationForMS[key]) {
                finalRemainingQuotationForMS[key] = [];
                finalRemainingQuotationForMS[key][rowIndex] = {};
              }
              finalRemainingQuotationForMS[key][rowIndex] = copiedObj[rowIndex];
            });
          });
        }
        // }

        if (
          this.props.multipleSolutionFlag &&
          !this.props.slabFlag &&
          !this.state.switchState[key]
        ) {
          let solution = [];

          for (const rowindex in finalRemainingQuotation[key]) {
            const keys = `${key}-0`;
            let obj = {
              // name: this.state.solutionNameMainTemplate[key] || "",
              // changed below condition to fix issue id TU4-I148
              name:
                this.state.solutionNames[keys] !== ""
                  ? this.state.solutionNames[keys]
                  : this.state.solutionNameMainTemplate[key],
              data: [finalRemainingQuotation[key][rowindex]],
            };
            solution.push(obj);
          }
          if (mainTemplateQuotation.hasOwnProperty(key)) {
            const item = mainTemplateQuotation[key];

            const itemData = {
              selected: item.selected,
              formdetailRowId: item.formdetailRowId,
              itemBoxId: this.props.itemBoxId,
              solution: solution,
            };
            itemize[key] = itemData;
          }
        } else {
          // this.props.transactionItemSequence.forEach((itemId) => {
          let solution = [];
          if (finalRemainingQuotationForMS.hasOwnProperty(key)) {
            const itemQuotations = finalRemainingQuotationForMS[key];
            for (const rowIndex in itemQuotations) {
              if (itemQuotations.hasOwnProperty(rowIndex)) {
                const keys = `${key}-${rowIndex}`;
                let obj = {
                  name: this.state.solutionNames[keys] || "",
                  data: [itemQuotations[rowIndex]],
                };

                // Filter out numeric keys
                obj.data = obj.data.map((dataObj) => {
                  let filteredObj = {};
                  for (let key in dataObj) {
                    if (/^\d+$/.test(key)) {
                      filteredObj[key] = dataObj[key];
                    }
                  }
                  return filteredObj;
                });
                solution.push(obj);

                if (
                  multiSolmainTemplateQuotation.hasOwnProperty(key) &&
                  multiSolmainTemplateQuotation[key].hasOwnProperty(rowIndex)
                ) {
                  const itemMS = multiSolmainTemplateQuotation[key][rowIndex];
                  const item = this.state.mainTemplateQuotation[key];

                  const itemData = {
                    selected: item.selected,
                    formdetailRowId: itemMS.formdetailRowId,
                    itemBoxId: this.props.itemBoxId,
                    solution: solution,
                  };
                  multiSolItemize[key] = itemData;
                }
              }
            }
          } else {
            console.warn(
              `finalRemainingQuotationForMS does not contain key: ${key}`
            );
          }
          // });
        }
      });
    });

    Object.entries(multiSolItemize).forEach(([key, obj]) => {
      if (this.state.switchState[key]) {
        let isValid = true;
        const solutions = obj.solution; // Access the solution array
        solutions?.forEach((solutionItem) => {
          solutionItem.data?.forEach((rowdata, rowindex) => {
            // Iterate over the data array within each solution item
            if (
              !rowdata["solutionName"] ||
              rowdata["solutionName"].trim() === ""
            ) {
              isValid = false;
              this.setState((prevState) => ({
                solutionNameErrors: {
                  ...prevState.solutionNameErrors,
                  [rowindex]: "Solution name is required",
                },
              }));
            }
          });
        });
      }
    });

    //common new work

    const common = {};
    const commonDElemnts = {};
    Object.keys(additionalInfoTemplate).map((fieldId, index) => {
      let fieldValue = additionalInfoTemplate[fieldId]?.value;
      if (additionalInfoTemplate[fieldId].VisibilityFlag === "Y") {
        const isAttachmentField =
          additionalInfoTemplate[fieldId].Identifier === "Attachment" ||
          additionalInfoTemplate[fieldId].Identifier === "AttachmentForeign";

        if (isAttachmentField) {
          if (Array.isArray(fieldValue)) {
            common[fieldId] = fieldValue.length > 0 ? fieldValue : "";
          } else {
            common[fieldId] = "";
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      } else {
        let commonDelements = {};
        if (additionalInfoTemplate[fieldId]?.ElementType === "D") {
          if (
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQty" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalReqQtyFor" ||
            additionalInfoTemplate[fieldId].Identifier == "TotalQuantity"
          ) {
            let exactIndex = Object.keys(this.state.mainTemplateQuotation)[0];
            common[fieldId] = EvalResultWithItemForMS[exactIndex][fieldId];
          } else {
            if (
              additionalInfoTemplate[fieldId].DefaultValue.hasOwnProperty(
                "Formula"
              ) &&
              additionalInfoTemplate[fieldId].DefaultValue.Formula != ""
            ) {
              let pattern = /f(\d+)/g;
              let formula =
                additionalInfoTemplate[fieldId].DefaultValue?.Formula;
              const matches = [...formula.matchAll(pattern)];
              const matchedIdentifiersFormula = matches.map(
                (match) => match[1]
              );
              matchedIdentifiersFormula.forEach((formulaIdentifier) => {
                let FromItemize = this.state.allItemsTemplate.find((field) => {
                  return (
                    field.FieldId === formulaIdentifier &&
                    field.HTMLAttributeName.includes("_")
                  );
                });
                if (FromItemize) {
                  if (FromItemize?.ElementType === "D") {
                    const sum = this.sumFieldValue(
                      EvalResultWithItemForMS,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sum
                        ? !isNaN(parseFloat(sum))
                          ? parseFloat(sum)
                          : `"${sum}"`
                        : 0
                    );
                  } else {
                    const sumOfFieldQty = this.getSumOfField(
                      mainTemplateQuotation,
                      formulaIdentifier
                    );
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      sumOfFieldQty
                    );
                  }
                } else if (commonDElemnts.hasOwnProperty(formulaIdentifier)) {
                  formula = formula.replace(
                    `f${formulaIdentifier}`,
                    parseFloat(commonDElemnts[formulaIdentifier])
                  );
                } else {
                  let exactIndex = Object.keys(
                    this.state.mainTemplateQuotation
                  )[0];

                  let replacedValue =
                    EvalResultWithItemForMS[exactIndex][formulaIdentifier];
                  if (
                    EvalResultWithItemForMS[exactIndex]?.hasOwnProperty(
                      formulaIdentifier
                    )
                  ) {
                    formula = formula?.replace(
                      `f${formulaIdentifier}`,
                      replacedValue
                        ? !isNaN(parseFloat(replacedValue))
                          ? parseFloat(replacedValue)
                          : `"${replacedValue}"`
                        : 0
                    );
                  } else {
                    formula = formula.replace(
                      `f${formulaIdentifier}`,
                      this.state.additionalInfoTemplate[formulaIdentifier]
                        ?.value
                        ? !isNaN(
                            parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          )
                          ? parseFloat(
                              this.state.additionalInfoTemplate[
                                formulaIdentifier
                              ]?.value
                            )
                          : `"${this.state.additionalInfoTemplate[formulaIdentifier]?.value}"`
                        : 0
                    );
                  }
                }
              });
              common[fieldId] = parseFloat(eval(formula)).toFixed(3);
              commonDElemnts[fieldId] = parseFloat(eval(formula)).toFixed(3);
            } else {
              if (
                typeof additionalInfoTemplate[fieldId].DefaultValue == "object"
              ) {
                common[fieldId] = 0;
              } else {
                common[fieldId] =
                  additionalInfoTemplate[fieldId].DefaultValue || 0;
              }
            }
          }
        } else {
          common[fieldId] = fieldValue != null ? fieldValue : "";
        }
      }
    });

    // Construct payload
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);
    const quotationDate = dayjs(
      this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
        this?.state?.dataFromQuotationDetails?.offlineRequoteDate
    );

    const formattedDate = quotationDate.isValid()
      ? quotationDate.format("YYYY-MM-DD HH:mm:ss IST")
      : null;

    const concatenatedItemizeUnique = {
      ...multiSolItemize,
      ...itemize,
    };

    // Use formattedDate wherever needed
    const payload = {
      inquiry_id: this.props.inquiryId,
      quotation_number:
        this?.state?.dataFromQuotationDetails?.offlineRequoteNo ||
        this?.state?.dataFromQuotationDetails?.offlineQuotationNo,
      validity_date: dayjs(
        this?.state?.dataFromQuotationDetails?.requoteValidity ||
          this?.state?.dataFromQuotationDetails?.quotationValidity
      ).format("YYYY-MM-DD HH:mm:ss IST"),
      tran_status:
        this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote",
      currency: this.state.selectedCurrency || this.props.quotedCurrency,
      terms_and_conditions_text: "",
      location_flag: this.props.vendorType === "Local" ? "L" : "I",
      shipment_mark: this.props.CurrentTransaction.shipment_mark || "",
      template: {
        itemize: concatenatedItemizeUnique,
        common: common,
      },
      ...(supplier !== 1 && {
        offline: {
          reference: this?.state?.dataFromQuotationDetails?.reference,
          vendor_id: this.props.vendorId,
          vendor_quotation_date: dayjs(
            this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
              this?.state?.dataFromQuotationDetails?.offlineRequoteDate
          ).format("YYYY-MM-DD HH:mm:ss IST"),
          vendor_quotation_date: formattedDate,
          attachment: this?.state?.dataFromQuotationDetails?.attachment,
        },
      }),
    };
    return payload;
  };

  prepareQuotationPayloadForSlab = () => {
    let {
      additionalInfoTemplate,
      mainTemplateQuotation,
      remainTemplateQuotation,
    } = this.state;
    const { lastTransactionData, quotationJsonTemplate } = this.props;
    let newSlabQuotationTemplate = this.state.slabMainTemplateQuotation;
    let itemize = {};
    let slabItemize = {};
    let finalRemainingQuotation = {};
    let finalRemainingCommonQuotation = {};
    let finalRemainingQuotationForSlab = {};

    if (this.props.itemSpecific) {
      newSlabQuotationTemplate = {
        ...newSlabQuotationTemplate,
        ...this.state.notFilteredSlabMainTemplateQuotation,
      };
    }

    // Prepare itemize object
    let item_id_arr =
      this.props.inquiryItemsId.length > 0
        ? this.props.inquiryItemsId
        : Object.keys(mainTemplateQuotation);
    // item_id_arr.forEach((item_id) => {
    this.props.transactionItemSequence.forEach((item_id) => {
      let arr = [];
      let obj = {};
      let key = item_id;
      let EvalResults = {};
      let EvalSlabResults = {};
      Object.entries(newSlabQuotationTemplate).forEach(([key, obj]) => {
        EvalSlabResults[key] = {};
        obj.forEach((rowdata, rowIndex) => {
          // Initialize EvalSlabResults[rowIndex] if necessary
          if (!EvalSlabResults[key][rowIndex]) {
            EvalSlabResults[key][rowIndex] = {};
          }
          this.state.rearrangedArray.forEach((defaultIdentifier) => {
            let formula = defaultIdentifier.DefaultValue.Formula;
            if (formula) {
              let newFormulaSlab = this.arrangedEvalForSlab(
                formula,
                key,
                rowIndex,
                EvalSlabResults[key],
                defaultIdentifier.Identifier
              );
              // Replace exchange rate placeholders
              if (newFormulaSlab.includes("ExchangeRate_SelectedCurrency")) {
                let exchangeRate = this.props.currencyOptions.find(
                  (currency) =>
                    currency.Currency ==
                    (this.state.selectedCurrency || this.props.quotedCurrency)
                );
                if (exchangeRate) {
                  newFormulaSlab = newFormulaSlab.replace(
                    /ExchangeRate_SelectedCurrency/g,
                    exchangeRate.ExchangeRate
                  );
                }
              }
              if (newFormulaSlab.includes("ExchangeRate_BuyerLocalCurrency")) {
                let exchangeRate = this.props.currencyOptions.find(
                  (currency) =>
                    currency.Currency === (this.props.currencyDetails || "INR")
                );
                if (exchangeRate) {
                  newFormulaSlab = newFormulaSlab.replace(
                    /ExchangeRate_BuyerLocalCurrency/g,
                    exchangeRate.ExchangeRate
                  );
                }
              }
              if (
                newFormulaSlab && newFormulaSlab.includes("ExchangeRate_")
              ) {
                newFormulaSlab = this.evalMasterExchangeRate(newFormulaSlab);
              }
              // Replace undefined variables
              if (newFormulaSlab && newFormulaSlab.includes("(f")) {
                const match = newFormulaSlab.match(/f\d+/)[0];
                newFormulaSlab = newFormulaSlab.replace(match, 0);
              }
              // const evaluatedValue = parseFloat(eval(newFormulaSlab)).toFixed(3);
              const evaluatedValue = parseFloat(eval(newFormulaSlab));
              // Check if evaluatedValue is a valid number
              if (!isNaN(evaluatedValue)) {
                EvalSlabResults[key][rowIndex][defaultIdentifier.FieldId] =
                  evaluatedValue;
              } else {
                EvalSlabResults[key][rowIndex][defaultIdentifier.FieldId] = 0; // or any default value you prefer
              }
            }
          });
        });
      });

      const solutionsRange =
        lastTransactionData.itemize[key]?.solution[0].data[0]?._range ===
          undefined || [];

      if (
        this.props.slabFlag &&
        this.props.multipleSolutionFlag &&
        solutionsRange === true
      ) {
        //change this
        // For mainTemplateQuotation
        Object.entries(this.props.quotationJsonTemplate.ITEMIZE[0]).forEach(
          (value, index) => {
            let field_id = value[1]?.FieldId;
            if (!obj[key]) {
              obj[key] = [];
            }
            if (this.props.itemBoxId !== field_id) {
              if (!obj[key][0]) {
                obj[key][0] = {};
              }
              Object.entries(mainTemplateQuotation).forEach(
                ([itemId, mainTemp]) => {
                  if (mainTemp[field_id] && itemId === key) {
                    obj[key][0][field_id] = mainTemp[field_id]?.value || "";
                    arr.push(field_id);
                    return;
                  }
                }
              );
            }
          }
        );
        obj[key].forEach((rowdata, rowindex) => {
          Object.entries(quotationJsonTemplate.ITEMIZE[0]).forEach(
            ([index, currentField]) => {
              if (
                this.state.mainTemplateQuotation &&
                this.state.mainTemplateQuotation[key] &&
                this.state.mainTemplateQuotation[key].hasOwnProperty(
                  currentField.FieldId
                ) &&
                currentField.Identifier !== "Product" &&
                currentField.ElementType !== "M"
              ) {
                if (currentField.ElementType === "D") {
                  obj[key][rowindex][currentField.FieldId] = String(
                    EvalResults[currentField.FieldId]
                  );
                } else {
                  obj[key][rowindex][currentField.FieldId] = String(
                    this.state.mainTemplateQuotation[key][currentField.FieldId]
                      ?.value
                  );
                }
              } else {
                if (arr.indexOf(currentField.FieldId) === -1) {
                  let formula =
                    currentField.DefaultFormula ||
                    currentField.DefaultValue.Formula;
                  if (this.props.itemBoxId !== currentField.FieldId) {
                    let value = 0;
                    if (formula && formula !== "") {
                      let placeholders = formula.match(/\(f\d+\)/g);
                      if (placeholders) {
                        try {
                          if (currentField.ElementType === "D") {
                            if (
                              EvalResults.hasOwnProperty(currentField.FieldId)
                            ) {
                              value = EvalResults[currentField.FieldId];
                            }
                          } else {
                            let defaultFormula = currentField.DefaultFormula;
                            if (defaultFormula) {
                              const formattedResult =
                                this.extractAndFormat(defaultFormula);

                              if (
                                formattedResult &&
                                formattedResult.length >= 1
                              ) {
                                const valuesFound = formattedResult
                                  .map((identifier, index) => {
                                    const attributeKey = identifier.split(".");
                                    const subStringAttrKey =
                                      attributeKey[1].substring(1);
                                    const valuesInSolution =
                                      lastTransactionData.itemize[key]?.solution
                                        ?.map(
                                          (obj) =>
                                            obj?.data[0]?.[subStringAttrKey]
                                        )
                                        ?.filter(
                                          (value) => value !== undefined
                                        );
                                    const fallbackValue =
                                      lastTransactionData.common?.[
                                        subStringAttrKey
                                      ];
                                    const fetchTxn = this.props.fetchedTxn?.[
                                      attributeKey[0]
                                    ]?.itemize[key]?.solution
                                      ?.map(
                                        (obj) =>
                                          obj?.data[0]?.[subStringAttrKey]
                                      )
                                      ?.filter((value) => value !== undefined);
                                    if (
                                      valuesInSolution &&
                                      valuesInSolution.length > 0
                                    ) {
                                      return valuesInSolution;
                                    } else if (
                                      valuesInSolution &&
                                      valuesInSolution.length == 0 &&
                                      fetchTxn &&
                                      fetchTxn.length > 0
                                    ) {
                                      return fetchTxn;
                                    } else if (fallbackValue !== undefined) {
                                      return [fallbackValue];
                                    } else {
                                      return [];
                                    }
                                  })
                                  .flat();

                                let finalValue = null;
                                for (let i = 0; i < valuesFound.length; i++) {
                                  if (valuesFound[i] !== null) {
                                    finalValue = valuesFound[i];
                                    break;
                                  }
                                }
                                value = finalValue;
                              }
                            }
                          }
                        } catch (error) {
                          console.log("error in eval", error);
                        }
                      }
                    } else {
                      value = currentField.DefaultValue || 0;
                    }
                    obj[key][rowindex][currentField.FieldId] = String(
                      value || 0
                    );
                  }
                }
              }
            }
          );
          if (!finalRemainingQuotation[key]) {
            finalRemainingQuotation[key] = [];
            finalRemainingQuotation[key][rowindex] = {};
          }
          finalRemainingQuotation[key][rowindex] = obj[key][rowindex];
        });
      } else {
        // For Slab
        Object.entries(newSlabQuotationTemplate).forEach(([key, obj]) => {
          let productElement = this.state.allItemsTemplate.find((item) => {
            return (
              item.Identifier === "Product" ||
              item.Identifier === "ProductForeign"
            );
          });

          let productNewElement = productElement.FieldId;
          let copiedObj = JSON.parse(JSON.stringify(obj));
          const updatedData = copiedObj.map((item) => {
            const { [productNewElement]: excluded, ...rest } = item; // Destructure and exclude the productNewElement field
            return rest;
          });
          copiedObj = updatedData;
          copiedObj.forEach((rowdata, rowIndex) => {
            Object.entries(quotationJsonTemplate.ITEMIZE[0]).forEach(
              ([index, currentField]) => {
                if (
                  newSlabQuotationTemplate &&
                  newSlabQuotationTemplate[key][rowIndex] &&
                  newSlabQuotationTemplate[key][rowIndex].hasOwnProperty(
                    currentField.FieldId
                  ) &&
                  (currentField.Identifier !== "Product" ||
                    currentField.Identifier !== "ProductForeign") &&
                  currentField.ElementType !== "M"
                ) {
                  if (currentField.ElementType === "D") {
                    copiedObj[rowIndex][currentField.FieldId] =
                      EvalSlabResults[key][rowIndex][currentField.FieldId];
                  } else {
                    copiedObj[rowIndex][currentField.FieldId] =
                      newSlabQuotationTemplate[key][rowIndex][
                        currentField.FieldId
                      ]["value"];
                  }
                } else {
                  if (arr.indexOf(currentField.FieldId) === -1) {
                    let formula =
                      currentField.DefaultFormula ||
                      currentField.DefaultValue.Formula;

                    if (this.props.itemBoxId !== currentField.FieldId) {
                      let value = 0;
                      if (formula && formula !== "") {
                        let placeholders = formula.match(/\(f\d+\)/g);
                        if (placeholders) {
                          try {
                            if (currentField.ElementType === "D") {
                              if (
                                EvalSlabResults[key][rowIndex].hasOwnProperty(
                                  currentField.FieldId
                                )
                              ) {
                                value =
                                  EvalSlabResults[key][rowIndex][
                                    currentField.FieldId
                                  ];
                              }
                            } else {
                              let defaultFormula = currentField.DefaultFormula;
                              if (defaultFormula) {
                                const formattedResult =
                                  this.extractAndFormat(defaultFormula);
                                if (
                                  formattedResult &&
                                  formattedResult.length >= 1
                                ) {
                                  const valuesFound = formattedResult
                                    .map((identifier, index) => {
                                      const attributeKey =
                                        identifier.split(".");
                                      const subStringAttrKey =
                                        attributeKey[1].substring(1);
                                      const valuesInSolution =
                                        lastTransactionData.itemize[
                                          key
                                        ]?.solution
                                          ?.map(
                                            (obj) =>
                                              obj?.data[0]?.[subStringAttrKey]
                                          )
                                          ?.filter(
                                            (value) => value !== undefined
                                          );
                                      const fallbackValue =
                                        lastTransactionData.common?.[
                                          subStringAttrKey
                                        ];
                                      const fetchTxn = this.props.fetchedTxn?.[
                                        attributeKey[0]
                                      ]?.itemize[key]?.solution
                                        ?.map(
                                          (obj) =>
                                            obj?.data[0]?.[subStringAttrKey]
                                        )
                                        ?.filter(
                                          (value) => value !== undefined
                                        );
                                      if (
                                        valuesInSolution &&
                                        valuesInSolution.length > 0
                                      ) {
                                        return valuesInSolution;
                                      } else if (
                                        valuesInSolution &&
                                        valuesInSolution.length == 0 &&
                                        fetchTxn &&
                                        fetchTxn.length > 0
                                      ) {
                                        return fetchTxn;
                                      } else if (fallbackValue !== undefined) {
                                        return [fallbackValue];
                                      } else {
                                        return [];
                                      }
                                    })
                                    .flat();

                                  let finalValue = null;
                                  for (let i = 0; i < valuesFound.length; i++) {
                                    if (valuesFound[i] !== null) {
                                      finalValue = valuesFound[i];
                                      break;
                                    }
                                  }
                                  value = finalValue;
                                }
                              }
                            }
                          } catch (error) {
                            console.log("error in eval", error);
                          }
                        }
                      } else {
                        value = currentField.DefaultValue || 0;
                      }
                      copiedObj[rowIndex][currentField.FieldId] = value || 0;
                    }
                  }
                }
              }
            );

            if (!finalRemainingQuotationForSlab[key]) {
              finalRemainingQuotationForSlab[key] = [];
              finalRemainingQuotationForSlab[key][rowIndex] = {};
            }
            finalRemainingQuotationForSlab[key][rowIndex] = copiedObj[rowIndex];
          });
        });
      }
    });

    this.props.transactionItemSequence.forEach((key) => {
      if (finalRemainingQuotationForSlab.hasOwnProperty(key)) {
        const itemQuotations = finalRemainingQuotationForSlab[key];

        // Initialize the solution array for the item key
        let solution = [
          {
            data: [],
          },
        ];

        for (const rowIndex in itemQuotations) {
          if (itemQuotations.hasOwnProperty(rowIndex)) {
            // Filter out numeric keys and include select_slab and _range
            let filteredObj = {};
            for (let fieldKey in itemQuotations[rowIndex]) {
              if (
                /^\d+$/.test(fieldKey) ||
                fieldKey === "select_slab" ||
                fieldKey === "_range"
              ) {
                filteredObj[fieldKey] = itemQuotations[rowIndex][fieldKey];
              }
            }

            solution[0].data.push(filteredObj);
            if (
              newSlabQuotationTemplate.hasOwnProperty(key) &&
              newSlabQuotationTemplate[key].hasOwnProperty(rowIndex)
            ) {
              const item = newSlabQuotationTemplate[key][rowIndex];
              const itemSelected = newSlabQuotationTemplate[key];
              const concatenatedItemData = item["formdetailRowId"];
              let concatenatedItem = "";
              for (const dataKey in concatenatedItemData) {
                if (concatenatedItemData.hasOwnProperty(dataKey)) {
                  concatenatedItem += concatenatedItemData[dataKey];
                }
              }

              // Concatenate item.selected values
              const concatenatedSelectedData = itemSelected["selected"];
              let concatenatedSelected = "";
              for (const dataKey in concatenatedSelectedData) {
                if (concatenatedSelectedData.hasOwnProperty(dataKey)) {
                  concatenatedSelected += concatenatedSelectedData[dataKey];
                }
              }

              const itemData = {
                selected: concatenatedSelected, // Concatenated selected string
                formdetailRowId: parseInt(concatenatedItem, 10),
                itemBoxId: this.props.itemBoxId,
                solution: solution,
              };

              slabItemize[key] = itemData;
            }
          }
        }
      } else {
        console.warn(
          `finalRemainingQuotationForMS does not contain key: ${key}`
        );
      }
    });

    let item_id_arr_cm = Object.keys(additionalInfoTemplate);
    item_id_arr_cm.forEach((item_id) => {
      let obj = {};
      let key = item_id;
      Object.entries(quotationJsonTemplate.ITEMIZE[0]).forEach(
        ([index, currentField]) => {
          let field_id = currentField.FieldId;
          let value;
          if (remainTemplateQuotation[field_id] !== undefined) {
            value = remainTemplateQuotation[field_id];
          } else {
            let formula =
              currentField.DefaultFormula || currentField.DefaultValue.Formula;
            if (this.props.itemBoxId !== currentField.FieldId && formula) {
              let placeholders = formula.match(/\(f\d+\)/g);
              if (placeholders) {
                let formulaDataHash = this.finalFormulaFunction(
                  formula,
                  placeholders,
                  currentField.FieldId,
                  key,
                  0
                );
                formula = formulaDataHash.formula;
                try {
                  if (
                    currentField.ElementType === "D" &&
                    formulaDataHash.eval
                  ) {
                    value = eval(formula);
                    value = isNaN(value) ? 0 : value;
                  } else {
                    value = formulaDataHash.value || currentField.DefaultValue;
                  }
                } catch (error) {
                  console.log("error in eval", error);
                }
              }
            }
          }
          obj[field_id] = value !== undefined ? value : "";
        }
      );
      finalRemainingCommonQuotation[key] = obj;
    });

    const common = {};
    for (const fieldId in finalRemainingCommonQuotation) {
      if (
        fieldId === "undefined" ||
        fieldId === undefined ||
        fieldId === null
      ) {
        continue; // Skip this iteration if fieldId is undefined or null
      }

      if (!additionalInfoTemplate.hasOwnProperty(fieldId)) {
        continue; // Skip this iteration if fieldId is not present in additionalInfoTemplate
      }

      const fieldData = additionalInfoTemplate[fieldId];
      const fieldValue = fieldData?.value;

      if (
        fieldId === "undefined" ||
        fieldData === undefined ||
        fieldData === null
      ) {
        continue; // Skip this iteration if fieldData is undefined or null
      }

      const isAttachmentField =
        fieldData.Identifier === "Attachment" ||
        fieldData.Identifier === "AttachmentForeign";

      if (isAttachmentField) {
        if (Array.isArray(fieldValue)) {
          common[fieldId] = fieldValue.length > 0 ? fieldValue : "";
        } else {
          common[fieldId] = "";
        }
      } else {
        common[fieldId] = fieldValue != null ? fieldValue : "";
      }
    }

    // Construct payload
    const queryParams = new URLSearchParams(window.location.search);
    const supplier = parseInt(queryParams.get("supplier"), 10);
    const quotationDate = dayjs(
      this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
        this?.state?.dataFromQuotationDetails?.offlineRequoteDate
    );

    const formattedDate = quotationDate.isValid()
      ? quotationDate.format("YYYY-MM-DD HH:mm:ss IST")
      : null;

    const concatenatedItemizeUnique = {
      ...slabItemize,
      ...itemize,
    };

    // Use formattedDate wherever needed
    const payload = {
      inquiry_id: this.props.inquiryId,
      quotation_number:
        this?.state?.dataFromQuotationDetails?.offlineRequoteNo ||
        this?.state?.dataFromQuotationDetails?.offlineQuotationNo,
      validity_date: dayjs(
        this?.state?.dataFromQuotationDetails?.requoteValidity ||
          this?.state?.dataFromQuotationDetails?.quotationValidity
      ).format("YYYY-MM-DD HH:mm:ss IST"),
      tran_status:
        this?.props?.transactionType === "Inquiry" ? "Quotation" : "Requote",
      currency: this.state.selectedCurrency || this.props.quotedCurrency,
      terms_and_conditions_text: "",
      location_flag: this.props.vendorType === "Local" ? "L" : "I",
      shipment_mark: this.props.CurrentTransaction.shipment_mark || "",
      template: {
        itemize: concatenatedItemizeUnique,
        common: common,
      },
      ...(supplier !== 1 && {
        offline: {
          reference: this?.state?.dataFromQuotationDetails?.reference,
          vendor_id: this.props.vendorId,
          vendor_quotation_date: dayjs(
            this?.state?.dataFromQuotationDetails?.offlineQuotationDate ||
              this?.state?.dataFromQuotationDetails?.offlineRequoteDate
          ).format("YYYY-MM-DD HH:mm:ss IST"),
          vendor_quotation_date: formattedDate,
          attachment: this?.state?.dataFromQuotationDetails?.attachment,
        },
      }),
    };
    return payload;
  };

  quotationSubmitAPI = async () => {
    const formData = this.props.multipleSolutionFlag
      ? this.props.slabFlag
        ? this.prepareQuotationPayloadForSlab()
        : this.prepareQuotationPayloadForMS()
      : this.prepareQuotationPayload();
    // console.log("formData", formData);
    // return false;
    try {
      this.setState({
        quotationSubmitLoading: true,
      });
      const response = await axios.post(quotationSubmit, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      this.setState({
        quotationSubmitSuccessMessage: response.data.message,
      });
      if (response.data.status === "success") {
        const formData = {
          vendorId: this.props.vendorId,
          type: this.state.industryType,
          value:
            this.state.industryType === "MSME" ? this.state.msmeNumber : "",
        };
        axios
          .patch(industry_type, formData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.props.newSessionID}`,
            },
          })
          .then((response) => {})
          .catch((error) => {
            console.error(
              "There was a problem with your axios operation:",
              error
            );
          });

        try {
          await this.sendMail(this.prepareQuotationPayload(), response.data);
        } catch (error) {
          console.error("Error sending mail:", error);
        }
      }
    } catch (error) {
      // Handle error
      console.error("Error submitting quotation:", error);
      // Turn off loading state
      this.setState({
        quotationSubmitLoading: false,
        quotationSubmitSuccess: false,
        quotationSubmitErrorMessage:
          "Failed to submit quotation. Please try again later.",
      });

      // Extract error messages from the response
      let errorMessages = [];
      if (error?.response?.data[0]?.code === 1018) {
        console.log("9815 error", error);
        this.props.openSnackbar(
          "error",
          error?.response?.data[0]?.error
            ? error?.response?.data[0]?.error
            : "Something went wrong!"
        );
      } else {
        if (
          error?.response &&
          error?.response?.data &&
          error?.response?.data?.error
        ) {
          const errors = error?.response?.data?.error;
          console.log("9820 error", errors);
          errorMessages = Object.keys(errors).map((key) => {
            if (Array.isArray(errors[key])) {
              return errors[key].join(" ");
            } else {
              return errors; // or handle it appropriately if it's not an array
            }
          });
        }
        // Fallback error message
        if (errorMessages.length === 0) {
          console.log("9831 error", error);
          errorMessages.push(
            error.response && error.response.data && error.response.data.message
              ? error.response.data.message
              : "Something went wrong!"
          );
        }
        console.log("9838 errorMessages", errorMessages);
        // Show the error messages in MUI Snackbar
        errorMessages.forEach((message) => {
          this.props.openSnackbar("error", message);
        });
      }
    }
  };

  handleCloseDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: false });
  }

  handleOpenDestinationDialog() {
    this.setState({ isDestinationDetailsDialogOpen: true });
  }

  setIsDestinationDetailsDialogOpen(isOpen) {
    this.setState({ isDestinationDetailsDialogOpen: isOpen });
  }
  disabledProps(disabledItems) {
    this.setState({ disabledProps: disabledItems });
  }

  setRearrangedTemplate(data) {
    this.setState({ rearrangedArray: data });
  }

  setItemsTemplate(data) {
    this.setState({ allItemsTemplate: data });
  }
  setServiceSourceName(source) {
    this.setState({ excelSourceName: source });
  }

  render() {
    return (
      <React.Fragment>
        <Backdrop
          style={{ color: "#fff", zIndex: "10000000000" }}
          open={
            this.state.loaderArray.length > 0 ||
            this.state.prevRFQLoading ||
            this.props.jsonTemplateloading ||
            this.state.quotationSubmitLoading ||
            this.state.attachmentLoading
          }
        >
          <CircularProgress style={{ color: "#fff" }} />
        </Backdrop>
        <React.Fragment>
          <form
            id="novalidatedform"
            noValidate
            autoComplete="off"
            onSubmit={(event) => {
              if (
                this.props.location?.pathname.indexOf("/prcombination") > -1
              ) {
                event.preventDefault();
              }
            }}
          >
            {/* -----------------------Quotation Details----------------------- */}
            <Box mb={2} id="QuotationDetails">
              <QuotationDetailsSection
                title="Quotation Details"
                data={this.props.selectedQuotedItems}
                categoryId={this.props.categoryId}
                Alreadyexistingfiles={this.props.categorySpecFiles}
                getField={this.getFieldLocal}
                foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                  this?.props?.quotationJsonTemplate?.FOREIGN
                )}
                itemizeData={this.state.mainTemplateQuotation}
                headerLabels={this.props?.headerLabels}
                commonData={this?.props?.quotationJsonTemplate?.COMMON}
                localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                  this?.props?.quotationJsonTemplate?.LOCAL
                )}
                currencyOptions={this.props.currencyOptions}
                currencyDetails={this.props.currencyDetails}
                fileSpecificationData={this.props.fileSpecificationData}
                handleCurrencyChange={this.handleCurrencyChange}
                vendorType={this.props.vendorType}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                setAttachmentsValue={(value) => {
                  this.setAttachmentsValue(value);
                }}
                dataFromQuotationDetails={this.setDataFromQuotationChild}
                offlineDateError={this.state.offlineDateError}
                offlineAttachmentError={this.state.offlineAttachment}
                offlineFieldError={this.state.offlineFieldError}
                offlineReferenceError={this.state.offlineReferenceError}
                featurePermissionDetails={this.props.featurePermission}
                industryDetails={this.props.industryDetails}
                industryType={this.state.industryType}
                msmeNumber={this.state.msmeNumber}
                onIndustryTypeChange={this.handleIndustryTypeChange}
                onMsmeNumberChange={this.handleMsmeNumberChange}
                industryTypeValidator={this.state.industryTypeValidator}
                selectedCurrencyValidator={this.state.selectedCurrencyValidator}
                msmeValidator={this.state.msmeValidator}
                industryTypeErrorMessage={this.state.industryTypeErrorMessage}
                selectedCurrencyErrorMessage={
                  this.state.selectedCurrencyErrorMessage
                }
                selectedCurrency={this.state.selectedCurrency}
                additionalInfo={this.state.additionalInfoTemplate}
                inquiryId={this.props.inquiryId}
                CurrentTransaction={this.props.CurrentTransaction}
                setquotationImported={(value) => {
                  this.setQuotationImport(value);
                }}
                quotedCurrency={this.props.quotedCurrency}
                transactionItemSequence={this.props.transactionItemSequence}
                allItemsTemplate={this.state.allItemsTemplate}
                base_currency={this.props.currencyDetails}
                PermissionInquiry={this.props.PermissionInquiry}
                disabledProps={this.state.disabledProps}
                isServiceRfq={this.props.isServiceRfq}
                categoryName={this.props.categoryName}
                serviceSourceName={this.setServiceSourceName}
                itemSpecific={this.props.itemSpecific}
                multipleSolutionFlag={this.props.multipleSolutionFlag}
                slabFlag={this.props.slabFlag}
                solutionNames={this.state.solutionNames}
                getMasterCurrencyField={this.getFieldMasterCurrency}
              />
            </Box>

            {/* -----------------------Template Section----------------------- */}
            {console.log(
              "this.props.AlternativeNames?.alternate_options",
              this.props.AlternativeNames?.alternate_options
            )}
            <Box mb={2} id="Template">
              <QuotationFormSectionTable
                inquiryId={this.props.inquiryId}
                randomId={this.state.randomId}
                additionalInfoTemplate={this.state.additionalInfoTemplate}
                categoryId={this.props.categoryId}
                batch_id={
                  this.props.quotationJsonTemplate?.PARAM &&
                  this.props.quotationJsonTemplate?.PARAM[0]?.batch_id
                }
                currencyOptions={this.props.currencyOptions}
                msmainTemplateQuotation={this.state.msmainTemplateQuotation}
                data={this.props.quotationJsonTemplate?.ITEMIZE || []}
                commonData={this?.props?.quotationJsonTemplate?.COMMON || []}
                mainTemplateQuotation={this.state.mainTemplateQuotation}
                handleOpenDestinationDialog={this.handleOpenDestinationDialog}
                setIsDestinationDetailsDialogOpen={
                  this.setIsDestinationDetailsDialogOpen
                }
                setRearrangedTemplate={this.setRearrangedTemplate}
                setItemsTemplate={this.setItemsTemplate}
                selectedQuotedItems={this.props.selectedQuotedItems.items}
                base_currency={this.props.currencyDetails}
                handleUplaod={this.handleUplaod}
                applyToAll={this.state.applyToAll}
                multiSolApplyToAll={this.state.multiSolApplyToAll}
                slabApplyToAll={this.state.slabApplyToAll}
                slabParentApplyToAll={this.state.slabParentApplyToAll}
                handleApplyToAllClick={this.handleApplyToAllClick}
                handleMSApplyToAllClick={this.handleMSApplyToAllClick}
                handleSlabApplyToAllClick={this.handleSlabApplyToAllClick}
                handleSlabParentApplyToAllClick={
                  this.handleSlabParentApplyToAllClick
                }
                handleDateChange={this.handleDateChange}
                handleDateSlabChange={this.handleDateSlabChange}
                handleDateMSChange={this.handleDateMSChange}
                handleDateWithOutSlabChange={this.handleDateWithOutSlabChange}
                handleWithOutSlabChange={this.handleWithOutSlabInputChange}
                handleUpdateInMasterClick={this.handleUpdateInMasterClick}
                handleChange={this.handleItemizeInputChange}
                handleMSChange={this.handleMSItemizeInputChange}
                handleSlabChange={this.handleSlabItemizeInputChange}
                handleSolutionNameChange={this.handleSolutionNameChange}
                solutionNameErrors={this.state.solutionNameErrors}
                errorArrayForMSParentName={this.state.errorArrayForMSParentName}
                solutionNames={this.state.solutionNames}
                handleCheckboxChange={this.handleCheckboxChange}
                default_dropdown_type={
                  this.props.companyInfo?.default_dropdown_type
                }
                destination={this.getDestinationValue()}
                updateDeliveryScheduleFieldValue={
                  this.updateDeliveryScheduleFieldValue
                }
                updateDeliveryScheduleFieldValueSlab={
                  this.updateDeliveryScheduleFieldValueSlab
                }
                itemSpecificFiles={this.props.itemSpecificFiles}
                LATEST={this.LATEST}
                handleSlab={this.handleSlab}
                slab={slab}
                slabAddRow={this.slabAddRow}
                slabRemoveRow={this.slabRemoveRow}
                handleRowInput={this.handleRowInput}
                handleApplyToAllSlab={this.handleApplyToAllSlab}
                handleKey={this.handleKey}
                slabKey={this.state.slabKey}
                applyToAllSlab={this.state.applyToAllSlab}
                firstActiveSlab={this.props.selectedItems?.find((element) => {
                  if (
                    this.state?.slab[element?.id] &&
                    this.state?.slab[element?.id].isOpen
                  ) {
                    return element;
                  }
                })}
                displayOptions={this.props.displayOptions}
                selectedCurrency={this.state.selectedCurrency}
                lastTransactionInThread={this.props.lastTransactionInThread}
                lastTransactionData={this.props.lastTransactionData}
                lastNegotiationData={this.props.lastNegotiationData}
                lastTransactionTypeInThread={
                  this.props.lastTransactionTypeInThread
                }
                filteredSelectedNotData={this.props.filteredSelectedNotData}
                vendorType={this.props.vendorType}
                currencyDetails={this.props.currencyDetails}
                items={this.props.items}
                inquiryItemsId={this.props.inquiryItemsId}
                itemSpecific={this.props.itemSpecific}
                multipleSolutionFlag={this.props.multipleSolutionFlag}
                slabFlag={this.props.slabFlag}
                multiplePlantsFlag={this.props.multiplePlantsFlag}
                toggleSwitch={this.toggleSwitch}
                switchState={this.state.switchState}
                errorArray={this.state.errorArray}
                errorArrayForMS={this.state.errorArrayForMS}
                errorArrayForSlab={this.state.errorArrayForSlab}
                quotedCurrency={this.props.quotedCurrency}
                transactionItemSequence={this.props.transactionItemSequence}
                template={this.props.quotationJsonTemplate}
                EvalResultsKeyWise={this.state.EvalResults}
                EvalResultsKeyWiseSlab={this.state.EvalResultsSlab}
                multiSolmainTemplateQuotation={
                  this.state.multiSolmainTemplateQuotation
                }
                CurrentTransaction={this.props.CurrentTransaction}
                handleUpdateQuotation={this.handleUpdateQuotation}
                setSolutionNames={this.setSolutionNames}
                setSolutionNamesErrors={this.setSolutionNamesErrors}
                solutionNameMainTemplate={this.state.solutionNameMainTemplate}
                handleSolNameChangeMainTemplate={
                  this.handleSolNameChangeMainTemplate
                }
                disabledProps={this.disabledProps}
                isServiceRfq={this.props.isServiceRfq}
                slabMainTemplateQuotation={this.state.slabMainTemplateQuotation}
                setSlabMainTemplateQuotation={this.setSlabMainTemplateQuotation}
                disabledRows={this.state.disabledRows}
                setDisabledRows={this.setDisabledRows}
                arrangedEvalForMS={this.arrangedEvalForMS}
                openSnackbar={this.props.openSnackbar}
                updateMultiSolmainTemplateQuotation={
                  this.updateMultiSolmainTemplateQuotation
                }
                clearMultiSolApplyToAll={this.clearMultiSolApplyToAll}
                switchStateToggleCheck={this.state.switchStateToggleCheck}
                slabChildStateToggleCheck={this.state.slabChildStateToggleCheck}
                handleSave={this.handleSave}
                lastTransactionInThreadCommon={
                  this.props.lastTransactionInThreadCommon
                }
                itemizeData={this?.props?.quotationJsonTemplate?.ITEMIZE?.[0]}
                foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                  this?.props?.quotationJsonTemplate?.FOREIGN
                )}
                localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                  this?.props?.quotationJsonTemplate?.LOCAL
                )}
                isMultipleCurrency={this.props.isMultipleCurrency}
                uploadItemFiles={this.uploadItemFiles}
                getMasterCurrencyByFieldId={this.getMasterCurrencyByFieldId}
                AlternativeOptions={
                  this.props.AlternativeNames?.alternate_options
                }
              />
            </Box>
            {/*----------------------- Additional Info -----------------------*/}
            <>
              <Box mb={2} id="AdditionalInfo">
                <QuotationCardTemplate
                  title="Additional Info"
                  state={this?.state?.lastTransactionData?.common}
                  foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                    this?.props?.quotationJsonTemplate?.FOREIGN
                  )}
                  localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
                    this?.props?.quotationJsonTemplate?.LOCAL
                  )}
                  MaterialDescription={this.state.MaterialDescription}
                  materialDescriptionAll={this.download}
                  getField={this.getFieldLocal}
                  handleChange={this.handleLocalChange}
                  vendorType={this.props.vendorType}
                  categoryId={this.props.categoryId}
                  fileSpecificationData={this.props.fileSpecificationData}
                  CurrentTransaction={this.props.CurrentTransaction}
                  multiplePlantsFlag={this.props.multiplePlantsFlag}
                  category={this.props.selectedQuotedItems.categories[0]}
                />
              </Box>

              {/* -----------------------Submit Quotation Form Button----------------------- */}

              <Box className="Footer-QuotedForm" sx={{ mb: 2 }}>
                <FormGroup
                  sx={{
                    display: "inline-flex",
                    "& .MuiTypography-root": {
                      color: "var(--primary)",
                      textDecoration: "underline",
                      fontWeight: "500",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.quotationChecked}
                        onChange={(event) =>
                          this.handleQuotationCheckboxChange(event)
                        }
                        color="primary"
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle1"
                        component="a"
                        onClick={(e) => {
                          e.preventDefault();
                          this.setState({
                            tandcDialogOpen: true,
                          });
                        }}
                      >
                        Accept Terms and Conditions
                      </Typography>
                    }
                  />
                </FormGroup>
                <Stack direction="row" spacing={2} mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    className="Upload_file_btn"
                    onClick={(event) => this.handleQuotationSubmit(event)}
                    disabled={!this.state.quotationChecked}
                  >
                    Submit{" "}
                    {this?.props?.transactionType === "Inquiry"
                      ? "Quotation"
                      : "Requote"}
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ visibility: "hidden" }}
                  >
                    Regret
                  </Button>
                </Stack>
              </Box>
            </>
          </form>
        </React.Fragment>

        {/* Modals */}

        {/* Additional Info Specification */}
        {this.state.specificationsOpen && (
          <UploadFileDialog
            product_id={this.props.selectedCategory?.id}
            randomId={this.state.randomId}
            master_type="category"
            handleUplaod={this.handleUplaod}
            open={this.state.specificationsOpen}
            Alreadyexistingfiles={this.props.categorySpecFiles}
            updateCategorySpecificationFiles={(newFiles) => {
              this.setState({
                categorySpecificationFiles: newFiles,
              });
            }}
            handleClose={() => {
              this.setState({
                specificationsOpen: false,
              });
            }}
          />
        )}

        {/* Additional Info Attachment */}
        {(this.state.attachments !== null ||
          this.state.attachments !== undefined) &&
          this.state.dynamicFileUploadOpen && (
            <UploadDynamicQuotation
              sessionID={this.props.sessionId}
              currentFieldId={this.state.currentFieldId}
              uploadedFiles={
                this.state[this.state.currentTemplate] &&
                this.state[this.state.currentTemplate][
                  this.state.currentFieldId
                ] &&
                this.state[this.state.currentTemplate][
                  this.state.currentFieldId
                ]["value"]
              }
              handleUploadedFileDelete={(index) => {
                let temp = this.state[this.state.currentTemplate];
                temp[this.state.currentFieldId]["value"].splice(index, 1);
                this.setState({ [this.state.currentTemplate]: temp });
              }}
              setAttachmentsValue={(value) => {
                this.setAttachmentsValue(value);
              }}
              randomId={this.state.randomId}
              open={this.state.dynamicFileUploadOpen}
              handleClose={() => {
                this.setState({ dynamicFileUploadOpen: false });
              }}
              uploadedFilesAttachment={this.state.attachments} // Pass attachments here
              categoryId={this.props.categoryId}
            />
          )}

        {this.state.dynamicFileUploadItemsOpen && (
          <UploadDynamicQuotation
            sessionID={this.props.sessionId}
            currentFieldId={this.state.currentFieldItemId}
            uploadedFiles={
              // typeof this.state.currentItemIndex == "undefined" ?
              this.state[this.state.currentTemplateItem] &&
              this.state[this.state.currentTemplateItem][
                this.state.currentItemId
              ] &&
              this.state[this.state.currentTemplateItem][
                this.state.currentItemId
              ][this.state.currentFieldItemId] &&
              this.state[this.state.currentTemplateItem][
                this.state.currentItemId
              ][this.state.currentFieldItemId]["value"]
              // : this.state[this.state.currentTemplateItem] &&
              //   this.state[this.state.currentTemplateItem][
              //     this.state.currentItemId
              //   ] &&
              //   this.state[this.state.currentTemplateItem][
              //     this.state.currentItemId
              //   ][this.state.currentItemIndex] &&
              //   this.state[this.state.currentTemplateItem][
              //     this.state.currentItemId
              //   ][this.state.currentItemIndex][this.state.currentFieldItemId] &&
              //   this.state[this.state.currentTemplateItem][
              //     this.state.currentItemId
              //   ][this.state.currentItemIndex][this.state.currentFieldItemId]["value"]
            }
            handleUploadedFileDelete={(index) => {
              // let temp
              // if (typeof this.state.currentItemIndex == "undefined") {
              let temp = this.state[this.state.currentTemplateItem];
              temp[this.state.currentItemId][this.state.currentFieldItemId][
                "value"
              ].splice(index, 1);
              // } else {
              //   temp = this.state[this.state.currentTemplateItem];
              //   temp[this.state.currentItemId][this.state.currentItemIndex][this.state.currentFieldItemId][
              //     "value"
              //   ].splice(index, 1)
              // }

              this.setState({ [this.state.currentTemplateItem]: temp });
            }}
            setAttachmentsValue={(value) => {
              // if (typeof this.state.currentItemIndex == "undefined") {
              this.setAttachmentsValueForItemize(value);
              // } else {
              //   this.setAttachmentsValueForItemizeAlternativeOption(value);

              // }
            }}
            randomId={this.state.randomId}
            open={this.state.dynamicFileUploadItemsOpen}
            handleClose={() => {
              this.setState({ dynamicFileUploadItemsOpen: false });
            }}
            uploadedFilesAttachment={this.state.attachments} // Pass attachments here
            categoryId={this.props.categoryId}
          />
        )}

        {/* Terms & Conditions Modal */}
        {this.state.tandcDialogOpen && (
          <TermsAndConditions
            category_id={this.props.categoryId}
            open={this.state.tandcDialogOpen}
            termsAndConditionsData={this.props.termsAndConditionsDetails}
            handleClose={() => {
              this.setState({
                tandcDialogOpen: false,
              });
            }}
          />
        )}

        {this.state.fileSpecificationsOpen && (
          <UploadDynamicView
            Loading={this.state.fileSpecLoading}
            title={
              this.state.fileAttachmentName
                ? this.state.fileAttachmentName
                : "View Transaction Specification"
            }
            uploadedFiles={this.state.specification?.data}
            open={this.state.fileSpecificationsOpen}
            handleClose={this.handleUploadClose}
          />
        )}

        <Dialog
          scroll="body"
          maxWidth={"sm"}
          open={this.state.quotationSubmitSuccess}
          aria-labelledby="responsive-dialog-title"
          sx={(theme) => ({
            "&.MuiDialog-root .MuiPaper-root": {
              width: "500px",
              boxSizing: "border-box",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
              },
            },
          })}
        >
          <DialogContent>
            <DialogContentText>
              <div className="linkcategorydialog_main">
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    className="text-center"
                  >
                    <img src={successimage} alt="Category Link Icon" />
                    <>
                      <h3 className="success_title">Successfully</h3>
                      <p className="success_subtitle">
                        {this.state.quotationSubmitSuccessMessage}
                      </p>
                    </>
                  </Grid>
                </Grid>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              // autoFocus
              onClick={(event) => {
                this.setState({
                  quotationSubmitSuccess: false,
                });
                const queryParams = new URLSearchParams(window.location.search);

                if (queryParams.get("supplier")) {
                  window.top.location.reload();
                }
                window.parent.postMessage("Quotation_close_iframe", "*");
                event.preventDefault();
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        {/* Quotation Form Submit Confirmation */}
        <ConfirmAlert
          open={this.state.confirmDialogOpen}
          handleClose={() => {
            this.setState({
              confirmDialogOpen: false,
            });
          }}
          handleSubmit={() => {
            this.setState({
              confirmDialogOpen: false,
            });
            this.quotationSubmitAPI();
          }}
          msg={this.state.confirmAlertMsg}
          title={this.state.confirmAlertTitle}
        />

        <Dialog
          open={this.state.openConfirmBox}
          onClose={this.handleConfirmBoxClose}
        >
          {/* <DialogTitle>Import {tranType} Form via Excel</DialogTitle> */}
          <DialogTitle>Import Form via Excel</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={this.handleConfirmBoxClose}
            sx={{ position: "absolute", right: 8, top: 13 }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography variant="h6">Download File Format:</Typography>
            {/* {masterType === 'category' ? ( */}
            {true ? (
              <>
                <Button onClick={() => this.downloadTransactionExcel(1)}>
                  Vertical
                </Button>
                <Button onClick={() => this.downloadTransactionExcel(2)}>
                  Horizontal
                </Button>
              </>
            ) : (
              <Button onClick={() => this.downloadTransactionExcel(1)}>
                Excel
              </Button>
            )}
            <br />
            <br />
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
            >
              {/* Upload file */}
              <input type="file" />
            </Button>
          </DialogContent>
        </Dialog>

        <DestinationDetailsDialog
          handleClose={this.handleCloseDestinationDialog}
          open={this.state.isDestinationDetailsDialogOpen}
          lastTransactionInThreadCommon={
            this.props.lastTransactionInThreadCommon
          }
          setIsDestinationDetailsDialogOpen={
            this.setIsDestinationDetailsDialogOpen
          }
          itemizeData={this?.props?.quotationJsonTemplate?.ITEMIZE?.[0]}
          foreignData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
            this?.props?.quotationJsonTemplate?.FOREIGN
          )}
          localData={this?.props?.quotationJsonTemplate?.COMMON?.concat(
            this?.props?.quotationJsonTemplate?.LOCAL
          )}
          vendorType={this.props.vendorType}
          handleSave={this.handleSave}
          fetchedTxn={this.props.fetchedTxn}
          additionalInfo={this.state.additionalInfoTemplate}
          mainTemplateInfo={this.state.mainTemplateQuotation}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCategory: state.categoryReducer.selectedCategory,
  sessionId: state.sessionReducer.sessionId,
  companyInfo: state.companyInfoReducer.companyInfo,
  rfqSettingValues: state.drawerValuesReducer.rfqSettingValues,
  // reminderDrawer: state.drawerValuesReducer.reminderDrawer,
  selectedVendors: state.vendorReducer.selectedVendors,
  accountModulePermission: state.permissionsReducer.accountModulePermission,
  accountlevelInfo: state.permissionsReducer.accountlevelInfo,
  featurePermission: state.permissionsReducer.featurePermission,
  remiderDrawer: state.drawerValuesReducer.remiderDrawer,
  checkTechnicalBidItems: state.itemReducer.checkTechnicalBidItems,
  checkTechinicalBidCategory: state.categoryReducer.checkTechinicalBidCategory,
  categorySpecFiles: state.categoryReducer.fileSpecifications,
  itemSpecificFiles: state.itemReducer.itemSpecifications,
  prlineitems: state.prrfqreducer.setPrLineItemsData,
  prplants: state.prrfqreducer.plantcodes,
  selectedPrCatagory: state.prrfqreducer.selectedPrCategory,
  rfqSubmittedPrs: state.prrfqreducer.rfqSubmittedPrs,
  prCombinations: state.prrfqreducer.prCombinations,
  pr_dropdown_values: state.prrfqreducer.pr_dropdown_values,
  defaultValues: state.prrfqreducer.defaultValues,
  userInfo: state.userInfoReducer.userInfo,
  isAllVendorSelected: state.vendorReducer.isAllVendorSelected,
  disableIndex: state.prrfqreducer.disableIndex,
  templateValues: state.prrfqreducer.templateValues,
  termsAndConditions: state.prrfqreducer.termsAndConditions,
  fileSpecificationFromApi: state.prrfqreducer.fileSpecificationFromApi,
  selectedItems: state.itemReducer.selectedItems,
  displayOptions: state.prrfqreducer.displayOptions,
  quotationExcelData: state.ImportQuotationReducer.quotation,
});

const mapDispatchToProps = {
  openSnackbar,
  setReminderSettings,
  resetReminderSettings,
  resetCAT,
  setFileSpecification,
  setItemSpecifications,
  setPrlinktoitemsforspecification,
  setPrRfqs,
  setPRselectedChange,
  setDefaultValues,
  setTemplateValues,
  setTermsAndConditions,
  setFileSpecificationFromApi,
  setRFQSettings,
  ImportQuotation,
};

const ConnectedQuotationTemplate = connect(
  mapStateToProps,
  mapDispatchToProps
)(QuotationTemplate);

const StyledAccountTemplate = withStyles(styles)(ConnectedQuotationTemplate);

export default StyledAccountTemplate;
